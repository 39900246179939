import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  optLabel: PropTypes.string,
  optValue: PropTypes.string,
  showNone: PropTypes.bool,
  color: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  variant: 'standard',
  name: undefined,
  label: undefined,
  value: '',
  defaultValue: undefined,
  placeholder: 'Select',
  multiple: false,
  fullWidth: false,
  optLabel: 'label',
  optValue: 'value',
  showNone: true,
  sx: {},
  color: 'secondary',
};

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const InputSelect = ({
  variant,
  label,
  name,
  value,
  defaultValue,
  placeholder,
  options,
  onChange,
  multiple,
  fullWidth,
  helperText,
  margin,
  optLabel,
  optValue,
  showNone,
  color,
  tip,
  sx,
  InputLabelProps,
  hideAsterisk,
  ...props
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    onChange(event.target.value, event);
  };

  const placeholderExist = typeof placeholder === 'string' && !!placeholder;

  return (
    <FormControl
      sx={{
        ...(placeholderExist
          ? {
              '& .MuiInputLabel-root': {
                background: 'white',
                padding: '0px 8px',
              },
              '& .MuiSelect-select:has(.notranslate) ~ input': {
                height: '100%',
                opacity: 1,
                padding: '0 18px',
                fontSize: '15px',
                border: 'none',
                ...(props?.backgroundColor && { backgroundColor: props?.backgroundColor }),
                '&::placeholder': {
                  color: '#c4c4c4',
                },
              },
            }
          : {}),
        ...sx,
      }}
      fullWidth={fullWidth}
      margin={margin}
      error={props.error}
      size={props?.size}
    >
      <InputLabel
        {...(placeholderExist ? { shrink: true } : {})}
        sx={
          props?.isSmall
            ? {
                fontSize: 14,
              }
            : {}
        }
        id={`${props.id}-label`}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${props.id}-label`}
        {...(placeholderExist
          ? {
              placeholder:
                placeholder.endsWith('*') && !hideAsterisk ? placeholder.slice(0, -1) : placeholder,
            }
          : {})}
        id={props.id}
        onChange={handleChange}
        variant={variant}
        name={name}
        label={label}
        defaultValue={value}
        value={value}
        {...props}
        color={color}
        multiple={multiple}
        placeholder={placeholder}
        sx={
          props?.isSmall
            ? {
                ...(props?.sx || {}),
                '& .MuiSelect-select': {
                  fontSize: 14,
                },
              }
            : { ...(props?.sx || {}) }
        }
      >
        {/* {showNone && !multiple && <MenuItem value="">{placeholder || 'None'}</MenuItem>} */}
        {showNone && !multiple && <MenuItem value="">None</MenuItem>}

        {(() => {
          if (options && typeof options !== 'undefined' && options.length) {
            return options.map((option) => {
              return (
                <MenuItem
                  disabled={option?.disabled ? option?.disabled : false}
                  key={option[optValue]}
                  value={option[optValue]}
                >
                  {option?.icon ? (
                    <Box display="flex" alignItems="center">
                      <ListItemIcon sx={{ minWidth: '44px' }}>{option.icon}</ListItemIcon>
                      {props?.withElpsis ? (
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {option[optLabel]}
                        </Typography>
                      ) : (
                        option[optLabel]
                      )}
                    </Box>
                  ) : (
                    option[optLabel]
                  )}
                </MenuItem>
              );
            });
          }
        })()}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

InputSelect.propTypes = propTypes;
InputSelect.defaultProps = defaultProps;

export default InputSelect;
