import { Box, Card, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

export const EmptyICPCard = styled(Card)({
  margin: '56px 12px 12px 12px',
  padding: '80px',
  borderRadius: '16px',
  border: '1px solid #CCCBCA',
  background: '##FFFFFF',
  boxShadow: 'none',
});

export const EmptyICPTitle = styled(Typography)({
  color: '#1A1A1A',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '32px',
});

export const EmptyContent = styled(Typography)({
  color: '#666',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '18.672px',
});

export const EmptyListContent = styled(Typography)({
  color: '#1A1A1A',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '18.672px',
});

export const EmptyCustomerCard = styled(Card)({
  padding: '0px',
  borderRadius: '16px',
  boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.15)',
  background: '#FFF',
  display: 'flex',
  flexDirection: 'column',
});

export const CustomerCardBlock = styled(Stack)({
  padding: '82px 28px 24px 24px',
  gap: '12px',
  background: 'linear-gradient(106deg, #EED17D 1.11%, #F64D33 99.93%)',
  alignItems: 'flex-start',
});

export const ICPFieldViews = styled(Stack)({
  padding: '24px 28px',
  gap: '8px',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  flexWrap: 'wrap',
  flexDirection: 'row',
});

export const BlackChip = styled(Box)({
  display: 'flex',
  padding: '4px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '999px',
  background: '#1A1A1A',
});

export const BlackChipText = styled(Typography)({
  color: '#FFFFFF',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 'normal',
});

export const CustomerBlockTitleText = styled(Typography)({
  color: '#1A1A1A',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '23.34px',
});

export const FieldChip = styled(Box)({
  display: 'flex',
  padding: '4px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  border: '1px solid #CCCBCA',
  background: '#FFF',
  borderRadius: '999px',
});

export const FieldChipText = styled(Typography)({
  color: '#1A1A1A',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  textOverflow: 'ellipsis',
});

export const CustomerCard = styled(Stack)({
  gap: '4px',
  alignItems: 'flex-start',
});

export const ICPFieldViewsGrid = styled(Stack)({
  gap: '8px',
  alignSelf: 'stretch',
  flexWrap: 'wrap',
  padding: '12px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'end',
});

export const ModalContainer = styled(Stack)({
  width: '530px',
  borderRadius: '8px',
  background: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
});

export const ModalHeader = styled(Stack)({
  alignItems: 'flex-end',
  padding: '24px 24px 0px 24px',
});

export const ModalContent = styled(Typography)({
  padding: '24px',
  color: '#000000DE',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '23.34px',
});

export const FieldCardChip = styled(Box)({
  display: 'flex',
  padding: '4px 8px',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '4px',
  border: '1px solid #CCCBCA',
  background: '#FFF',
  borderRadius: '999px',
});

export const CustomerTitleText = styled(Typography)({
  color: '#1A1A1A',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '23.34px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2, // Limits text to 2 lines
  maxHeight: '2.4em', // 2 lines * line height (1.2em)
  textOverflow: 'ellipsis',
});

export const CustomerCardBlockModal = styled(Stack)({
  gap: '12px',
  alignItems: 'flex-start',
});

export const CustomerCardBlockModalTitle = styled(Stack)({
  gap: '12px',
  alignItems: 'flex-start',
  padding: '24px',
});

export const StyledCloseIcon = styled(CloseIcon)`
  color: #0000008a;
  font-size: 32px;
  cursor: pointer;
`;

export const CardMoreOption = styled('div')({
  color: '#1976D2',
  fontWeight: '500',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  fontSize: '14px',
  justifyContent: 'end',
  minWidth: '80px',
});

export const ICPCardViewsGrid = styled(Stack)({
  alignSelf: 'stretch',
  flexWrap: 'wrap',
  padding: '12px',
  flexDirection: 'row',
  alignItems: 'end',
});

export const AutocompleteMenuItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: '500',
  fontSize: '14px',
});

export const OptionLabelBox = styled(Box)({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
});

export const IcpModalContent = styled(Typography)({ fontSize: '20px', fontWeight: '500' });

export const useStyles = makeStyles((theme) => ({
  noDataTxT: {
    color: theme.palette.text.grey,
  },
  modalContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
      maxWidth: '720px',
    },
    '& .MuiDialogTitle-root': {
      boxSizing: 'border-box',
      padding: '24px',
      '& > div': {
        alignItems: 'baseline',
      },
    },
    '& .MuiDialogActions-root': {
      padding: '0px !important',
    },
    '& .MuiDialogContent-root': {
      padding: '0px !important',
    },
  },
  modalCloseIcon: { fontSize: '32px', color: '#0000008a' },
  icpModalCloseIcon: { color: 'rgba(0,0,0,0.60)', fontSize: '32px', cursor: 'pointer' },
  modalTitle: {
    color: '#000000DE',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '23.34px',
  },
  modalContent: {
    borderTop: '1px solid #0000001f',
  },
  formStyle: {
    minHeight: '365px',
    height: '100%',
    width: '100%',
  },
  icpCardContainer: {
    width: 274,
    borderRadius: '12px',
    '& .MuiCardContent-root': { padding: '0px' },
    '& .MuiCardContent-root:last-child': { padding: '0px !important' },
  },
  icpMoreDetailsModalContainer: {
    minWidth: '416px',
    '& .MuiDialog-paper': { borderRadius: '16px', width: '464px' },
  },
  commonModalContainer: {
    '& .MuiDialog-paper': {
      borderRadius: '8px',
    },
  },
  cancelButtonContainer: {
    width: '120px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#666666',
    padding: '8px 24px',
  },
  autocompleteFieldMeniItem: {
    '&:hover': {
      background: 'rgba(25, 118, 210, 0.04) !important',
    },
  },
  buttonContainer: {
    width: '120px',
    padding: '8px 24px',
    borderRadius: '3px',
    textTransform: 'none',
    height: '44px',
    '&.Mui-disabled': {
      backgroundColor: '#B3B3B3 !important',
      color: '#FFFFFF !important',
    },
  },
  addButtonContainer: {
    background: 'transparent',
    color: '#1976D2',
    padding: '9px 16px',
    borderRadius: '3px',
    textTransform: 'none',
    '&:hover': {
      background: '#1976D230',
      boxShadow: 'none !important',
      padding: '10px',
    },
  },
  selectFieldContainer: {
    '& .MuiInputBase-input::placeholder': {
      fontSize: '14px !important',
      fontWeight: '400 !important',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px !important',
      fontWeight: '400 !important',
      paddingTop: '6px !important',
      paddingBottom: '6px !important',
    },
  },

  textFieldContainer: {
    '& .MuiInputBase-input::placeholder': {
      fontSize: '14px !important',
      fontWeight: '400 !important',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px !important',
      fontWeight: '400 !important',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#FFF',
    },
  },

  inputFieldContainer: {
    backgroundColor: '#F9F9F9',
    '& .MuiInputBase-input': {
      fontSize: '14px !important',
      fontWeight: '400 !important',
      padding: '12px 16px !important',
    },
  },

  cardLabelTypography: {
    fontWeight: 500,
    fontSize: '18px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    lineHeight: '1.2em',
    maxHeight: '1.4em',
    textOverflow: 'ellipsis',
  },
  createICPButton: {
    borderRadius: '7px',
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.00) 100%), #0C1721`,
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    padding: '0px',
    '& .MuiButton-icon': {
      margin: '0',
    },
  },
  cancelButtonICPModal: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#666666',
  },
  secondButtonICPModal: {
    fontSize: '16px',
    fontWeight: '500',
    padding: '8px 22px',
  },
}));
