import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Pagination,
} from '@mui/material';
import { ButtonCircle } from './styles';
import { Spacer, Menu, Button } from 'src/components/shared';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TaskItem from './TaskItem';
import moment from 'moment';
import { time_ago } from './helper';
import ContactTasksSkeleton from './ContactTasksSkeleton';
import { useState } from 'react';
import { Empty } from 'src/components/App';

function ContactTasks(props) {
  const [collapse, setCollapse] = useState(true);
  const [editTask, setEditTask] = useState(false);

  useEffect(() => {
    if (!props.showTaskForm && editTask) {
      const contactId = props.match.params.id;
      setEditTask(false);
      props.fetchContactTasks(contactId);
    }
  }, [props.showTaskForm]);

  const handleChange = (event, value) => {
    const contactId = props.match.params.id;
    props.fetchContactTasks(contactId, '', { pageNo: value - 1, perPage: 10 });
  };

  const handleChangeAccordion = () => {
    setCollapse((prevState) => !prevState);
  };

  const { data = [], paging = {} } = props.contactTasks || {};

  if (props.isLoadingContactTasks)
    return (
      <Accordion expanded={true}>
        <AccordionDetails>
          <ContactTasksSkeleton />
        </AccordionDetails>
      </Accordion>
    );
  else
    return (
      <>
        {data.length ? (
          <Accordion expanded={collapse} onChange={handleChangeAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Upcoming Tasks<ButtonCircle>{data.length}</ButtonCircle>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {data.map((task, index) => {
                return (
                  <TaskItem
                    key={index}
                    index={index}
                    type={task.taskType}
                    title={task.name || ''}
                    date={task?.scheduledOn}
                    // date={moment(tasks?.dueOn + ' ' + tasks?.dueTime, 'YYYY/MM/DD HH:mm')?._i.trim()}
                    assignedTo={task.assignedTo}
                    id={task.id}
                    tasks={task}
                    toggleAddTaskForm={props.toggleAddTaskForm}
                    setEditTask={setEditTask}
                  />
                );
              })}
            </AccordionDetails>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent={'center'}
              mt={2}
              mb={2}
            >
              <Pagination
                count={Math.ceil(paging.count / paging.perPage)}
                page={paging.pageNo + 1}
                onChange={handleChange}
              />
            </Box>
          </Accordion>
        ) : (
          <Accordion expanded={true}>
            <AccordionDetails>
              <Empty title="No Tasks found!" />
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
}
ContactTasks.propTypes = {};

export default ContactTasks;
