import React from 'react';
import { Box, Grid, Stack, useTheme } from '@mui/material';
import {
  AIVoiceActionContainer,
  AIVoiceContentHeader,
  AIVoiceCreateViewHeader,
  AIVoiceCreateViewSubHeader,
  DetailedContent,
  HeadLineContent,
  StyledButton,
  useStyles,
} from '../../components/Voice/styles';
import images from 'src/config/images';
import { Button } from '../../../../components/shared';

const CreateVoiceDefaultView = () => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <Stack sx={{ background: theme.palette.background.paper }}>
        <Stack
          direction={'row'}
          gap={'16px'}
          sx={{ padding: '24px', borderBottom: `1px solid ${theme.palette.border.default}` }}
        >
          <img src={images.voice.toneOfVoiceIcon} alt={''} height={32} width={32} />
          <Stack gap={'8px'}>
            <AIVoiceCreateViewHeader>AI: Create a Tone of Voice</AIVoiceCreateViewHeader>
            <AIVoiceCreateViewSubHeader isGrey>
              Set up a tone of voice profile by analysing example copy to make your generated
              content sound just like you!
            </AIVoiceCreateViewSubHeader>
          </Stack>
        </Stack>
        <Stack gap={'24px'} sx={{ padding: '24px 72px' }}>
          <Stack
            gap={'24px'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <AIVoiceCreateViewHeader>
              Based on the example copy, here is your tone of voice!
            </AIVoiceCreateViewHeader>
            <Button
              startIcon={<img src={images.voice.reanalyseIcon} alt={''} />}
              variant={'text'}
              className={classes.reAnalyseButton}
            >
              Re-analyse
            </Button>
          </Stack>
          <Grid container columnSpacing={'24px'}>
            <Grid item xs={12} md={7}>
              <Stack gap={'24px'}>
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Headline</AIVoiceContentHeader>
                  <HeadLineContent className={classes.gradiantBG}>
                    Conversational yet authoritative, helpful and insightful.
                  </HeadLineContent>
                </Stack>
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Detailed Profile</AIVoiceContentHeader>
                  <DetailedContent className={classes.gradiantBG}>
                    <span style={{ fontWeight: 700 }}>Supportive and Inspiring:</span>
                    <br />
                    Use a positive, motivating tone that empowers readers to take action,
                    acknowledging their challenges and reinforcing their abilities.
                    <br />
                    <br />
                    <span style={{ fontWeight: 700 }}>Authentic and Experience-Based:</span>
                    <br />
                    Share relatable, experience-based insights to connect emotionally, balancing
                    authority with humility.
                    <br />
                    <br />
                    <span style={{ fontWeight: 700 }}>Action-Oriented and Solution-Focused:</span>
                    <br />
                    Encourage small, manageable steps with practical tips, emphasizing their
                    long-term impact.
                    <br />
                    <br />
                    <span style={{ fontWeight: 700 }}>Professional yet Relatable:</span>
                    <br />
                    Keep the tone friendly and conversational, using inclusive language to build
                    camaraderie and avoid formality.
                    <br />
                    <br />
                    <span style={{ fontWeight: 700 }}>Encouraging Progress:</span>
                    <br />
                    Promote progress over perfection, highlighting persistence and self-compassion
                    for motivation.{' '}
                  </DetailedContent>
                </Stack>
                <Grid container columnSpacing={'24px'}>
                  <Grid item xs={12} md={6}>
                    <Stack gap={'8px'}>
                      <AIVoiceContentHeader>Style</AIVoiceContentHeader>
                      <StyledButton fullWidth>
                        <DetailedContent sx={{ fontWeight: 700 }}>Neutral</DetailedContent>
                      </StyledButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack gap={'8px'}>
                      <AIVoiceContentHeader>Tone</AIVoiceContentHeader>
                      <StyledButton fullWidth>
                        <DetailedContent sx={{ fontWeight: 700 }}>Encouraging</DetailedContent>
                      </StyledButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack gap={'24px'} height="100%">
                <Stack gap={'8px'}>
                  <AIVoiceCreateViewHeader>Your Tone of Voice Example</AIVoiceCreateViewHeader>
                  <AIVoiceCreateViewSubHeader isGrey>
                    Here is an example of your tone of voice in action
                  </AIVoiceCreateViewSubHeader>
                </Stack>
                <AIVoiceActionContainer>
                  <DetailedContent isGrey>
                    In cold emailing, each message is a door opener, not a sales pitch. Focus on who
                    your ideal customer is and speak directly to their needs.
                  </DetailedContent>
                  <DetailedContent isGrey>
                    Remember, effective cold emails aren’t about pushing for the sale but about
                    sparking a conversation and building rapport. We’ve gathered data-backed
                    insights that can guide you in the right direction—because, after all, the more
                    informed your outreach, the better your results.
                  </DetailedContent>
                </AIVoiceActionContainer>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Box padding="24px" display={'flex'} gap="16px" justifyContent={'end'}>
          <Button className={classes.textButton} color={'secondary'} onClick={() => null}>
            Back to input screen
          </Button>
          <Button variant={'contained'} color="secondary" className={classes.secondaryButton}>
            Save
          </Button>
        </Box>
      </Stack>
    </>
  );
};
export default CreateVoiceDefaultView;
