import React, { useCallback, useState } from 'react';
import {
  ListItemIcon,
  MenuItem,
  Box,
  CircularProgress,
  createFilterOptions,
  useTheme,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AutocompleteMenuItemBox, OptionLabelBox, useStyles } from '../../components/ICP/style';
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'lodash';

const AutocompleteField = ({
  freeSolo = false,
  isDynamicFiled = false,
  item,
  optionList,
  defaultValue,
  handleAutocomplete,
  setFieldValue,
  errors,
  getRenderIcon = null,
  hideInputSearchOnOpen,
  remoteMethod,
  showAddNew,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [remoteOptions, setRemoteOptions] = useState(hideInputSearchOnOpen ? optionList : []);
  const isSingle = isDynamicFiled ? item.type === 'single' : item.lookupType === 'single';
  const placeholderText = `Select ${item?.label.toLowerCase()}`;
  const filter = createFilterOptions();

  const onType = useCallback(
    debounce(async (val) => {
      if (typeof remoteMethod !== 'undefined' && typeof remoteMethod === 'function') {
        setRemoteOptions([]);
        setLoading(true);
        const data = await getRemoteData(val);
        setRemoteOptions(data);
        setLoading(false);
      }
    }, 500),
    [remoteMethod],
  );

  const getRemoteData = async (val) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await remoteMethod(val && typeof val !== 'undefined' ? val : '');
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      onChange={(e) => {
        e.persist();
        onType(e.target.value);
      }}
      placeholder={placeholderText}
      error={!!errors[item.fieldName] && !isDynamicFiled}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
        startAdornment:
          isSingle && !isDynamicFiled && params.inputProps.value && getRenderIcon?.icon ? (
            <>
              <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                {getRenderIcon.icon}
              </div>
              {params.InputProps.startAdornment}
            </>
          ) : (
            params.InputProps.startAdornment
          ),
      }}
    />
  );

  let optionsList = [];
  if (
    typeof remoteMethod !== 'undefined' &&
    typeof remoteMethod === 'function' &&
    remoteOptions?.length > 0
  ) {
    optionsList = remoteOptions;
  } else {
    optionsList = optionList;
  }

  const getDisplayLabel = (option) => {
    if (showAddNew) {
      return (option?.['x-add-new-label'] ? option?.['x-add-new-label'] : option?.label) || '';
    }
    return option?.label || '';
  };

  const renderOption = (props, option) => (
    <MenuItem
      {...props}
      disabled={option?.disabled || false}
      key={option?.value}
      value={option?.label}
      className={classes.autocompleteFieldMeniItem}
    >
      {option?.icon ? (
        <AutocompleteMenuItemBox>
          <ListItemIcon sx={{ minWidth: '44px' }}>{option.icon}</ListItemIcon>
          <OptionLabelBox>{getDisplayLabel(option)} </OptionLabelBox>
        </AutocompleteMenuItemBox>
      ) : (
        <OptionLabelBox>{getDisplayLabel(option)}</OptionLabelBox>
      )}
    </MenuItem>
  );

  const handleChange = (event, newValue) => {
    if (
      newValue &&
      ((showAddNew && Array.isArray(newValue) && newValue.at(-1)?.['x-add-new-label']) ||
        newValue?.hasOwnProperty(['x-add-new-label']))
    ) {
      if (isDynamicFiled) {
        handleAutocomplete(
          event,
          newValue?.map((item) => ({ label: item.label, value: item?.value || item?.label })),
          item.value,
        );
      } else {
        handleAutocomplete(event, { value: newValue.label, label: newValue.label }, item.value);
      }
      setFieldValue(
        isDynamicFiled ? item?.value : item.fieldName,
        isSingle
          ? { value: newValue?.label, type: 'single' }
          : { value: newValue?.map((item) => item.label), type: 'multiple' },
      );
      return;
    }
    if (isDynamicFiled) {
      handleAutocomplete(event, newValue, item.value);
    } else {
      handleAutocomplete(event, newValue, item.fieldName);
    }
    setFieldValue(
      isDynamicFiled ? item.value : item.fieldName,
      isSingle
        ? { value: newValue?.value, type: 'single' }
        : { value: newValue?.map((item) => item.label), type: 'multiple' },
    );
  };

  const getOption = () => {
    const options = optionsList ?? item?.fieldOptions ?? [];
    const defaultValues = Array.isArray(defaultValue) ? defaultValue : [];

    return options.map((option) => ({
      ...option,
      disabled: defaultValues?.some((defaultItem) => defaultItem.value === option.value) || false,
    }));
  };

  return (
    <Box width="100%" sx={{ '& .MuiFormControl-root': { margin: '0px !important' } }}>
      <Autocomplete
        freeSolo={freeSolo}
        options={getOption()}
        fullWidth
        forcePopupIcon
        name={item.fieldName}
        multiple={!isSingle}
        filterSelectedOptions={!isSingle}
        onChange={handleChange}
        onOpen={(event) => {
          onType(hideInputSearchOnOpen ? '' : event?.target?.value);
        }}
        disableCloseOnSelect={!isSingle}
        defaultValue={isSingle ? defaultValue : Array.isArray(defaultValue) ? defaultValue : []}
        limitTags={!isSingle ? 1 : undefined}
        placeholder={placeholderText}
        getOptionLabel={(option) => option?.label || ''}
        renderInput={renderInput}
        renderOption={renderOption}
        className={classes.selectFieldContainer}
        filterOptions={(options, params) => {
          let filtered = filter(options, params);
          if (showAddNew) {
            let { inputValue } = params;
            const isExisting = options.some(
              (option) => inputValue?.trim() === option?.label?.trim(),
            );
            filtered = [
              ...(inputValue !== '' && !isExisting && !loading
                ? [
                    {
                      value: undefined,
                      label: inputValue,
                      'x-add-new-label': `+ Add ${inputValue}`,
                    },
                  ]
                : []),
              ...filtered,
            ];
          }
          return filtered;
        }}
        ChipProps={{
          deleteIcon: (
            <CloseIcon
              sx={{
                cursor: 'pointer',
                color: '#1976D2 !important',
                fontSize: '16px',
                marginTop: '2px !important',
              }}
            />
          ),
          sx: {
            borderRadius: '5px',
            background: '#F0F7FF',
            fontSize: '14px',
            fontWeight: '400',
            maxWidth: '270px !important',
          },
        }}
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            backgroundColor: theme.palette.text.white1,
          },
          '& .Mui-focused': {
            backgroundColor: theme.palette.text.white1,
          },
        }}
        size="small"
        variant="outlined"
        optLabel="label"
        optValue="value"
      />
    </Box>
  );
};

export default AutocompleteField;
