export const styleItems = ['neutral', 'formal', 'informal'];

export const toneItems = [
  'empathetic',
  'friendly',
  'professional',
  'funny',
  'authoritative',
  'constructive',
  'encouraging',
  'engaging',
  'persuasive',
  'anticipatory',
  'educational',
  'diplomatic',
];

export const emptyScreenActiveItems = ['encouraging', 'constructive', 'educational'];

export const sampleVoiceLookup = [
  {
    key: 'empathetic',
    label: 'Empathetic',
    voice: 'I understand how you feel. I am here to help you.',
  },
  {
    key: 'Friendly',
    label: 'Friendly',
    voice: 'Hi there 😊 How can I help you today?',
  },
  {
    key: 'Professional',
    label: 'Professional',
    voice: 'Hello, I am here to assist you. How can I help you today?',
  },
  {
    key: 'Funny',
    label: 'Funny',
    voice: 'Hey there! I am here to make you laugh and help you out. 😄',
  },
  {
    key: 'Authoritative',
    label: 'Authoritative',
    voice: 'I am here to help you. Please provide me with the necessary information.',
  },
  {
    key: 'Constructive',
    label: 'Constructive',
    voice: 'I am here to help you. Let’s work together to solve your problem.',
  },
  {
    key: 'Encouraging',
    label: 'Encouraging',
    voice: 'I am here to help you. You are doing great! Keep going.',
  },
  {
    key: 'Engaging',
    label: 'Engaging',
    voice: 'I am here to help you. Let’s work together to solve your problem.',
  },
  {
    key: 'Persuasive',
    label: 'Persuasive',
    voice: 'I am here to help you. Let me show you how we can solve your problem.',
  },
  {
    key: 'Anticipatory',
    label: 'Anticipatory',
    voice: 'I am here to help you. I know what you need and I am ready to help you.',
  },
  {
    key: 'Educational',
    label: 'Educational',
    voice: 'I am here to help you. Let me show you how we can solve your problem.',
  },
  {
    key: 'Diplomatic',
    label: 'Diplomatic',
    voice: 'I am here to help you. Let’s work together to solve your problem.',
  },
];

export const keysToRemove = [
  'createdAt',
  'updatedAt',
  'id',
  'tenant',
  'user',
  'isDeleted',
  'deletedAt',
];
