import React from 'react';
import { useTheme } from '@mui/material';

import { AppGrid } from 'src/components/App';
import { voiceColumns } from './VoiceColumns';
import { CreateVoiceBtn } from './utils';

function VoicesGrid({ drawerToggle, voices, loading, userBulkUpdate, ...props }) {
  const theme = useTheme();
  const { data = [], filters, paging, sort = 'status:asc' } = voices;

  const columns = voiceColumns(data, drawerToggle, props);

  const handleTableChange = ({ pageNo, perPage }) => {
    props.fetchVoices(
      {
        pageNo,
        perPage,
      },
      filters,
      sort,
    );
  };

  const onSort = (sort) => {
    props.fetchVoices(paging, filters, sort);
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchVoices(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          sort,
        );
      } else {
        props.fetchVoices(paging, { ...filters, [key]: value }, sort);
      }
    } else {
      delete filters[key];
      props.fetchVoices(paging, filters, sort);
    }
  };

  return (
    <>
      <AppGrid
        selectableRows="none"
        columns={columns.map((col, index) => ({
          ...col,
          options: columns?.columns?.length
            ? { ...col.options, ...columns?.columns[index] }
            : col.options,
        }))}
        onSort={onSort}
        data={data}
        onTableChange={handleTableChange}
        loading={loading?.voices}
        onFilterChange={onFilterChange}
        appliedFilters={filters}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
          },
          searchSx: {
            background: `${theme.palette.text.white} !important`,
          },
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'none',
          searchPlaceholder: 'Search tone of voice',
          searchText: filters?._search ? filters._search : '',
          searchOpen: true,
          search: true,
          renderCustomFilterActions: () => {
            return (
              <CreateVoiceBtn
                setOpenAICreateVoice={props?.setOpenAICreateVoice}
                formProps={props?.formProps}
              />
            );
          },
        }}
      />
    </>
  );
}

export default VoicesGrid;
