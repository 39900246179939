import { styled } from '@mui/system';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonBase } from '@mui/material';
import { BorderBottom } from '@mui/icons-material';

export const contactFormStyles = makeStyles((theme) => ({
  contactDetailsForm: {
    '& .MuiFormControl-root .MuiSvgIcon-root': {
      fill: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      fontSize: '1.76em',
    },
  },
}));

export const ProfileActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& button svg': {
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
    fontSize: '1.2em',
  },
  '& button': {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      '& svg': {
        color: '#ffffff',
        fill: '#ffffff',
      },
    },
  },
}));

export const ContactDetailsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const ContactDetailsItem = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  '& .title': {
    color: 'rgba(0,0,0,0.6)',
    flex: 1,
    minWidth: '64px',
    marginTop: '4px',
  },
}));
export const ContentWrapper = styled('div')(({ theme, showType }) => ({
  display: 'flex',
  flex: 4,
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& .placeholder': {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  '& .addNewButton .MuiTypography-root': {
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
  },
  '& .addNewButton .MuiTypography-root svg': {
    fontSize: '1.25em',
    paddingRight: '4px',
  },
}));
export const ContactItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: '100%',
  position: 'relative',
  alignItems: 'center',
  '& .action-btn': {
    display: 'none',
    position: 'absolute',
    justifyContent: 'end',
    margin: 0,
    padding: '0 10px',
    right: 0,
    backgroundColor: 'white',
    gap: 8,
    width: 'fit-content',
  },
  '& .__value': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&:hover': {
    '& .__value': {
      maxWidth: '140px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .action-btn': {
      position: 'static',
      display: 'flex',
    },
  },
}));

export const ContactItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyItems: 'space-between',
  width: '100%',
  '& .contact': {
    color: theme.palette.secondary.main,
    flex: 'inherit',
  },
  '& .contactType': {
    color: 'rgba(0,0,0,0.6)',
    paddingLeft: '4px',
  },
  '& .MuiButtonBase-root .MuiSvgIcon-root': {
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    color: 'rgba(0,0,0,0.6)',
  },
}));

export const SocialDetailsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: 8,
}));

export const SocialMediaItem = styled(ButtonBase)(({ theme, status }) => ({
  display: 'flex',
  '&.Mui-disabled': {
    opacity: 0.7,
  },
  '& .MuiSvgIcon-root': {
    fill: status === 'active' ? theme.palette.secondary.main : theme.palette.grey[400],
    fontSize: '28px',
  },
}));

export const EmailFormGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 20,

  '& > div': {
    margin: 0,
    borderTop: 'none !important',

    '& > .MuiAutocomplete-root': {
      borderBottom: 'none !important',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    '& fieldset': {
      borderRadius: '0 !important',
    },
  },
}));
