import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert, Box, ButtonBase, Tooltip, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Modal, Drawer, Spacer, Menu } from 'src/components/shared';
import { ConfirmDialog } from 'src/components/App';
import {
  ContactDetailsWrapper,
  ContactDetailsItem,
  ContentWrapper,
  ContactItemWrapper,
  SocialDetailsWrapper,
  SocialMediaItem,
} from './styles';

import images from 'src/config/images';
import { ReactComponent as TwitterX } from '../../../../assets/icons/twitter.svg';
import ContactDetailsForm from './ContactDetailsForm';
import SocialMediaForm from './SocialMediaForm';
import ContactItem from './ContactItem';
import EditIcon from '@mui/icons-material/Edit';
import GitHubIcon from '@mui/icons-material/GitHub';

import {
  updateContactSocialMedia,
  postContactDetails,
  putContactDetails,
  deleteContactDetail,
  fetchContact,
} from '../../actions/contactActions';

import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import AddIcon from '@mui/icons-material/Add';
import api from 'src/api';
import { checkEmailUnique, checkPhoneUnique } from 'src/utils/validation';
import { useSelector } from 'react-redux';
import toast from 'src/utils/toast';
import SearchContactDetails from './SearchContactDetails';

import { fetchContactDetails, fetchEnrichSearch } from 'src/modules/contacts/api/contactApis';
import { capitalizeFirstLetter } from 'src/utils/helper';
function ContactDetails({
  contact,
  showSelection = false,
  selected,
  onSelectionChange,
  onChange,
  type,
  isEnroll,
  ...props
}) {
  const dispatch = useDispatch();
  const integrations = useSelector((state) => state.admin.admin.integrations);
  const [formType, setFormType] = useState(undefined);
  const [formData, setFormData] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [deleteContactItem, setDeleteContactItem] = useState({});
  const [deleteType, setDeleteType] = useState('');
  const [dataCreditError, setDataCreditError] = useState({});

  const [deleting, setDeleting] = useState(false);

  const formRef = useRef();

  const getLinkName = (url) => {
    const segments = new URL(url).pathname.split('/');
    const last = segments.pop() || segments.pop();
    return last;
  };

  const findErrors = async (formType) => {
    try {
      const type = formRef?.current?.values?.type || '';
      let payload = {};
      switch (true) {
        case formType === 'email':
          payload = {
            type: `${type}Email`,
            id: contact.id,
          };
          break;

        case formType === 'phone':
          payload = {
            type: type,
            id: contact.id,
          };
          break;
      }

      setLoader(true);
      if (contact.id) {
        let linkedin = contact.socialLinks.filter((item) => item.type === 'linkedin');
        if (linkedin && linkedin.length) {
          const pathname = new URL(linkedin[0].link).pathname;
          payload = {
            ...payload,
            link: pathname,
          };
        }

        const searchResult = await fetchEnrichSearch([payload]);
        const workEmail =
          searchResult?.contacts[0]?.emails?.length > 0
            ? searchResult.contacts[0].emails.find((element) => {
                return element.type === 'work';
              })?.email
            : '';

        const personalEmail =
          searchResult?.contacts[0]?.emails?.length > 0
            ? searchResult.contacts[0].emails.find((element) => {
                return element.type === 'personal';
              })?.email
            : '';

        const phone =
          searchResult?.contacts[0]?.phones?.length > 0
            ? searchResult.contacts[0].phones[0]?.phone?.number
            : '';

        const linkedIn = searchResult?.contacts[0]?.linkedin_url || '';

        switch (formType) {
          case 'email':
            setFormData({
              ...formData,
              email: workEmail,
            });
            break;
          case 'personal_email':
            setFormData({
              ...formData,
              email: personalEmail,
            });
            break;
          case 'phone':
            setFormData({
              ...formData,
              phone,
            });
            break;
          case 'linkedin':
            setFormData({
              ...formData,
              linkedin: linkedIn,
            });
            break;
        }
        toast.success(
          `${
            ['email', 'personal_email']?.includes(formType)
              ? workEmail || personalEmail
                ? `${formType === 'email' ? 'Work email' : 'Personal email'} fetched succesfully`
                : `No ${formType === 'email' ? 'work email' : 'personal email'} found`
              : ['phone']?.includes(formType)
              ? phone
                ? 'Phone number fetched succesfully'
                : 'No phone number found'
              : linkedIn
              ? 'LinkedIn fetched succesfully'
              : 'No linkedIn profile found'
          }`,
        );
      }
      setLoader(false);
      setLoader(false);
    } catch (error) {
      toast.error(error?.error?.message || 'Error occured! Please try again.');
      setLoader(false);
    }
  };

  const openForm = (type, value = undefined) => {
    if (type === 'social') {
      if (value && value !== '') {
        value = !value.split('https://')?.[1] ? 'https://' + value : value;
        window.open(value, '_blank', 'noopener,noreferrer');
      } else {
        setFormType(type);
      }
    } else {
      if (value) {
        setFormData(value);
      }
      setFormType(type);
    }
  };
  const onFormClose = () => {
    setFormData(undefined);
    setFormType(undefined);
  };
  const onDelete = (type, item) => {
    setDeleteContactItem(item);
    setDeleteType(type);
  };
  const closeDeleteModal = () => {
    setDeleteContactItem({});
    setDeleteType('');
  };
  const confirmDelete = async () => {
    try {
      setDeleting(true);
      await new Promise((resolve, reject) => {
        dispatch(
          deleteContactDetail(contact.id, deleteType, deleteContactItem.id, resolve, reject),
        );
      });
      onChange();
      setDeleting(false);
      await closeDeleteModal();
    } catch (error) {}
  };

  const updateSocialData = (data, contact) => {
    return new Promise((resolve, reject) => {
      dispatch(updateContactSocialMedia(contact.id, data, resolve, reject));
    });
  };
  const addContactDetails = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(postContactDetails(contact.id, formType, data, resolve, reject));
    });
  };
  const editContactDetails = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(putContactDetails(contact.id, formType, formData.id, data, resolve, reject));
    });
  };
  const onSubmit = async (data, type, form) => {
    try {
      if (formType === 'social') {
        await updateSocialData(data, contact);
      } else {
        if (formData?.id && formData?.id !== '') {
          await editContactDetails(data);
        } else {
          await addContactDetails(data);
        }
        await props?.fetchContact(props?.match?.params?.id)
      }
      if (props?.fetchSequenceEnrollment) {
        await props?.fetchSequenceEnrollment?.(props?.match?.params?.id);
      }
      onFormClose();
      onChange();
    } catch (error) {
      form.setFieldError(type, error?.message || 'Error occured, Please try again.');
      return error;
    }
  };

  let socialData = useMemo(() => {
    if (contact?.socialLinks?.length) {
      return contact.socialLinks.reduce((data, value) => {
        return { ...data, [value.type]: value.link };
      }, {});
    }
    return {};
  }, [contact?.socialLinks]);

  const socialLinkPresent =
    socialData?.instagram ||
    socialData?.facebook ||
    socialData?.twitter ||
    socialData?.linkedin ||
    socialData?.linkedinRecruiter ||
    socialData?.linkedinSalesNavigator ||
    socialData?.github ||
    socialData?.stackoverflow;

  const modalTitle = useMemo(() => {
    return formType === 'email'
      ? formData?.id
        ? 'Edit email address'
        : 'Add Email address'
      : formData?.id
      ? 'Edit Phone number'
      : 'Add Phone number';
  }, [formType, formData]);

  const isUnsubscribed = contact.status === 'unsubscribed';

  return (
    <>
      <ContactDetailsWrapper>
        {Object.keys(dataCreditError)?.length !== 0 && (
          <>
            {Object.keys(dataCreditError)?.map((key) => {
              if (!dataCreditError[key]) return null;
              return (
                <>
                  <Alert
                    severity="warning"
                    onClose={() => {
                      delete dataCreditError[key];
                      setDataCreditError({ ...dataCreditError });
                    }}
                  >
                    {typeof dataCreditError[key] === 'string'
                      ? capitalizeFirstLetter(dataCreditError[key])
                      : dataCreditError[key]}
                  </Alert>
                  <Spacer x={0.75} y={0.75} />
                </>
              );
            })}
            <Spacer x={1.25} y={1.25} />
          </>
        )}

        {isUnsubscribed && (
          <Fragment>
            <Alert severity="error">Email unsubscribed.</Alert>
            <Spacer y={1.5} />
          </Fragment>
        )}

        <ContactDetailsItem>
          <ContentWrapper>
            {contact?.emails?.filter((item) => !item?.isDeleted)?.length ? (
              contact.emails.map((email, index) => (
                <ContactItem
                  key={`contact-details-email-${index}`}
                  item={email}
                  type="email"
                  isUnsubscribed={isUnsubscribed}
                  props={props}
                  onEdit={openForm}
                  onDelete={onDelete}
                  onAdd={() => openForm('email')}
                  showSelection={showSelection}
                  selected={selected}
                  onSelectionChange={onSelectionChange}
                  isEnroll={isEnroll}
                />
              ))
            ) : (
              <Tooltip
                title={isUnsubscribed ? 'Cannot perform this action on an unsubscribed user.' : ''}
              >
                <Button
                  disabled={isUnsubscribed}
                  size="small"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: 14,
                    marginTop: -5,
                    '&.Mui-disabled': { opacity: 0.7, color: '#1976D2' },
                  }}
                  onClick={() => openForm('email')}
                  color="secondary"
                >
                  Add Email
                </Button>
              </Tooltip>
            )}
          </ContentWrapper>
        </ContactDetailsItem>

        <Spacer x={1.25} y={1.25} />

        <ContactDetailsItem>
          <ContentWrapper>
            {contact?.phones?.filter((item) => !item?.isDeleted)?.length ? (
              contact.phones.map((phone, index) => (
                <ContactItem
                  key={`contact-details-phone-${index}`}
                  item={phone}
                  isUnsubscribed={isUnsubscribed}
                  type="phone"
                  onEdit={openForm}
                  onDelete={onDelete}
                  onAdd={() => openForm('phone')}
                  showSelection={showSelection}
                  selected={selected}
                  onSelectionChange={onSelectionChange}
                  isEnroll={isEnroll}
                />
              ))
            ) : (
              <Tooltip
                title={isUnsubscribed ? 'Cannot perform this action on an unsubscribed user.' : ''}
              >
                <Button
                  size="small"
                  disabled={isUnsubscribed}
                  // startIcon={<AddIcon />}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: 14,
                    marginTop: -5,
                    '&.Mui-disabled': { opacity: 0.7, color: '#1976D2' },
                  }}
                  onClick={() => openForm('phone')}
                  color="secondary"
                >
                  Add Number
                </Button>
              </Tooltip>
            )}
          </ContentWrapper>
        </ContactDetailsItem>

        <Spacer x={1.25} y={1.25} />

        <ContactDetailsItem>
          <ContentWrapper>
            <SocialDetailsWrapper>
              {socialLinkPresent ? (
                <>
                  {socialData?.instagram && (
                    <SocialMediaItem
                      disabled={isUnsubscribed}
                      status={'active'}
                      onClick={() => openForm('social', socialData?.instagram)}
                    >
                      <InstagramIcon />
                    </SocialMediaItem>
                  )}
                  {socialData?.facebook && (
                    <SocialMediaItem
                      disabled={isUnsubscribed}
                      status={'active'}
                      onClick={() => openForm('social', socialData?.facebook)}
                    >
                      <FacebookIcon />
                    </SocialMediaItem>
                  )}
                  {socialData?.twitter && (
                    <SocialMediaItem
                      disabled={isUnsubscribed}
                      status={'active'}
                      onClick={() => openForm('social', socialData?.twitter)}
                    >
                      <TwitterX />
                    </SocialMediaItem>
                  )}
                  {(socialData?.linkedin ||
                    socialData?.linkedinRecruiter ||
                    socialData?.linkedinSalesNavigator) && (
                    <SocialMediaItem
                      disabled={isUnsubscribed}
                      status={'active'}
                      onClick={() =>
                        openForm(
                          'social',
                          socialData?.linkedin ||
                            socialData?.linkedinRecruiter ||
                            socialData?.linkedinSalesNavigator,
                        )
                      }
                    >
                      <LinkedInIcon />
                    </SocialMediaItem>
                  )}

                  {socialData?.github && (
                    <SocialMediaItem
                      disabled={isUnsubscribed}
                      status={'active'}
                      onClick={() => openForm('social', socialData?.github)}
                    >
                      <GitHubIcon />
                    </SocialMediaItem>
                  )}

                  {socialData?.stackoverflow && (
                    <SocialMediaItem
                      disabled={isUnsubscribed}
                      status={'active'}
                      onClick={() => openForm('social', socialData?.stackoverflow)}
                    >
                      <img src={images.app.stackOverflow} style={{ width: 24 }} />
                    </SocialMediaItem>
                  )}

                  <ButtonBase disabled={isUnsubscribed} onClick={() => openForm('social')}>
                    <EditIcon sx={{ maxWidth: '18px', color: '#d6d6d6' }} />
                  </ButtonBase>
                </>
              ) : (
                <Tooltip
                  title={
                    isUnsubscribed ? 'Cannot perform this action on an unsubscribed user.' : ''
                  }
                >
                  <Button
                    size="small"
                    disabled={isUnsubscribed}
                    sx={{ textTransform: 'none', fontWeight: 400, fontSize: 14, marginTop: -5 }}
                    onClick={() => openForm('social')}
                    color="secondary"
                  >
                    Add Social Links
                  </Button>
                </Tooltip>
              )}
            </SocialDetailsWrapper>
          </ContentWrapper>
        </ContactDetailsItem>
      </ContactDetailsWrapper>

      <Tooltip title={isUnsubscribed ? 'Cannot perform this action on an unsubscribed user.' : ''}>
        <SearchContactDetails
          contact={contact}
          disabled={isUnsubscribed}
          type={type}
          {...props}
          setDataCreditError={(obj) => {
            setDataCreditError((prevValue) => {
              return { ...prevValue, ...obj };
            });
          }}
        />
      </Tooltip>

      <Modal
        open={formType === 'email' || formType === 'phone'}
        onClose={onFormClose}
        title={modalTitle}
        size="xs"
      >
        <ContactDetailsForm
          formType={formType}
          formData={formData}
          onSubmit={onSubmit}
          onClose={onFormClose}
          contact={contact}
          findErrors={findErrors}
          loader={loader}
          formRef={formRef}
          {...props}
        />
      </Modal>

      <Drawer open={formType === 'social'} title="Social Media" onClose={onFormClose}>
        <SocialMediaForm onClose={onFormClose} onSubmit={onSubmit} formData={socialData} />
      </Drawer>

      <ConfirmDialog
        title="Remove Contact Detail"
        open={deleteContactItem?.id}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        loading={deleting}
      >
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to remove{' '}
          <b>{deleteType === 'email' ? deleteContactItem?.email : deleteContactItem?.phone}?</b>
        </Typography>
      </ConfirmDialog>
    </>
  );
}

ContactDetails.propTypes = {
  onChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
};

ContactDetails.defaultProps = {
  onChange: () => {},
  onSelectionChange: () => {},
};

export default ContactDetails;
