import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Button, Form } from '../../../../../components/shared';
import Dialog from '../../../../../components/shared/DialogV2';
import {
  FieldSubTitle,
  FieldTitle,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useStyles,
} from '../styles';
import images from 'src/config/images';
import DialogContent from '@mui/material/DialogContent';

const AnalyseCopyModal = React.memo(function AnalyseCopyModal({
  onCancel = () => {},
  onSubmit = () => {},
  open = false,
  formProps,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const { errors } = formProps;

  return (
    <Dialog open={open} onClose={onCancel} className={classes.modalContainer}>
      <ModalHeader sx={{ borderBottom: `1px solid ${theme.palette.border.default}` }}>
        <Stack direction="row" gap="8px" alignItems="center">
          <img src={images.voice.toneOfVoiceIcon} alt={'icon'} width={32} height={32} />
          <Typography variant={'h1'}>Analyse Example Copy</Typography>
        </Stack>
        <CloseIcon
          sx={{ color: theme.palette.text.grey1, fontSize: '32px', cursor: 'pointer' }}
          onClick={onCancel}
        />
      </ModalHeader>
      <DialogContent p={'16px 24px'}>
        <ModalContent>
          <Box>
            <FieldTitle variant={'h3'}>Add copy that represents your tone and style.</FieldTitle>
            <FieldSubTitle variant={'body2'} paddingTop="2px">
              Use something substantial, such as an article or several emails. We recommend adding
              <br />
              between 300-1000 words.
            </FieldSubTitle>
            <Form.Field.Input
              page={'auth'}
              padding={0}
              p={0}
              fullWidth
              variant="outlined"
              className={classes.inputFieldContainerAnalyse}
              name="sampleText"
              placeholder="Paste your content here for our AI tone of voice expert to analyse!"
              error={Boolean(errors?.sampleText)}
              helperText={errors?.sampleText}
              multiline
              minRows={7}
            />
          </Box>
        </ModalContent>
      </DialogContent>
      <ModalFooter>
        <Stack width="100%" direction="row" justifyContent="end">
          <Button
            variant={'contained'}
            color="secondary"
            onClick={onSubmit}
            className={classes.secondaryButton}
          >
            Next
          </Button>
        </Stack>
      </ModalFooter>
    </Dialog>
  );
});
export default AnalyseCopyModal;
