import React, { useState } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useHistory } from 'react-router-dom';

const VoiceGridAction = ({ ...props }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const history = useHistory();
  const menuId = 'action-menu-id';

  const handleActionsMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (type) => () => {
    setAnchorElement(null);
    switch (type) {
      case 'editPopUp':
        props.formProps.setFieldValue('voicePrompt', '');
        props?.handleOpenEditModal(props.row);
        break;
      default:
        break;
    }
  };

  const menuItem = [
    {
      title: 'Edit',
      itemType: 'editPopUp',
    },
  ];

  return (
    <React.Fragment>
      <Typography aria-controls={menuId} aria-haspopup="true" onClick={handleActionsMenuOpen}>
        <MoreVertIcon style={{ fill: '#1C1B1F' }} />
      </Typography>
      <Menu
        anchorEl={anchorElement}
        id={menuId}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleMenuClose()}
      >
        {menuItem.map((mItem, index) => (
          <MenuItem key={mItem.itemType + index} onClick={handleMenuClose(mItem.itemType)}>
            {mItem.title}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default VoiceGridAction;
