import React from 'react';
import { useDispatch } from 'react-redux';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { useTheme } from '@mui/material';

import { Menu } from '../../../../components/shared';
import images from 'src/config/images';
import { toggleVisibilityOfProfileTab } from '../../actions/authActions';
import { useStyles } from './styles';

const menuContainer = () => ({
  '& .MuiList-root': { padding: '0px !important', borderRadius: '4px !important' },
});

export const createNewTovButton = (theme) => ({
  borderRadius: '7px !important',
  background: `linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.00) 100%), #0C1721`,
  color: `${theme.palette.background.paper} !important`,
  fontSize: '16px !important',
  fontWeight: '500 !important',
  lineHeight: '20px !important',
  padding: '0 20px 0 0 !important',
  margin: '16px 0px !important',
});

export const CreateVoiceBtn = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        isOpen={open}
        showCustomButton
        endIcon={<ArrowDropDownOutlinedIcon />}
        startIcon={<img src={images.icons.plusIcon} alt={''} />}
        iconButton={false}
        showListItemIcon={false}
        buttonStyles={createNewTovButton(theme)}
        title="Create new tone of voice"
        sx={menuContainer()}
        menuItemClass={classes.menuItem}
        menuLabelClass={classes.menuLabel}
        options={[
          {
            label: 'Analyse example copy',
            icon: <img src={images.voice.toneOfVoiceIcon} alt={'icon'} width={24} height={24} />,
            onClick: () => {
              handleClose();
              props?.formProps?.setValues(initialStateVoiceDataForm);
              props?.setOpenAICreateVoice({ open: true, creationType: 'ai', isPromptView: true });
            },
          },
          {
            label: 'Manually create',
            icon: <img src={images.voice.manualCreateIcon} alt={'icon'} width={24} height={24} />,
            onClick: () => {
              handleClose();
              dispatch(toggleVisibilityOfProfileTab(true));
              props?.formProps?.setValues(initialStateVoiceDataForm);
              props?.setOpenAICreateVoice({ open: true, creationType: 'manual' });
            },
          },
        ]}
      />
    </>
  );
};

export const initialStateVoiceData = {
  name: '',
  isDefault: false,
  params: { style: 'neutral', tone: [] },
  sampleVoice: {},
  sampleText: '',
  voicePrompt: '',
};

export const initialStateVoiceDataForm = {
  name: '',
  isDefault: false,
  tone: [],
  style: 'neutral',
  sampleVoice: {},
  sampleText: '',
  voicePrompt: '',
};
