import React from 'react';
import { LoadingImage, LoadingText, ModalContainer, useStyles } from '../styles';
import { Spacer } from '../../../../../components/shared';
import Dialog from '../../../../../components/shared/DialogV2';
import images from 'src/config/images';

const VoiceLoading = ({ open = false, reAnalyse }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} className={classes.modalContainer}>
      <ModalContainer>
        <Spacer y={5} />
        <LoadingText variant={'h2'}>
          {reAnalyse ? 'Re-analysing' : 'Analysing'} your content to craft the perfect tone of
          voice!
        </LoadingText>
        <LoadingImage>
          <img src={images.voice.tovLoading} alt={''} />
        </LoadingImage>
        <Spacer y={5} />
      </ModalContainer>
    </Dialog>
  );
};
export default VoiceLoading;
