import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, Typography, Stack, Alert, Tooltip } from '@mui/material';
import { Spacer, Drawer, Menu, Button } from 'src/components/shared';
import { BackButton, Accordion, Empty } from 'src/components/App';

import { getContactFields } from 'src/modules/app/actions/appSelector';
import { useDispatch } from 'react-redux';

import Profile from './Profile';
import ContactDetails from '../ContactDetails';
import ProfileActions from './ProfileActions';
import { AddToSequenceForm, SendEmailForm, LeaveNote } from '../ContactActions';
import Skeleton from './Skeleton';
import SequenceItem from './sequence';
import ActivityItem from './NoteActivities/ActivityItem';
import Attributes from './attributes';
import Matches from './Matches';

import { postTask } from 'src/modules/tasks/actions/taskActions';
import { fetchCRMConfig, toggleAddContactForm } from 'src/modules/app/actions/appActions';

import {
  deleteContact,
  putEnrollment,
  addContactToSequence,
  sendContactEmail,
  fetchBullhornMatches,
  setBullhornMatches,
  syncBullhornContact,
  syncBullhornNewContact,
  fetchVincereMatches,
  setVincereMatches,
  syncVincereContact,
  syncVincereNewContact,
  fetchJobAdderMatches,
  setJobAdderMatches,
  syncJobAdderContact,
  syncJobAdderNewContact,
  fetchSalesforceMatches,
  setSalesforceMatches,
  syncSalesforceContact,
  syncSalesforceNewContact,
  fetchHubspotMatches,
  setHubspotMatches,
  syncHubspotContact,
  syncHubspotNewContact,
  postContactNote,
  fetchContactNotes,
  putContact,
  fetchSequenceEnrollment,
} from '../../actions/contactActions';

import EditIcon from '@mui/icons-material/Edit';
import { JobTitle, JobLocation, ContactWrapper } from './styles';

import moment from 'moment/moment';
import { fetchSequenceContacts } from 'src/modules/sequence/actions/sequenceActions';
import MandatoryField from './Mandatory';
import { Span } from 'src/modules/admin/containers/integration/styles';
import ContactProfileTabView from './ContactProfileTabView';
import { Chip } from '@mui/material';
import { getEnrolmentErrors } from 'src/modules/sequence/api/sequenceApi';

export const Contact = ({
  contact,
  sequenceEnrollment,
  isLoading,
  showBack,
  integrations,
  showSequenceActions = false,
  ...props
}) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [CRMDrawer, setCRMDrawer] = useState(false);
  const [CRMField, setCRMFeild] = useState('');
  const [crmType, setCrmType] = useState('');
  const [errors, setErrors] = useState([]);
  const [contactAction, setContactAction] = useState(undefined);

  const [showBullhornDrawer, setShowBullhornDrawer] = useState(false);
  const [showVincereDrawer, setShowVincereDrawer] = useState(false);
  const [showJobAdderDrawer, setShowJobAdderDrawer] = useState(false);
  const [showHubspotDrawer, setShowHubspotDrawer] = useState(false);
  const [showSalesforceDrawer, setShowSalesforceDrawer] = useState(false);
  const [matchesLoading, setMatchesLoading] = useState(true);

  const [prevContactId, setPrevContactId] = useState(contact?.id);
  const [prevEnrollmentId, setPrevEnrollmentId] = useState(sequenceEnrollment?.[0]?.id);

  const toggleContactActionDrawer = (action) => {
    setContactAction(action && action !== '' ? action : undefined);
  };

  const disconnectHandler = async () => {
    await props.putContact(contact?.id, {
      data: {
        [`${crmActiveField}Url`]: '',
        [`${crmActiveField}Type`]: '',
        [`${crmActiveField}Id`]: '',
      },
    });
  };

  useEffect(() => {
    props.setBullhornMatches([], {});
    props.setVincereMatches([], {});
    props.setJobAdderMatches([], {});
    props.setHubspotMatches([], {});
    props.setSalesforceMatches([], {});
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getEnrolmentErrors(
        contact?.id,
        sequenceEnrollment?.[0]?.sequence?.id,
        sequenceEnrollment?.[0]?.id,
      );
      setErrors(res.errors);
    };

    if (
      contact?.id &&
      sequenceEnrollment?.[0]?.id &&
      (contact?.id !== prevContactId || sequenceEnrollment?.[0]?.id !== prevEnrollmentId)
    ) {
      fetchData();
    }
  }, [contact?.id, sequenceEnrollment?.[0]?.id]);

  useEffect(() => {
    const fetchData = async () => {
      if (showBullhornDrawer === true) {
        setMatchesLoading(true);
        await props.fetchBullhornMatches(
          contact,
          crmType === 'Contact' ? 'ClientContact' : crmType,
        );
        setMatchesLoading(false);
      } else {
        setMatchesLoading(true);
      }
    };
    fetchData();
  }, [showBullhornDrawer]);

  useEffect(() => {
    const fetchData = async () => {
      if (showVincereDrawer === true) {
        setMatchesLoading(true);
        await props.fetchVincereMatches(contact, crmType);
        setMatchesLoading(false);
      } else {
        setMatchesLoading(true);
      }
    };
    fetchData();
  }, [showVincereDrawer]);
  useEffect(() => {
    const fetchData = async () => {
      if (showJobAdderDrawer === true) {
        setMatchesLoading(true);
        await props.fetchJobAdderMatches(contact, crmType);
        setMatchesLoading(false);
      } else {
        setMatchesLoading(true);
      }
    };
    fetchData();
  }, [showJobAdderDrawer]);
  useEffect(() => {
    const fetchData = async () => {
      if (showHubspotDrawer === true) {
        setMatchesLoading(true);
        await props.fetchHubspotMatches(contact, crmType);
        setMatchesLoading(false);
      } else {
        setMatchesLoading(true);
      }
    };
    fetchData();
  }, [showHubspotDrawer]);
  useEffect(() => {
    const fetchData = async () => {
      if (showSalesforceDrawer === true) {
        setMatchesLoading(true);
        await props.fetchSalesforceMatches(contact, crmType);
        setMatchesLoading(false);
      } else {
        setMatchesLoading(true);
      }
    };
    fetchData();
  }, [showSalesforceDrawer]);

  const getTitle = useMemo(() => {
    return contactAction == 'sequence'
      ? 'Add to sequence'
      : contactAction == 'emailForm'
      ? 'Compose email'
      : contactAction == 'leaveNote'
      ? 'Leave a Note'
      : undefined;
  }, [contactAction]);

  const getDrawerContent = (type) => {
    switch (contactAction) {
      case 'sequence':
        return (
          <AddToSequenceForm
            contact={contact}
            addContactToSequence={props.addContactToSequence}
            fetchContactNotes={props.fetchContactNotes}
            onClose={() => {
              toggleContactActionDrawer(undefined);
            }}
          />
        );
        break;

      case 'emailForm':
        return (
          <SendEmailForm
            contact={contact}
            sendContactEmail={props.sendContactEmail}
            fetchContactNotes={props.fetchContactNotes}
            onClose={toggleContactActionDrawer}
            fetchContact={props.fetchContact}
          />
        );
        break;

      case 'leaveNote':
        return (
          <LeaveNote
            contact={contact}
            onClose={toggleContactActionDrawer}
            taskPriorityList={props.taskPriorityList}
            taskTypeList={props.taskTypeList}
            user={props.user}
            postContactNote={props.postContactNote}
            fetchContactTasks={props.fetchContactTasks}
          />
        );
        return null;
        break;

      default:
        return null;
        break;
    }
  };

  const isBullhornActive = () => {
    const bullhornIntegration = (integrations?.data || [])?.find(
      (o) => o?.name?.toLowerCase() === 'bullhorn',
    );
    return (
      bullhornIntegration?.status === 'active' &&
      !bullhornIntegration?.errorMessage &&
      !bullhornIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
      !bullhornIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
    );
  };
  const isJobAdderActive = () => {
    const jobadderIntegration = (integrations?.data || [])?.find(
      (o) => o?.name?.toLowerCase() === 'jobadder',
    );
    return (
      jobadderIntegration?.status === 'active' &&
      !jobadderIntegration?.errorMessage &&
      !jobadderIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
      !jobadderIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
    );
  };
  const isVincereActive = () => {
    const vincereIntegration = (integrations?.data || [])?.find(
      (o) => o?.name?.toLowerCase() === 'vincere',
    );
    return (
      vincereIntegration?.status === 'active' &&
      !vincereIntegration?.errorMessage &&
      !vincereIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
      !vincereIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
    );
  };
  const isSalesforceActive = () => {
    const salesforceIntegration = (integrations?.data || [])?.find(
      (o) => o?.name?.toLowerCase() === 'salesforce',
    );
    return (
      salesforceIntegration?.status === 'active' &&
      !salesforceIntegration?.errorMessage &&
      !salesforceIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
      !salesforceIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
    );
  };
  const isHubspotActive = () => {
    const hubspotIntegration = (integrations?.data || [])?.find(
      (o) => o?.name?.toLowerCase() === 'hubspot',
    );
    return (
      hubspotIntegration?.status === 'active' &&
      !hubspotIntegration?.errorMessage &&
      !hubspotIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
      !hubspotIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
    );
  };

  const isAnyCRMActive =
    isBullhornActive() ||
    isJobAdderActive() ||
    isVincereActive() ||
    isSalesforceActive() ||
    isHubspotActive();

  const handleCrmDrawer = (type) => {
    setCrmType(type);
    switch (true) {
      case isBullhornActive() === true:
        setShowBullhornDrawer(true);
        setCRMFeild('Bullhorn');
        break;

      case isJobAdderActive() === true:
        setShowJobAdderDrawer(true);
        setCRMFeild('JobAdder');
        break;

      case isVincereActive() === true:
        setShowVincereDrawer(true);
        setCRMFeild('Vincere');
        break;

      case isSalesforceActive() === true:
        setShowSalesforceDrawer(true);
        setCRMFeild('Salesforce');
        break;

      case isHubspotActive() === true:
        setShowHubspotDrawer(true);
        setCRMFeild('Hubspot');
        break;

      default:
        break;
    }
  };

  const crmActiveField = ['bullhorn', 'vincere', 'jobadder', 'hubspot', 'salesforce']?.find(
    (crmField) => contact?.[crmField + 'Id'],
  );

  const crmActiveFieldType = crmActiveField
    ? contact?.[crmActiveField + 'Type'] === 'ClientContact'
      ? 'Contact'
      : contact?.[crmActiveField + 'Type']
    : null;

  let IntegrationData = {};
  if (integrations?.data?.length > 0) {
    let newdata = integrations?.data?.map((property) => ({
      name: property.name,
      status: property.status,
    }));
    let objectStatus = newdata.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.status }),
      {},
    );
    IntegrationData = objectStatus;
  }
  const isCrmActive = Object.keys(IntegrationData)?.find(
    (key) => IntegrationData[key] === 'active',
  );

  const crmoptions = [
    ...(!isHubspotActive() && !isSalesforceActive()
      ? [
          {
            label: 'Candidate',
            onClick: () => {
              handleCrmDrawer('Candidate');
            },
          },
        ]
      : []),
    {
      label: 'Contact',
      onClick: () => {
        handleCrmDrawer('Contact');
      },
    },
    ...(isBullhornActive()
      ? [
          {
            label: 'Lead',
            onClick: () => {
              handleCrmDrawer('Lead');
            },
          },
        ]
      : []),
  ];

  const isUnsubscribed = contact.status === 'unsubscribed';

  return (
    <ContactWrapper>
      <Box p={'0 16px 16px'}>
        <Stack direction="row" justifyContent="space-between" sx={{ marginBottom: 1 }}>
          <Box>
            {showBack === true && (
              <BackButton
                text="Back"
                onClick={() => {
                  if (location.key) {
                    history.goBack();
                  } else {
                    history.push('/contacts');
                  }
                }}
              />
            )}
          </Box>
          {isLoading || !isCrmActive || !isAnyCRMActive ? null : crmActiveFieldType ? (
            <Box position={'relative'}>
              <Chip
                sx={{
                  backgroundColor: '#4caf50',
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 12,
                  letterSpacing: '0.8px',
                  opacity: isUnsubscribed ? 0.7 : 1,
                }}
                label={crmActiveFieldType}
              />
              <Menu
                sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  '& .MuiButton-endIcon': {
                    display: 'none',
                  },
                }}
                disabled={isUnsubscribed}
                options={[{ onClick: disconnectHandler, label: 'Disconnect' }]}
                iconButton={false}
                buttonStyles={{ padding: 0, textTransform: 'none', fontWeight: 'normal' }}
              />
            </Box>
          ) : props?.loading?.connectingContact ? (
            <Typography color="secondary">Connecting...</Typography>
          ) : crmoptions.length > 1 && isAnyCRMActive ? (
            <Menu
              iconButton={false}
              title="Add to CRM"
              color="secondary"
              disabled={isUnsubscribed}
              buttonStyles={{ padding: 0, textTransform: 'none', fontWeight: 'normal' }}
              options={crmoptions}
            />
          ) : isAnyCRMActive ? (
            <Button
              color="secondary"
              disabled={isUnsubscribed}
              sx={{ '&.Mui-disabled': { opacity: 0.7 } }}
              onClick={crmoptions?.[0]?.onClick}
            >
              Add to CRM
            </Button>
          ) : (
            <div />
          )}
        </Stack>

        {isLoading ? (
          <>
            <Skeleton />
          </>
        ) : (
          <>
            <Profile
              contact={contact}
              putEnrollment={props.putEnrollment}
              sequenceEnrollment={sequenceEnrollment}
              toggleAddContactForm={props.toggleAddContactForm}
              showSequenceActions={showSequenceActions}
              toggleContactActionDrawer={toggleContactActionDrawer}
            />
            {errors?.length !== 0 &&
              errors.map((error) => (
                <Box mt={2}>
                  <Alert severity="warning" sx={{ '& .MuiAlert-icon ': { color: '#5F2B01' } }}>
                    {error.message}
                  </Alert>
                </Box>
              ))}
            {contact?.emails?.filter(({ email }) =>
              props.adminSettings?.[0]?.blockedDomains?.includes(email.split('@')[1]),
            )?.length !== 0 && (
              <Box mt={2}>
                <Alert severity="warning" sx={{ '& .MuiAlert-icon ': { color: '#5F2B01' } }}>
                  Email domain is blocked, please replace email.
                </Alert>
              </Box>
            )}
            <ProfileActions
              contact={contact}
              deleteContact={props.deleteContact}
              fetchSequenceContacts={props.fetchSequenceContacts}
              sequence={props.sequence}
              toggleContactActionDrawer={toggleContactActionDrawer}
            />
          </>
        )}
      </Box>
      <Box>
        <Accordion title="Contact Details" expanded={true}>
          <Box sx={{ padding: '8px 16px' }}>
            <ContactDetails contact={contact} {...props} />
          </Box>
        </Accordion>
        <Accordion title="Sequence History" expanded={false}>
          <Box sx={{ padding: '16px 12px' }}>
            {sequenceEnrollment.length ? (
              <>
                {sequenceEnrollment.map((sequence, index) => (
                  <SequenceItem
                    contact={contact}
                    sequence={sequence}
                    putEnrollment={props.putEnrollment}
                    isUnsubscribedUser={isUnsubscribed}
                    key={`sequence-item-${sequence.id}-${index}`}
                  />
                ))}
              </>
            ) : (
              <Tooltip
                title={isUnsubscribed ? 'Cannot perform this action on an unsubscribed user.' : ''}
              >
                <Empty
                  title="No sequences found"
                  description="Click here to add to a sequence!"
                  buttonTitle="Add to sequence"
                  buttonProps={{
                    variant: 'outlined',
                    size: 'small',
                  }}
                  onClick={() => {
                    if (!isUnsubscribed) toggleContactActionDrawer('sequence');
                  }}
                />
              </Tooltip>
            )}
          </Box>
        </Accordion>
        <Accordion title="Activity" expanded={false}>
          {props?.contactNote?.length > 0 ? (
            props.contactNote.map((note, index) => (
              <ActivityItem data={note} key={`contact-activity-${index}`} />
            ))
          ) : (
            <Empty title="No activities found!" />
          )}
        </Accordion>
        <Accordion title="Attributes" expanded={false}>
          <Box sx={{ padding: 3 }} display={'flex'} gap={2} flexDirection={'column'}>
            <Attributes contact={contact} fields={props.fields} />
          </Box>
        </Accordion>
        <Spacer x={4} y={4} />
        <Drawer
          showClose={false}
          size="small"
          sx={{
            '& .drawer-header': {
              padding: '8px 16px',
            },
          }}
          open={showBullhornDrawer}
          title={<ContactProfileTabView contact={contact} />}
          onClose={() => setShowBullhornDrawer(false)}
          disabledPadding={true}
        >
          {showBullhornDrawer && (
            <Matches
              initialLoading={props.loading?.bullhornMatches || matchesLoading}
              matches={props.bullhornMatches}
              match={props.bullhornMatch}
              CRMField={CRMField}
              contact={contact}
              closeDrawer={() => setShowBullhornDrawer(false)}
              syncContact={props.syncBullhornContact}
              onClose={(value) => {
                setCRMDrawer(value);
                setShowBullhornDrawer(false);
              }}
            />
          )}
        </Drawer>
        <Drawer
          showClose={false}
          size="small"
          sx={{
            '& .drawer-header': {
              padding: '8px 16px',
            },
          }}
          open={showVincereDrawer}
          title={<ContactProfileTabView contact={contact} />}
          onClose={() => setShowVincereDrawer(false)}
          disabledPadding={true}
        >
          {showVincereDrawer && (
            <Matches
              initialLoading={props.loading?.vincereMatches || matchesLoading}
              matches={props.vincereMatches}
              match={props.vincereMatch}
              contact={contact}
              CRMField={CRMField}
              syncContact={props.syncVincereContact}
              closeDrawer={() => setShowVincereDrawer(false)}
              onClose={(value) => {
                setCRMDrawer(value);
                setShowVincereDrawer(false);
              }}
            />
          )}
        </Drawer>
        <Drawer
          showClose={false}
          size="small"
          sx={{
            '& .drawer-header': {
              padding: '8px 16px',
            },
          }}
          open={showJobAdderDrawer}
          title={<ContactProfileTabView contact={contact} />}
          onClose={() => {
            setShowJobAdderDrawer(false);
          }}
          disabledPadding={true}
        >
          {showJobAdderDrawer && (
            <Matches
              initialLoading={props.loading?.jobAdderMatches || matchesLoading}
              matches={props.jobAdderMatches}
              match={props.jobAdderMatch}
              contact={contact}
              CRMField={CRMField}
              closeDrawer={() => setShowJobAdderDrawer(false)}
              syncContact={props.syncJobAdderContact}
              onClose={(value) => {
                setCRMDrawer(value);
                setShowJobAdderDrawer(false);
              }}
            />
          )}
        </Drawer>
        <Drawer
          showClose={false}
          size="small"
          sx={{
            '& .drawer-header': {
              padding: '8px 16px',
            },
          }}
          open={showSalesforceDrawer}
          title={<ContactProfileTabView contact={contact} />}
          onClose={() => setShowSalesforceDrawer(false)}
          disabledPadding={true}
        >
          {showSalesforceDrawer && (
            <Matches
              initialLoading={props.loading?.salesforceMatches || matchesLoading}
              matches={props.salesforceMatches}
              match={props.salesforceMatch}
              contact={contact}
              CRMField={CRMField}
              syncContact={props.syncSalesforceContact}
              closeDrawer={() => setShowSalesforceDrawer(false)}
              onClose={(value) => {
                setCRMDrawer(value);
                setShowSalesforceDrawer(false);
              }}
            />
          )}
        </Drawer>
        <Drawer
          showClose={false}
          size="small"
          sx={{
            '& .drawer-header': {
              padding: '8px 16px',
            },
          }}
          open={showHubspotDrawer}
          title={<ContactProfileTabView contact={contact} />}
          onClose={() => setShowHubspotDrawer(false)}
          disabledPadding={true}
        >
          {showHubspotDrawer && (
            <Matches
              initialLoading={props.loading?.hubspotMatches || matchesLoading}
              matches={props.hubspotMatches}
              match={props.hubspotMatch}
              contact={contact}
              CRMField={CRMField}
              syncContact={props.syncHubspotContact}
              closeDrawer={() => setShowHubspotDrawer(false)}
              onClose={(value) => {
                setCRMDrawer(value);
                setShowHubspotDrawer(false);
              }}
            />
          )}
        </Drawer>
        <Drawer
          open={Boolean(
            contactAction && contactAction !== '' && typeof contactAction !== 'undefined',
          )}
          title={<Typography fontSize={20}>{getTitle}</Typography>}
          onClose={() => {
            toggleContactActionDrawer(undefined);
          }}
        >
          {getDrawerContent()}
        </Drawer>
        <Drawer
          open={CRMDrawer}
          title={`Add ${crmType} to ${CRMField}`}
          sx={{ position: 'relative' }}
          onClose={() => setCRMDrawer(false)}
        >
          {CRMDrawer && (
            <MandatoryField
              CRMDrawer={CRMDrawer}
              CRMField={CRMField}
              contact={contact}
              fieldType={crmType}
              syncJobAdderNewContact={props.syncJobAdderNewContact}
              syncSalesforceNewContact={props.syncSalesforceNewContact}
              syncHubspotNewContact={props.syncHubspotNewContact}
              syncVincereNewContact={props.syncVincereNewContact}
              syncBullhornNewContact={props.syncBullhornNewContact}
              onClose={() => setCRMDrawer(false)}
            />
          )}
        </Drawer>
      </Box>
    </ContactWrapper>
  );
};

const mapStateToProps = (state) => ({
  sequenceEnrollment: state.contacts.sequenceEnrollment,
  contactNote: state.contacts.contactNote,
  sequence: state.sequence,
  taskPriorityList: state.app.globals.taskPriorityList,
  taskTypeList: state.app.globals.taskTypeList,
  user: state.auth.user,
  fields: getContactFields(state),
  loading: state.contacts.loading,
  bullhornMatches: state.contacts.bullhornMatches,
  bullhornMatch: state.contacts.bullhornMatch,
  vincereMatches: state.contacts.vincereMatches,
  vincereMatch: state.contacts.vincereMatch,
  jobAdderMatches: state.contacts.jobAdderMatches,
  jobAdderMatch: state.contacts.jobAdderMatch,
  salesforceMatches: state.contacts.salesforceMatches,
  salesforceMatch: state.contacts.salesforceMatch,
  hubspotMatches: state.contacts.hubspotMatches,
  hubspotMatch: state.contacts.hubspotMatch,
  editContact: state.app.editContact,
  adminSettings: state.admin.admin.tenantSettings.data,
  integrations: state.admin.admin.integrations,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContactNotes: (contactId, filter, paging) =>
    dispatch(fetchContactNotes(contactId, filter, paging)),
  fetchBullhornMatches: (payload, crmType) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchBullhornMatches(payload, crmType, null, null, resolve, reject));
    });
  },
  setBullhornMatches: (matches, match) => dispatch(setBullhornMatches(matches, match)),
  fetchVincereMatches: (payload, crmType) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchVincereMatches(payload, crmType, null, null, resolve, reject));
    });
  },
  fetchSequenceContacts: (paging, filters, seqId) =>
    dispatch(fetchSequenceContacts(paging, filters, seqId)),
  setVincereMatches: (matches, match) => dispatch(setVincereMatches(matches, match)),
  fetchJobAdderMatches: (payload, crmType) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchJobAdderMatches(payload, crmType, null, null, resolve, reject));
    });
  },
  setJobAdderMatches: (matches, match) => dispatch(setJobAdderMatches(matches, match)),
  fetchSalesforceMatches: (payload, crmType) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchSalesforceMatches(payload, crmType, null, null, resolve, reject));
    });
  },
  setSalesforceMatches: (matches, match) => dispatch(setSalesforceMatches(matches, match)),
  fetchHubspotMatches: (payload, crmType) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchHubspotMatches(payload, crmType, null, null, resolve, reject));
    });
  },
  setHubspotMatches: (matches, match) => dispatch(setHubspotMatches(matches, match)),
  toggleAddContactForm: (contact) => dispatch(toggleAddContactForm(contact)),
  putEnrollment: (id, enrollment) => dispatch(putEnrollment(id, enrollment)),
  deleteContact: (contactId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteContact(contactId, resolve, reject));
    });
  },
  syncBullhornContact: (contactId, match) => {
    return new Promise((resolve, reject) => {
      dispatch(syncBullhornContact(contactId, match, resolve, reject));
    });
  },
  syncBullhornNewContact: (contactId, bullhornType) => {
    return new Promise((resolve, reject) => {
      dispatch(syncBullhornNewContact(contactId, bullhornType, resolve, reject));
    });
  },
  syncVincereContact: (contactId, match) => {
    return new Promise((resolve, reject) => {
      dispatch(syncVincereContact(contactId, match, resolve, reject));
    });
  },
  syncVincereNewContact: (contactId, vincereType) => {
    return new Promise((resolve, reject) => {
      dispatch(syncVincereNewContact(contactId, vincereType, resolve, reject));
    });
  },
  syncJobAdderContact: (contactId, match) => {
    return new Promise((resolve, reject) => {
      dispatch(syncJobAdderContact(contactId, match, resolve, reject));
    });
  },
  syncJobAdderNewContact: (contactId, jobAdderType) => {
    return new Promise((resolve, reject) => {
      dispatch(syncJobAdderNewContact(contactId, jobAdderType, resolve, reject));
    });
  },
  syncSalesforceContact: (contactId, match) => {
    return new Promise((resolve, reject) => {
      dispatch(syncSalesforceContact(contactId, match, resolve, reject));
    });
  },
  syncSalesforceNewContact: (contactId, SalesforceType) => {
    return new Promise((resolve, reject) => {
      dispatch(syncSalesforceNewContact(contactId, SalesforceType, resolve, reject));
    });
  },
  syncHubspotContact: (contactId, match) => {
    return new Promise((resolve, reject) => {
      dispatch(syncHubspotContact(contactId, match, resolve, reject));
    });
  },
  syncHubspotNewContact: (contactId, HubspotType) => {
    return new Promise((resolve, reject) => {
      dispatch(syncHubspotNewContact(contactId, HubspotType, resolve, reject));
    });
  },
  addContactToSequence: (sequenceId, contactId) => {
    return new Promise((resolve, reject) => {
      dispatch(addContactToSequence(sequenceId, contactId, resolve, reject));
    });
  },
  sendContactEmail: (contactId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(sendContactEmail(contactId, payload, resolve, reject));
    });
  },
  postTask: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postTask(payload, resolve, reject));
    });
  },
  postContactNote: (note) => {
    return dispatch(postContactNote(note));
  },
  putContact: (id, contacts) => {
    return new Promise((resolve, reject) => {
      dispatch(putContact(id, contacts, resolve, reject));
    });
  },
  fetchSequenceEnrollment: (contactId) => dispatch(fetchSequenceEnrollment(contactId)),
});
//

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
