import React from 'react';
import { Box, Grid, Stack, Tooltip, Typography, Skeleton } from '@mui/material';
import images from 'src/config/images';
import { copyToClipboard } from '../../../../../utils/helper';

const Option = ({
  classes,
  optionText,
  content,
  subject,
  onClick,
  optionIndex,
  selectedOption,
  gridSize,
  refetchContent,
  isEmailForm,
}) => {
  const formattedContent = content ? content.replace(/\n/g, '<br/>') : '';

  return (
    <Grid item xs={12} md={gridSize}>
      <Stack
        sx={{ marginY: 2 }}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography className={classes.optionText}>{optionText}</Typography>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <Tooltip title={'Copy'}>
            <img
              src={images.icons.copyIcon}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                copyToClipboard(
                  isEmailForm ? `Subject: ${subject}\n\nContent: ${content}` : content,
                )
              }
              alt={''}
            />
          </Tooltip>
          <Tooltip title={'Regenerate'}>
            <img
              src={images.icons.refetchIcon}
              style={{ cursor: 'pointer' }}
              alt={''}
              onClick={() => refetchContent(optionIndex)}
            />
          </Tooltip>
        </Stack>
      </Stack>
      <Box
        className={`${classes.promptContent} ${
          optionIndex === selectedOption && classes.activePromptContent
        }`}
        onClick={() => onClick()}
      >
        {isEmailForm && (
          <Box>
            <Typography className={classes.promptContentHeader}>Subject</Typography>
            <Typography className={classes.promptContentDescription}>{subject}</Typography>
          </Box>
        )}
        <Box>
          <Typography className={classes.promptContentHeader}>
            {isEmailForm ? 'Email' : 'Linkedin'} content
          </Typography>
          <Typography
            className={classes.promptContentDescription}
            dangerouslySetInnerHTML={{ __html: formattedContent }}
          />
        </Box>
      </Box>
    </Grid>
  );
};

const LoadingView = ({ classes }) => (
  <Grid item xs={12} md={12}>
    <Stack
      sx={{ marginY: 2 }}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Skeleton variant="text" sx={{ fontSize: '14px' }} width="100px" />
      <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
        <Skeleton variant="text" sx={{ fontSize: '14px' }} width="24px" />
        <Skeleton variant="text" sx={{ fontSize: '14px' }} width="24px" />
      </Stack>
    </Stack>
    <Box className={classes.promptContent}>
      <Box>
        <Skeleton variant="text" sx={{ fontSize: '14px' }} width="50px" />
        <Skeleton variant="text" sx={{ fontSize: '14px' }} width="200px" />
      </Box>
      <Box>
        <Skeleton variant="text" sx={{ fontSize: '14px' }} width="50px" />
        <Skeleton variant="text" sx={{ fontSize: '14px' }} width="200px" />
        <Skeleton variant="text" sx={{ fontSize: '14px' }} />
        <Skeleton variant="text" sx={{ fontSize: '14px' }} />
        <Skeleton variant="text" sx={{ fontSize: '14px' }} />
        <Skeleton variant="text" sx={{ fontSize: '14px' }} />
        <Skeleton variant="text" sx={{ fontSize: '14px' }} />
        <Skeleton variant="text" sx={{ fontSize: '14px' }} />
      </Box>
    </Box>
  </Grid>
);
const AIPromptView = ({
  classes,
  selectedOption,
  setSelectedOption,
  aiResponse,
  refetchContent,
  loading,
  isEmailForm,
}) => {
  return (
    <Box sx={{ minHeight: '365px', height: '100%', width: '100%' }}>
      {loading ? null : (
        <Typography className={classes.promptText}>Select option below:</Typography>
      )}
      <Grid container spacing={2}>
        {loading ? (
          <>
            <LoadingView classes={classes} />
            {/*<LoadingView classes={classes} />*/}
          </>
        ) : aiResponse?.length > 0 ? (
          <>
            <Option
              optionIndex={0}
              onClick={() => setSelectedOption(0)}
              classes={classes}
              optionText={`Option 1`}
              selectedOption={selectedOption}
              content={aiResponse[0]?.body}
              subject={aiResponse[0]?.subject}
              gridSize={aiResponse.length > 1 ? 6 : 12}
              refetchContent={refetchContent}
              isEmailForm={isEmailForm}
            />
            {aiResponse?.length > 1 && (
              <Option
                optionIndex={1}
                onClick={() => setSelectedOption(1)}
                classes={classes}
                optionText={`Option 2`}
                content={aiResponse[1]?.body}
                subject={aiResponse[1]?.subject}
                selectedOption={selectedOption}
                gridSize={6}
                refetchContent={refetchContent}
                isEmailForm={isEmailForm}
              />
            )}
          </>
        ) : null}
      </Grid>
    </Box>
  );
};

export default AIPromptView;
