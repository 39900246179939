import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    borderRadius: '7px',
    '&:disabled': {
      background: theme.palette.background.navyBlue,
      color: theme.palette.text.white,
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
    },
    '&:enabled': {
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.00) 100%), #0C1721',
    },
  },
}));

export const gradientStyle = {
  colorDefault: {
    color: '#666666',
    fontSize: '14px',
    fontWeight: '400',
  },
  colorDark: {
    color: '#1A1A1A !important',
    fontSize: '16px !important',
    fontWeight: '500 !important',
  },
  manualButtonGrayStyle: {
    lineHeight: '20.02px !important',
    letterSpacing: '0.17px !important',
    borderRadius: '999px !important',
    background: '#F4F4F4',
    padding: '10px !important',
    minWidth: '126px !important',
    backgroundClip: 'padding-box !important',
    border: '1px solid transparent !important',
  },
  manualButtonStyle: {
    lineHeight: '20.02px !important',
    letterSpacing: '0.17px !important',
    borderRadius: '999px !important',
    background:
      'linear-gradient(236deg, rgba(255, 212, 128, 0.50) 10.71%, rgba(242, 64, 164, 0.50) 58.14%, rgba(25, 118, 210, 0.50) 93.71%)',
    padding: '10px !important',
    minWidth: '126px !important',
    backgroundClip: 'padding-box !important',
    border: '1px solid transparent !important',
    '&::before': {
      content: '""',
      position: 'absolute !important',
      inset: '0 !important,',
      backgroundColor: '#fff !important',
      borderRadius: 'inherit !important',
      top: '1px !important',
      right: '1px !important',
      bottom: '1px !important',
      left: '1px !important',
      zIndex: '-1 !important',
    },
    zIndex: '1 !important',
  },
  activeButton: {
    borderWidth: '0px !important',
    fontWeight: '500 !important',
    '&::before': {
      display: 'none !important',
    },
  },
};
