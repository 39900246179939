import { Box, Typography } from '@mui/material';
import { ModalBgSetup } from './styles';
import { Modal } from '../../shared';

const UpgradeSubscriptionModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '560px',
        },
        '& .MuiDialog-paper': {
          borderRadius: '10px',
        },
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
        '& .MuiDialogActions-root ': {
          padding: '0px',
        },
        '& .MuiDialogTitle-root': {
          height: '56px',
          boxSizing: 'border-box',
          '& > div': {
            alignItems: 'baseline',
          },
        },
      }}
    >
      <Box padding={'16px 24px 0px 24px'} display={'flex'}>
        <Box
          sx={{
            color: 'rgba(0, 0, 0, 0.87))',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.15px',
            fontFamily: 'Inter',
          }}
        >
          <Typography fontWeight={500}>To upgrade to premium,</Typography>
          <Typography fontWeight={500}>please contact the administrator.</Typography>
        </Box>
        <ModalBgSetup />
      </Box>
    </Modal>
  );
};
export default UpgradeSubscriptionModal;
