import React from 'react';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ custom }) => ({
  primaryButton: {
    height: 35,
    minWidth: 80,
    padding: '0 20px',
    fontSize: 13,
    root: {
      backgroundColor: 'green',
    },
    '&:hover': {
    },
    '&:focus': {
      outline: `none`,
    },
  },
  cancelButton: {
    '& MuiButton-label': {
      textTransform: 'unset',
    },
    '&:hover': {
    },
    '&:focus': {
      outline: 'none',
    },
  },
  closeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    cursor: 'pointer',
  },
  dialogContainer: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '400px',
    },
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function Dialog(props) {
  const {
    objectId,
    open,
    titleText = '',
    TitleComponent,
    titleComponentProps,
    content,
    handleClick,
    onClose,
    buttonText,
    showCancel = true,
    showCloseIcon = false,
    draggable = true,
    htmlContent,
    showAction = true,
    ContentComponent,
    contentProps,
    FooterComponent,
    footerComponentProps,
    dialogContentSx,
    ...otherProps
  } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose(false);
  };

  const onClick = () => {
    handleClick(objectId);
  };

  return (
    <div>
      <MuiDialog
        open={open}
        onClose={handleClose}
        PaperComponent={draggable ? PaperComponent : undefined}
        aria-labelledby="draggable-dialog-title"
        className={classes.dialogContainer}
        {...otherProps}
      >
        {TitleComponent && <TitleComponent {...titleComponentProps} />}
        {(titleText || showCloseIcon) && (
          <DialogTitle
            style={draggable ? { cursor: 'move' } : {}}
            id="draggable-dialog-title"
            className={showCloseIcon ? classes.closeWrapper : ''}
          >
            <Typography variant="h6">{titleText}</Typography>
            {showCloseIcon && <CloseIcon className={classes.closeButton} onClick={handleClose} />}
          </DialogTitle>
        )}
        <DialogContent sx={{ ...dialogContentSx }}>
          {content ? (
            <DialogContentText>{content}</DialogContentText>
          ) : ContentComponent ? (
            <ContentComponent {...contentProps} />
          ) : (
            htmlContent
          )}
        </DialogContent>
        {FooterComponent && <FooterComponent {...footerComponentProps} />}
        {showAction && (
          <DialogActions>
            <Button onClick={handleClose} color="primary" className={`${classes.cancelButton}`}>
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={onClick}
              color="primary"
              className={`${classes.primaryButton}`}
            >
              {buttonText}
            </Button>
          </DialogActions>
        )}
      </MuiDialog>
    </div>
  );
}
