import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import VoicesView from './VoicesView';
import {
  fetchVoices,
  saveVoice,
  fetchVoiceById,
  updateVoiceStatus,
  updateVoice,
} from '../../actions/authActions';

export const VoicesContainer = (props) => {
  useEffect(() => {
    if (!props.voices?.length) {
      props.fetchVoices(props.voices.paging, props.voices.filters, props.voices.sort);
    }
  }, []);
  return <VoicesView {...props} />;
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    voicesTabs: state.auth.voices?.voiceTabs,
    voices: state.auth?.voices,
    total: state.auth.voices?.paging.count || 10,
    globals: state.app.globals,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchVoices: (paging, filters, sort) => dispatch(fetchVoices(paging, filters, sort)),
  getVoiceById: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchVoiceById(id, resolve, reject));
    });
  },
  updateVoice: (rowData) => {
    return new Promise((resolve, reject) => {
      dispatch(updateVoice(rowData, resolve, reject));
    });
  },
  saveVoice: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(saveVoice(data, resolve, reject));
    });
  },
  updateVoiceStatus: (rowData) => dispatch(updateVoiceStatus(rowData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoicesContainer);
