import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: '24px',
  },
  modalTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  modalSubTitle: {
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '22px',
  },
  closeButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    alignSelf: 'self-start',
    cursor: 'pointer',
  },
  formContent: {
    padding: '24px 72px',
  },
  fieldLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.15px',
    minWidth: '332px',
    maxWidth: '332px',
  },
  formField: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
  },
  actionButton: {
    width: '142px',
    height: '44px',
    borderRadius: '999px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  grayButton: {
    backgroundColor: '#F9F9F9',
  },
  dropdown: {
    height: '44px',
  },
  additionalInstructionsLabel: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0.1px',
    cursor: 'pointer',
  },
  additionalInstructionsText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.60)',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0.1px',
  },
  generateButton: {
    color: theme.palette.text.white,
    width: '122px',
    height: '40px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.46px',
    display: 'flex',
    gap: '8px',
    background: 'linear-gradient(236deg, #FFD480 0.92%, #F240A4 37.89%, #1976D2 98.63%)',
    boxShadow: '0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.15), 0px 1px 0.75px 0px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
  },
  cancelButton: {
    color: 'rgba(0, 0, 0, 0.60)',
    height: '40px',
    width: '81px',
  },
  selector: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.text.white1,
      borderRadius: '6px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  purpose: {
    '& .MuiSelect-nativeInput': {
      backgroundColor: theme.palette.text.white1,
      borderRadius: '6px',
    },
    '& .MuiSelect-select': {
      backgroundColor: theme.palette.text.white1,
      borderRadius: '6px',
      paddingTop: '11px',
      paddingBottom: '11px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0000001F',
    },
  },
}));
