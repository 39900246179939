import React from 'react';
import { Stack, Box, Avatar } from '@mui/material';
import { IntroTitle, IntroDescription, InfoText } from './OutreachAccordionStyle';
import images from 'src/config/images';

function Intro({ user, ...props }) {
  return (
    <Stack gap="40px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <IntroTitle variant="h1">{`Hey ${user?.fname},`}</IntroTitle>
        <img src={images.app.logo} alt="" border="0" className={`img-fluid`} height="34px" />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <IntroDescription>
          Welcome to your outreach video library designed to help you unlock your potential! 🚀<br/><br/>
          Access the videos through the links below, and get ready to scale your outreach in just a
          few minutes.
          <InfoText> Let's do this! 😀</InfoText>
        </IntroDescription>
        <Avatar alt={user?.fname} src={user?.userImage} sx={{ width: 87, height: 87 }} />
      </Box>
    </Stack>
  );
}

export default Intro;
