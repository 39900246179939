import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Button, Drawer } from 'src/components/shared';
import ContactForm from 'src/modules/contacts/components/Form/ContactForm';
import { putUser } from 'src/modules/auth/actions/authActions';

import { useStyles } from './OutreachAccordionStyle';
import { processContent } from '../../../utils/helper';
import StepMedia from '../StepMedia';

function StepContent({
  step,
  fields,
  markStepComplete,
  toggleSequenceForm,
  toggleTaskForm,
  toggleEnrollForm,
  toggleIntegrationDrawer,
  toggleAddContactForm,
  showContactForm,
  defaultSequence,
  isCompleted,
  onMarkComplete,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  // const [userData, setUserData] = useState({});
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });
  const history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);

  const updateUser = (data) => {
    dispatch(putUser(data));
  };

  useEffect(() => {
    if (step.action == 'add_signature') {
      getUserData();
    }
  }, [step]);

  const getUserData = () => {
    // const currentUserData = JSON.parse(localStorage.getItem('user'));
    // const userId = currentUserData.id;
    // if (step.isUserUpdate) {
    //   api
    //     .get(`/automation/users/${userId}`)
    //     .then((res) => {
    //       setUserData(res.data);
    //     })
    //     .catch((error) => {
    //       setUserData({});
    //     });
    // }
  };

  const onAction = async () => {
    if (step?.action && step?.action !== '') {
      switch (step?.action) {
        case 'create_sequence':
          await markStepComplete(step);
          toggleSequenceForm();
          break;

        case 'manage_tasks':
          await markStepComplete(step);
          toggleTaskForm(false, step);
          break;

        case 'add_contacts':
          await markStepComplete(step);
          toggleEnrollForm(false, step);
          break;

        case 'add_signature':
          await markStepComplete(step);
          history.push('/profile/outreachSettings');
          break;

        case 'connect_mailbox':
          await markStepComplete(step);
          history.push('profile/outreachSettings');
          break;

        case 'download_chrome_extension':
          await markStepComplete(step);
          if (step.action_source && step.action_source != null) {
            window.open(step.action_source);
          }
          break;
        case 'improve_results':
          await markStepComplete(step);
          history.push('/boards');
          break;
        case 'update_profile':
          await markStepComplete(step);
          history.push('/profile/details');
          break;
        case 'using_templates':
          await markStepComplete(step);
          history.push('/templates/email');
          break;
        case 'manage_user':
          await markStepComplete(step);
          history.push('/admin/users');
          break;
        case 'manage_fields':
          await markStepComplete(step);
          history.push('/admin/fields');
          break;
        case 'manage_integrations':
          await markStepComplete(step);
          history.push('/admin/integrations');
          break;
        case 'manage_billing':
          await markStepComplete(step);
          history.push('/admin/billing/details');
          break;
        case 'manage_data_waterfall':
          await markStepComplete(step);
          history.push('/admin/data/waterfall');
          break;

        default:
          setIsLoading(true);
          await markStepComplete(step);
          setIsLoading(false);
          break;
      }
    } else {
      setIsLoading(true);
      await markStepComplete(step);
      setIsLoading(false);
    }
  };

  const noButtonActions = ['add_signature'];
  const hasThumb = step.thumbnail && step.thumbnail != '';

  return (
    <Box width="100%">
      <Drawer
        title={'Create contact'}
        open={props.showContactForm === true}
        onClose={() => {
          toggleAddContactForm();
        }}
      >
        {props.showContactForm === true && (
          <ContactForm
            edit={drawerOpen.edit}
            drawerToggle={showContactForm}
            formData={fields}
            {...props}
          />
        )}
      </Drawer>
      {step.content && (
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={12} md={hasThumb ? 7 : 12} style={{ padding: 0 }}>
            <Box mb={2}>
              <Typography variant={'body1'}
                dangerouslySetInnerHTML={{
                  __html: processContent(step.content, step.action_source, true),
                }}
              />
            </Box>
          </Grid>
          {/* Currently video open in newTab*/}
          {hasThumb && (
            <Grid item xs={12} md={5}>
              <StepMedia
                step={step}
                onAction={onAction}
                playVideo={playVideo}
                setPlayVideo={setPlayVideo}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Box class={classes.stepperButton}>
        {step.action ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (step.action == 'Video' || step?.isVideo) {
                setPlayVideo(true);
                // window.open(step.action_source, '_blank');
              } else {
                onAction();
              }
            }}
            loading={isLoading}
            // disabled={step.action == 'add_contacts' && !defaultSequence.id}
          >
            <Typography className={classes.stepperActionButton} variant={'h2'}>{step.action_label}</Typography>
          </Button>
        ) : (
          step.key !== 'mailbox' && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                onAction();
              }}
              loading={isLoading}
            >
              Mark as completed
            </Button>
          )
        )}
        {isCompleted ? (
          <Typography
            variant="h2"
            className={`${classes.outerAccordionInfoComplete} ${classes.outerAccordionInfoText}`}
          >
            <CheckCircleIcon /> Completed
          </Typography>
        ) : (
          step.key !== 'mailbox' && (
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                onMarkComplete(step);
              }}
              loading={isLoading}
            >
              <Typography className={classes.stepperActionButton} variant={'h2'}>Mark as completed</Typography>
            </Button>
          )
        )}
      </Box>
      {/* {step.action == 'add_contacts' && !defaultSequence.id && (
        <Box mt={2}>
          <Alert severity="warning">
            You need to create a Sequence before you can enroll contacts !
          </Alert>
        </Box>
      )} */}
    </Box>
  );
}

export default StepContent;
