import React from 'react';
import MuiDialog from '@mui/material/Dialog';

export default function Dialog(props) {
  const { open, onClose, ...otherProps } = props;

  const handleClose = () => {
    if (onClose) {
      onClose(false);
    }
  };

  return (
    <div>
      <MuiDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        {...otherProps}
      >
        {props.children}
      </MuiDialog>
    </div>
  );
}
