import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog } from '@mui/material';
import {
  FlexColumnBox,
  MainBox,
  Title,
  Content,
  ModalHeader,
  Heading,
  SecurityVerificationTitle,
} from './styles';
import { Button } from '../../../../../components/shared';
import Input from '../../../../../components/shared/Form/Input';

const SecurityVerification = ({
  open,
  onSMSClick,
  linkedInOtp,
  setLinkedInOtp,
  loading,
  setLinkedinOtpError,
  linkdinOtpError,
  setShowLinkedinSMS,
}) => {
  return (
    <>
      <Dialog open={open}>
        <ModalHeader>
          <Heading>Security verification</Heading>
          <CloseIcon
            sx={{ color: '#00000042', cursor: 'pointer' }}
            onClick={() => setShowLinkedinSMS(false)}
          />
        </ModalHeader>
        <MainBox alignItems={'flex-start'}>
          <FlexColumnBox gap={'8px'} alignItems={'flex-start'}>
            <SecurityVerificationTitle>
              Enter the code that was sent to your mobile phone
            </SecurityVerificationTitle>
            <Content>
              To finish registering, please enter the verification code we gave you. It might take a
              few minutes to receive your code.
            </Content>
          </FlexColumnBox>
          <Input
            fullWidth
            value={linkedInOtp}
            variant="outlined"
            onChange={(val) => {
              setLinkedinOtpError('');
              setLinkedInOtp(val);
            }}
            error={!!linkdinOtpError}
            helperText={linkdinOtpError}
          />
          <FlexColumnBox gap={'8px'}>
            <Button
              fullWidth
              variant={'contained'}
              color={'secondary'}
              sx={{ fontWeight: 500 }}
              onClick={() => {
                if (!linkedInOtp) {
                  setLinkedinOtpError('Please enter OTP');
                  return;
                }
                onSMSClick();
              }}
              loading={loading}
            >
              Submit
            </Button>
          </FlexColumnBox>
        </MainBox>
      </Dialog>
    </>
  );
};
export default SecurityVerification;
