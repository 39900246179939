import React, { useMemo, useState } from 'react';
import { Box, CircularProgress, Grid, Stack, useTheme } from '@mui/material';
import {
  AIVoiceActionContainer,
  AIVoiceContentHeader,
  AIVoiceCreateViewHeader,
  AIVoiceCreateViewSubHeader,
  AttributeSubHeader,
  DetailedContent,
  useStyles,
} from '../../components/Voice/styles';
import { Button, Form } from '../../../../components/shared';
import images from 'src/config/images';
import { styleItems, toneItems } from './utils';
import { capitalizeFirstLetter, handleSocketListener } from '../../../../utils/helper';
import { useSocketStore } from '../../../../components/hooks/socketConnector';
import { Title } from '../../../../components/App/Notifications/styles';

const ManualCreateVoice = ({ voiceData, setShowVoiceNameField, formProps, sampleTextList }) => {
  const [showLoading, setShowLoading] = useState(false);

  const theme = useTheme();
  const classes = useStyles();
  const { emit, socket } = useSocketStore();

  const { errors, values } = formProps;

  const handleStyleClick = (item) => {
    formProps.setFieldValue('style', item);
  };

  const handleToneChange = (item) => {
    const selectedTone = values?.tone || [];
    let updatedTones = [];
    if (selectedTone.includes(item)) {
      updatedTones = selectedTone.filter((tone) => tone !== item);
    } else {
      updatedTones = [...selectedTone, item];
    }
    formProps.setFieldValue('tone', updatedTones);
  };

  const handleSave = () => {
    if (formProps?.values?.style) {
      formProps.setErrors({});
      setShowVoiceNameField(true);
    } else {
      formProps.handleSubmit();
    }
  };

  const onChangeSampleVoice = (value) => {
    setShowLoading(true);
    formProps.setFieldValue('sampleVoice', {
      key: value,
      label: value,
      voice: {},
    });
    emit(
      'pipeline:execute',
      JSON.stringify({
        slug: 'getemail',
        payload: {
          text: JSON.stringify({
            style: formProps?.values?.style,
            tone: formProps?.values?.tone,
            type: value,
          }),
        },
      }),
    );

    const responseHandler = (res) => {
      formProps.setFieldValue('sampleVoice', {
        key: value,
        label: value,
        voice: res?.json,
      });
      setShowLoading(false);
    };
    const errorHandler = () => {
      setShowLoading(false);
    };

    handleSocketListener({
      socket,
      responseHandler,
      errorHandler,
    });
  };

  return (
    <>
      <Stack sx={{ background: theme.palette.background.paper }}>
        <Stack
          direction={'row'}
          gap={'16px'}
          sx={{ padding: '24px', borderBottom: `1px solid ${theme.palette.border.default}` }}
        >
          <img src={images.voice.toneOfVoiceIcon} alt={''} height={32} width={32} />
          <Stack gap={'8px'}>
            <AIVoiceCreateViewHeader>
              {voiceData?.id ? 'Edit' : 'Create'} a Tone of Voice - Manually
            </AIVoiceCreateViewHeader>
            <AIVoiceCreateViewSubHeader isGrey>
              Set up a tone of voice profile to make your generated content sound just like you!
            </AIVoiceCreateViewSubHeader>
          </Stack>
        </Stack>
        <Stack gap={'24px'} sx={{ padding: '24px 72px' }}>
          <Grid container columnSpacing={'24px'}>
            <Grid item xs={12} md={7}>
              <Stack gap={'24px'}>
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Select Your Attributes</AIVoiceContentHeader>
                  <AttributeSubHeader>
                    Select the style and tone to create your tone of voice
                  </AttributeSubHeader>
                </Stack>
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Style</AIVoiceContentHeader>
                  <Stack direction={'row'} alignItems={'center'} gap={'12px'}>
                    {styleItems.map((item) => (
                      <Button
                        variant={values?.style === item ? 'contained' : 'outlined'}
                        color={'gradient'}
                        onClick={() => handleStyleClick(item)}
                        className={classes.gradientButtonManualCreate}
                      >
                        {capitalizeFirstLetter(item)}
                      </Button>
                    ))}
                  </Stack>
                </Stack>
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Tone</AIVoiceContentHeader>
                  <Box className={classes.toneList}>
                    {toneItems.map((item) => (
                      <Button
                        variant={values?.tone?.includes(item) ? 'contained' : 'outlined'}
                        color={'gradient'}
                        onClick={() => handleToneChange(item)}
                        className={classes.gradientButtonManualCreate}
                      >
                        {capitalizeFirstLetter(item)}
                      </Button>
                    ))}
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack gap={'24px'} height="100%">
                <Stack gap={'8px'}>
                  <AIVoiceCreateViewHeader>Your Tone of Voice in Action</AIVoiceCreateViewHeader>
                  <AIVoiceCreateViewSubHeader isGrey>
                    Apply your tone of voice to some sample text
                  </AIVoiceCreateViewSubHeader>
                </Stack>
                <Stack>
                  <Form.Field.Select
                    options={[...sampleTextList]}
                    value={values?.sampleVoice?.key}
                    placeholder={'Select a sample text'}
                    fullWidth
                    withElpsis
                    className={classes.inputSelectSampleText}
                    sx={{
                      mt: 0,
                      mb: 0,
                      '.MuiOutlinedInput-notchedOutline': !errors.sampleTextType
                        ? { border: 0 }
                        : { borderRadius: '8px' },
                    }}
                    onChange={onChangeSampleVoice}
                    size="small"
                    variant="outlined"
                    optLabel="label"
                    isSmall
                    optValue="value"
                    showNone={false}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  />
                </Stack>
                <AIVoiceActionContainer>
                  {showLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                      <CircularProgress size={30} color="secondary" />
                    </Box>
                  ) : (
                    <>
                      <DetailedContent isGrey>
                        {values?.sampleVoice?.voice?.Subject
                          ? values?.sampleVoice?.voice?.Subject
                          : ''}
                      </DetailedContent>
                      <DetailedContent isGrey>
                        {values?.sampleVoice?.voice?.Body ? (
                          <Title
                            dangerouslySetInnerHTML={{
                              __html: values?.sampleVoice?.voice?.Body?.replace(/\n/g, '<br />'),
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </DetailedContent>
                    </>
                  )}
                </AIVoiceActionContainer>
                {/*<Box display="flex" alignItems="end" height="100%">*/}
                {/*  <Button*/}
                {/*    variant={'contained'}*/}
                {/*    color={'secondary'}*/}
                {/*    onClick={() => null}*/}
                {/*    className={classes.applyBtn}*/}
                {/*  >*/}
                {/*    Apply voice to this text*/}
                {/*  </Button>*/}
                {/*</Box>*/}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Box padding="24px" display={'flex'} gap="16px" justifyContent={'end'}>
          <Button
            variant={'contained'}
            color="secondary"
            onClick={handleSave}
            className={classes.secondaryButton}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </>
  );
};
export default ManualCreateVoice;
