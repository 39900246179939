import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/styles';

const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-inputRoot': {
    backgroundColor: '#F9F9F9',
  },
  '& .Mui-focused': {
    backgroundColor: '#F9F9F9',
  },
});

const LocationAutocomplete = ({ setFieldValue, name, classes, item, errors, defaultValue }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const autocompleteService = useRef(null);

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setFieldValue(name, {
      label: value,
    });
    if (value && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions({ input: value }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setOptions(predictions);
        }
      });
    } else {
      setOptions([]);
    }
  };
  const fetchPlaceDetails = (placeId) => {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div'),
    );
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const { lat, lng } = place.geometry.location;
        const location = {
          label: place.formatted_address,
          place_id: place.place_id,
          lat: lat(),
          lng: lng(),
        };
        setFieldValue(name, location);
      }
    });
  };

  return (
    <CustomAutocomplete
      freeSolo
      forcePopupIcon
      fullWidth
      placeholder={item?.placeholder || `Select ${item?.label.toLowerCase()}`}
      options={options.map((option) => ({
        label: option.description,
        place_id: option.place_id,
      }))}
      onInputChange={(event, newInputValue) => {
        handleInputChange({ target: { value: newInputValue } });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={item?.placeholder || `Select ${item?.label.toLowerCase()}`}
          error={!!errors[item.fieldName]}
          InputProps={{
            ...params.InputProps,
            endAdornment: inputValue && options.length ? params.InputProps.endAdornment : null,
          }}
        />
      )}
      onChange={(event, value) => {
        if (value) {
          fetchPlaceDetails(value.place_id);
        }
      }}
      className={classes}
      name={name}
      size="small"
      variant="outlined"
      defaultValue={defaultValue}
    />
  );
};

export default LocationAutocomplete;
