import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, ListItemIcon, IconButton, Typography, Stack } from '@mui/material';
import Button from '../Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { uniqueId } from 'lodash';
import { makeStyles } from '@mui/styles';
import { ConfirmDialog } from 'src/components/App';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';

export const useStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: 36,
  },
}));

const AppMenu = ({
  options,
  loading,
  className = '',
  iconButton,
  iconButtonColor,
  iconSearch,
  title,
  showCustomButton,
  startIcon,
  buttonStyles,
  color,
  endIcon = false,
  disabled = false,
  trigger,
  sx = {},
  removeParentAnchorlEl = () => {},
  anchorElProps,
  menuItemClass = '',
  menuLabelClass = '',
  menuItemSx,
  ...props
}) => {
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [savedVal, setSavedVal] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const id = uniqueId('context-menu-');
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (open || anchorEl) {
      if (removeParentAnchorlEl) {
        removeParentAnchorlEl?.(true);
      }
    }
  }, [open, anchorEl, anchorElProps]);

  const handleClick = (event) => {
    if (removeParentAnchorlEl) {
      removeParentAnchorlEl?.(true);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (val) => {
    if (removeParentAnchorlEl) {
      removeParentAnchorlEl?.(true);
    }
    if (val.confirm && val.confirm == true) {
      setSavedVal(val);
      setConfirmOpen(true);
      handleClose();
    } else {
      val?.onClick();
      handleClose();
    }
  };

  const onConfirm = () => {
    if (savedVal) {
      savedVal?.onClick();
      setSavedVal(null);
    }
    setConfirmOpen(false);
  };

  return (
    <div className={className}>
      {showCustomButton ? (
        <Button
          variant="contained"
          disabled={disabled}
          disableElevation={true}
          onClick={handleClick}
          endIcon={endIcon ? endIcon : null}
          sx={{ ...buttonStyles }}
          size="small"
        >
          <Stack direction={'row'} alignItems={'center'}>
            {startIcon && startIcon}
            <Typography variant="body2" sx={{ padding: '8px 8px 8px 0px' }}>
              {title}
            </Typography>
          </Stack>
        </Button>
      ) : iconButton ? (
        <IconButton
          sx={{ ...buttonStyles }}
          disabled={disabled}
          aria-label="more"
          aria-controls={id}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon sx={{ color: iconButtonColor || 'rbg(0, 0, 0, 0.54)' }} />
        </IconButton>
      ) : iconSearch ? (
        <Button
          color={color}
          size="medium"
          disabled={disabled}
          variant="outlined"
          onMouseEnter={(e) => handleClick(e)}
          sx={{ ...buttonStyles }}
          startIcon={<SearchIcon />}
        >
          {title}
        </Button>
      ) : (
        <Button
          variant="text"
          disabled={disabled}
          sx={{ ...buttonStyles }}
          endIcon={endIcon && <ArrowDropDownIcon />}
          color={color}
          {...props}
          {...(trigger === 'hover' && {
            onMouseEnter: (e) => handleClick(e),
          })}
          {...(trigger === 'click' && {
            onClick: (e) => handleClick(e),
          })}
        >
          {title}
        </Button>
      )}

      {!disabled && (
        <Menu
          id={id}
          sx={sx}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => {
            handleClose();
            if (removeParentAnchorlEl) {
              removeParentAnchorlEl?.(false);
            }
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={`${id}-option-${index}`}
              onClick={() => onSelect(option)}
              className={menuItemClass}
              sx={{ ...menuItemSx }}
            >
              {option?.icon && (
                <ListItemIcon className={classes.listItemIcon}>{option.icon}</ListItemIcon>
              )}
              <Typography variant="inherit" sx={{ ...option?.labelSx }}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
      <ConfirmDialog
        title={savedVal && savedVal.popUpTitle ? savedVal.popUpTitle : 'Are you sure?'}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={onConfirm}
        onClose={() => {
          setConfirmOpen(false);
          if (removeParentAnchorlEl) {
            removeParentAnchorlEl?.(false);
          }
        }}
        loading={loading}
      >
        {savedVal && savedVal.confirmMessage && savedVal.confirmMessage
          ? savedVal.confirmMessage
          : ''}
      </ConfirmDialog>
    </div>
  );
};

AppMenu.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.any,
  iconButton: PropTypes.bool,
  buttonStyles: PropTypes.any,
  color: PropTypes.any,
  trigger: PropTypes.string,
};

AppMenu.defaultProps = {
  options: [],
  className: '',
  loading: false,
  title: undefined,
  iconButton: true,
  buttonStyles: {},
  color: 'inherit',
  trigger: 'click',
};

export default AppMenu;
