import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormHelperText, Box, useTheme } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { trim } from 'lodash';
import { fileUpload } from 'src/modules/app/api/appApis';
import { aIWriterIcon, uploadButtonIcon } from './EditorIcon';

const apiKey = 'w4kzxwiw7n9w5n5rxt1gv2w3oq30nfwwhyi6r01zmm7rprri';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  characterLimit: PropTypes.number,
  showFontStyle: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  showFontEditor: PropTypes.bool,
  showAlignProps: PropTypes.bool,
  showListProps: PropTypes.bool,
  toolbarLocation: PropTypes.string,
  textColor: PropTypes.string,
  competitiveLearning: PropTypes.bool,
  showAttachments: PropTypes.bool,
  getChildEditorRef: PropTypes.func,
  ref: PropTypes.any,
  isSetup: PropTypes.bool,
  removeSpans: PropTypes.bool,
  pasteAsText: PropTypes.bool,
  height: PropTypes.number,
};

const defaultProps = {
  className: 'custom_input',
  competitiveLearning: false,
  value: undefined,
  textColor: '',
  onChange: () => {},
  loading: false,
  disabled: false,
  characterLimit: 0,
  showFontStyle: true,
  showPlaceholder: true,
  showFontEditor: true,
  showAlignProps: true,
  showListProps: true,
  showAttachments: true,
  toolbarLocation: null,
  getChildEditorRef: () => {},
  ref: null,
  isSetup: true,
  removeSpans: true,
  pasteAsText: false,
  height: 300,
};

const TextEditor = ({
  className,
  onChange,
  value,
  handleBlur = () => {},
  error,
  helperText,
  characterLimit,
  getChildEditorRef,
  ref,
  isSetup,
  textColor,
  removeSpans,
  pasteAsText,
  height,
  competitiveLearning,
  disabled,
  isSignature = false,
  ...props
}) => {
  let replacementVariables = useSelector((state) => state.app.globals.replacementVariables);
  let competitiveLearningVariables = useSelector(
    (state) => state.app.globals.competitionSettings?.replacementVariables,
  );
  const editorRef = React.useRef();

  const theme = useTheme();
  const isDark = theme.palette.type == 'dark';

  const contactVariables = useMemo(() => {
    if (replacementVariables && replacementVariables.length) {
      return replacementVariables.filter((item) => item.text.includes('Contact'));
    }
    return [];
  }, [replacementVariables]);
  const senderVariables = useMemo(() => {
    if (replacementVariables && replacementVariables.length) {
      return replacementVariables.filter((item) => item.text.includes('Sender'));
    }
    return [];
  }, [replacementVariables]);

  const otherVariables = useMemo(() => {
    if (replacementVariables && replacementVariables.length) {
      return replacementVariables.filter(
        (item) => !item.text.includes('Sender') && !item.text.includes('Contact'),
      );
    }
    return [];
  }, [replacementVariables]);

  const [characters, setCharacters] = useState(0);
  const [characterNumber, setCharactersNumber] = useState(0);

  const { unsubscribe = false } = props;

  function applyDefaultFontIfMissing(content) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const pTag = doc.querySelector('p');
    if (pTag) {
      const currentStyle = pTag.getAttribute('style') || '';
      if (!currentStyle.includes('font-family')) {
        const updatedStyle = `${currentStyle}; font-family: arial;`.trim();
        pTag.setAttribute('style', updatedStyle);
      }
    }
    return doc.body.innerHTML;
  }

  const handleEditorChange = (value) => {
    if (removeSpans === true) {
      value = value.replace(/<span(?:\s[^>]*?)?>((?:.|\n)*?)<\/span>/g, (match, innerContent) => {
        return /style=|class=/.test(match) ? match : innerContent;
      });
    }
    onChange(applyDefaultFontIfMissing(value));
  };

  useEffect(() => {
    const handler = (e) => {
      const { target } = e;
      if (target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
        // e.stopPropagation();
        e.stopImmediatePropagation();
        if (target.closest('.tox-textfield') && target.closest('.tox-textfield').length) {
          target.closest('.tox-textfield').focus();
        } else if (target && target.classList.contains('tox-textfield') && target.type === 'url') {
          target.focus();
        }
      }
    };
    document.addEventListener('focusin', handler);
    return () => document.removeEventListener('focusin', handler);
  }, []);

  React.useEffect(() => {
    if (getChildEditorRef) {
      getChildEditorRef(editorRef);
    }
  }, [editorRef]);

  useEffect(() => {
    if (value && value !== '' && characterLimit > 0) {
      setCharacters(value.length);

      if (value.length > characterLimit) {
        const newValue = value.substring(0, characterLimit);
        handleEditorChange(newValue);
        // onChange(newValue);
      }
    }
  }, [characterLimit, value]);

  const tools = useMemo(() => {
    const toolStr = [];
    if (props.showFontStyle === true) {
      if (
        props?.showAiWriter === true &&
        props.formType !== 'email' &&
        props.formType !== 'templateEmail'
      ) {
        toolStr.push('aiEmailWrite');
      }
      if (
        props.showPlaceholder === true &&
        props.formType !== 'email' &&
        props.formType !== 'templateEmail'
      ) {
        toolStr.push('placeholder');
      }
      toolStr.push('bold italic');
      if (props?.showUploadIcon === true) {
        toolStr.push('uploadbutton');
      }
      if (props.showPlaceholder === true && props.formType === 'email') {
        toolStr.push('placeholder');
      }
      if (props?.showAiWriter === true && props.formType === 'email') {
        toolStr.push('aiEmailWrite');
      }
      toolStr.push('underline');
      if (props.formType === 'templateEmail') {
        if (props.showFontEditor === true && !isSignature) {
          toolStr.push('forecolor');
        }
        if (props.showPlaceholder === true) {
          toolStr.push('placeholder');
        }
        if (props?.showAiWriter === true) {
          toolStr.push('aiEmailWrite');
        }
        if (props.showFontEditor === true) {
          toolStr.push('fontsize');
          toolStr.push('fontfamily');
        }
      }
    } else {
      if (props?.showUploadIcon === true) toolStr.push('aiEmailWrite');
      if (props?.showAiWriter === true) toolStr.push('aiEmailWrite');
      if (props.showPlaceholder === true) toolStr.push('placeholder');
    }
    if (props.formType !== 'templateEmail') {
      if (props.showFontEditor === true && !isSignature) toolStr.push('forecolor');
      if (props.showFontEditor === true) toolStr.push('fontsize');
      if (props.showFontEditor === true) toolStr.push('fontfamily');
    }
    if (props.showAlignProps === true)
      toolStr.push('alignleft aligncenter alignright alignjustify');
    if (props.showListProps === true) toolStr.push('bullist numlist outdent indent');
    if (props.showAttachments === true) toolStr.push('link image code');
    return toolStr.join(' | ');
  }, [
    props.showFontStyle,
    props.showPlaceholder,
    props.showFontEditor,
    props.showAlignProps,
    props.showListProps,
    props.showAttachments,
  ]);

  var characterLimitHtml = characterLimit > 0 ? characterLimit + 100 : 0;
  const allowed_keys = [8, 13, 16, 17, 18, 20, 33, 34, 35, 36, 37, 38, 39, 40, 46];

  if (props.showPlaceholder === true && (!replacementVariables || !replacementVariables.length))
    return null;

  const setup = function (editor) {
    let toggleState = false;

    editor.ui.registry.addNestedMenuItem('nesteditem', {
      text: 'My nested menu item',
      getSubmenuItems: () => replacementVariables,
    });

    editor.ui.registry.addIcon(
      'overflow-button',
      '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.75 31.75"><defs><style>.cls-1{fill:#4c5866;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Underline"><path class="cls-1" d="M13.88,25.69A10.89,10.89,0,0,1,3,14.82V1.88a1.88,1.88,0,1,1,3.75,0V14.82a7,7,0,0,0,7.13,7.12A7,7,0,0,0,21,14.82V1.88a1.88,1.88,0,1,1,3.75,0V14.82A10.89,10.89,0,0,1,13.88,25.69Z"/><path class="cls-1" d="M25.88,31.75h-24a1.88,1.88,0,1,1,0-3.75h24a1.88,1.88,0,0,1,0,3.75Z"/></g></g></g></svg>',
    );

    editor.on('keydown', function (event) {
      // Detect the "Enter" key press
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent default behavior

        // Insert a single <br> tag where the cursor is
        editor.execCommand('InsertLineBreak');
      }
    });

    editor.ui.registry.addMenuButton('placeholder', {
      text: 'Variables',
      class: 'PersonalizeButtonEditor',
      //icon: 'info',
      // tooltip: 'It is a personalize content variable',
      onAction: function () {
        //editor.insertContent('<p>You clicked the main button</p>');
      },
      onItemAction: function (api, value) {
        editor.insertContent(value);
      },
      fetch: function (callback) {
        const items = [
          competitiveLearning === true
            ? {
                type: 'nestedmenuitem',
                text: 'Competitive Learning',
                getSubmenuItems: () =>
                  competitiveLearningVariables.map((item) => ({
                    ...item,
                    type: 'menuitem',
                    onAction: () => editor.insertContent(item.value),
                  })),
              }
            : '',
          {
            type: 'nestedmenuitem',
            text: 'Contact',
            getSubmenuItems: () =>
              contactVariables.map((item) => ({
                ...item,
                type: 'menuitem',
                onAction: () => editor.insertContent(item.value),
              })),
          },
          {
            type: 'nestedmenuitem',
            text: 'Sender',
            getSubmenuItems: () =>
              senderVariables.map((item) => ({
                ...item,
                type: 'menuitem',
                onAction: () => editor.insertContent(item.value),
              })),
          },
          ...otherVariables.map((item) => ({
            ...item,
            type: 'menuitem',
            onAction: () => editor.insertContent(item.value),
          })),
        ];
        callback(items);
      },
    });

    if (props?.showUploadIcon) {
      editor.ui.registry.addIcon('uploadbuttonIcon', uploadButtonIcon());

      editor.ui.registry.addButton('uploadbutton', {
        style: {
          background: 'blue',
        },
        tooltip: 'Upload files',
        icon: 'uploadbuttonIcon',
        onAction: function () {
          props?.handleFileUpload();
        },
      });
    }

    if (props?.showAiWriter) {
      editor.ui.registry.addIcon('aiWriterIcon', aIWriterIcon());
      editor.ui.registry.addButton('aiEmailWrite', {
        text: 'AI-writer',
        icon: 'aiWriterIcon',
        tooltip: 'AI-writer',
        onAction: function () {
          props?.handleAIWriteOnClick(true);
        },
      });
    }

    editor.ui.registry.addButton('unsubscribe', {
      text: '<svg version="1.0" width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#757575" stroke="none"><path d="M620 4464 c-215 -46 -400 -232 -445 -449 -22 -105 -22 -2806 0 -2910 41 -196 199 -371 393 -437 l67 -23 1892 -3 c2076 -3 1965 -6 2095 57 141 67 260 203 308 351 l25 75 0 1435 0 1435 -23 73 c-61 193 -239 355 -437 397 -102 21 -3775 21 -3875 -1z m3822 -314 c63 -18 129 -70 162 -130 28 -49 31 -65 34-155 l3 -100 -1040 -667 -1040 -668 -1041 668 -1041 667 3 100 c3 90 6 106 34 155 32 58 99 112 158 129 45 13 3721 14 3768 1z m-2944 -1417 l1007 -648 55 0 56 0 1007 648 c554 356 1009 647 1012 647 3 0 4 -501 3 -1112 l-3 -1113 -22-41 c-28 -53 -74 -99 -127 -127 l-41 -22 -1864 -3 c-1248 -1 -1876 1 -1901 8-64 17 -130 70 -164 130 l-31 55 -3 1113 c-1 611 0 1112 3 1112 4 0 459 -291 1013 -647z"/></g></svg>',
      tooltip: 'Unsubscribe',
      onAction: function () {
        const selectedContent = editor.selection.getContent();
        editor.insertContent(
          `<a style="text-decoration: unset;" href='##unsubscribe##' target='_blank'>${
            selectedContent || 'unsubscribe'
          }</a>`,
        );
      },
    });
    editor.on('KeyDown', function (ed, evt) {
      if (characterLimit && characterLimit > 0) {
        try {
          const chars_without_html = trim(editor.getContent().replace(/(<([^>]+)>)/gi, '')).length;
          const chars_with_html = editor.getContent().length;
          let key = ed.keyCode;
          setCharacters(chars_without_html);
          if (allowed_keys.indexOf(key) != -1) {
            return;
          }
          if (chars_with_html > characterLimit + characterLimitHtml) {
            ed.stopPropagation();
            ed.preventDefault();
          } else if (chars_without_html > characterLimit - 1 && key != 8 && key != 46) {
            ed.stopPropagation();
            ed.preventDefault();
            let trimmedContent = editor.getContent();
            trimmedContent = editor.getContent().substring(0, characterLimit);
            setCharacters(trimmedContent.length);
            editor.setContent(trimmedContent);
          }
        } catch (error) {}
      }
    });

    editor.on('keyup change', () => {
      const content = editor.getContent({ format: 'text' }).length;

      const charCountFooter = document.getElementById('charCountFooter');
      if (charCountFooter) {
        setCharactersNumber(content);
      }
    });
  };
  const customFonts = `
    Arial=arial,helvetica,sans-serif;
    Times New Roman='Times New Roman',times;
    Courier New=courier new,courier;
    Verdana=verdana,geneva;
    Roboto=Roboto, sans-serif;
    Calibri=Calibri;
    Andale Mono=andale mono,times;
    Arial Black=arial black,avant garde;
    Book Antiqua=book antiqua,palatino;
    Comic Sans MS=comic sans ms,sans-serif;
    Georgia=georgia,palatino;
    Helvetica=helvetica;
    Impact=impact,chicago;
    Symbol=symbol;
    Tahoma=tahoma,arial,helvetica,sans-serif;
    Terminal=terminal,monaco;
    Trebuchet MS=trebuchet ms,geneva;
    Webdings=webdings;
    Wingdings=wingdings,zapf dingbats
  `;
  return (
    <Box sx={{ '& .tox-tinymce': { borderRadius: '4px' } }}>
      {/*{props?.label && characterLimit > 0 ? (*/}
      {/*  <Box display="flex" justifyContent="space-between" p={'0 8px 8px'}>*/}
      {/*    <Typography color="textSecondary" variant="body2">*/}
      {/*      {props?.label}*/}
      {/*    </Typography>*/}
      {/*    <Box>*/}
      {/*      {characterLimit > 0 ? (*/}
      {/*        <Typography>*/}
      {/*          {characters}/{characterLimit}*/}
      {/*        </Typography>*/}
      {/*      ) : null}*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*) : null}*/}
      <div style={{ position: 'relative' }}>
        <Editor
          key={props.placeHolder} // Forces re-render when placeHolder changes
          ref={editorRef}
          apiKey={apiKey}
          className={className}
          value={value}
          onBlur={handleBlur}
          onInit={(evt, editor) => (editorRef.current = editor)}
          disabled={disabled}
          init={{
            height: height,
            // forced_root_block: false,
            ...(isDark && {
              skin: 'oxide-dark',
              content_css: 'dark',
            }),
            // valid_styles: { '*': 'font-weight,font-style,text-decoration' },
            // valid_elements: 'a[href|title|target],del,b,strong,del,i,blockquote,p,br,em,ul,li,ol',
            paste_postprocess: function (plugin, args) {
              const allowedStyles = ['font-family', 'font-weight', 'font-style', 'color']; // Allowed CSS properties
              const regexStyleCleanup = new RegExp(
                `(${allowedStyles.join('|')})\\s*:[^;]+;?`,
                'gi',
              );

              const tempDiv = document.createElement('div');
              tempDiv.innerHTML = args.node.innerHTML;

              // Clean up styles on <span> tags
              // tempDiv.querySelectorAll('span[style]').forEach(span => {
              //   const style = span.getAttribute('style');
              //   const cleanedStyles = style?.match(regexStyleCleanup)?.join('; ') || '';
              //   if (cleanedStyles) {
              //     span.setAttribute('style', cleanedStyles);
              //   } else {
              //     span.removeAttribute('style');
              //   }
              // });

              // REPLACE ALL FONTS WITH ARIAL FONT.
              tempDiv.querySelectorAll('*').forEach((el) => {
                // 1. Replace existing font styles with Arial
                let style = el.getAttribute('style') || '';

                // Ensure the style ends with a semicolon if not empty
                if (style && !style.trim().endsWith(';')) {
                  style += ';';
                }

                // Add the font-family rule
                el.setAttribute('style', `${style} font-family: Arial;`.trim());
              });

              // Detect and replace <p> with only &nbsp; or <span>&nbsp;</span>
              tempDiv.querySelectorAll('p').forEach((p) => {
                const innerHTML = p.innerHTML.trim();

                // Case 1: <p>&nbsp;</p>
                if (innerHTML === '&nbsp;') {
                  const span = document.createElement('span');
                  span.innerHTML = '&nbsp;';
                  p.replaceWith(span);
                }

                // Case 2: <p><span>&nbsp;</span></p>
                else if (
                  p.children.length === 1 &&
                  p.firstElementChild.tagName === 'SPAN' &&
                  p.firstElementChild.innerHTML.trim() === '&nbsp;'
                ) {
                  const span = document.createElement('span');
                  span.innerHTML = '&nbsp;';
                  p.replaceWith(span);
                }
              });
              args.node.innerHTML = tempDiv.innerHTML;
            },
            formats: {
              forecolor: isSignature
                ? {
                    inline: 'div',
                    classes: 'forecolor',
                    styles: { display: 'inline', color: '%value' },
                  }
                : {
                    inline: 'span',
                    styles: { color: '%value' },
                  },
              underline: {
                inline: 'u',
              },
              // fontname: {
              //   block: 'p',
              //   styles: { fontFamily: '%value' },
              //   // remove: 'all',
              // },
              // fontsize: {
              //   block: 'p',
              //   styles: { fontSize: '%value' },
              //   // remove: 'all',
              // },
              // fontName: {
              //   block: 'p',
              //   styles: { fontFamily: '%value' },
              //   // remove: 'all',
              // },
              // fontSize: {
              //   block: 'p',
              //   styles: { fontSize: '%value' },
              //   // remove: 'all',
              // },
            },
            // font_formats: 'Calibri=Calibri;',
            font_family_formats: customFonts,
            // menubar: false,
            statusbar: false,
            placeholder: props.placeHolder || '',
            menubar: false,
            content_style: `
        @import url("https://fonts.googleapis.com/css?family=Calibri:300,400,500,700&display=swap");
        body { 
          font-family: arial;
          font-size: 12pt;
          ${textColor ? 'color:' + textColor : ''};
          background-color: ${isDark ? '#021424' : '#F5F4F2'};
        }
        .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
          font-weight: 400;
          font-size: 12pt;
          font-family: arial;
          letter-spacing: 0.15px;
        }
      `,
            plugins: [
              'textcolor',
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'wordcount',
              'colorPicker',
            ],
            paste_as_text: pasteAsText,
            selector: 'textarea',
            toolbar: tools || '',
            // toolbar1: tools || '',
            forced_root_block: false, // Prevent wrapping in <p> tags
            force_br_newlines: true, // Insert <br> for new lines
            force_p_newlines: false, // Prevent paragraph insertion for new lines
            toolbar_location: props?.toolbarLocation ? props?.toolbarLocation : 'top',
            images_upload_handler: async (blobInfo, success, failure, progress) => {
              return new Promise(async (resolve, reject) => {
                try {
                  const upload = await fileUpload('', blobInfo.blob(), (event) => {
                    progress(Math.round((100 * event.loaded) / event.total));
                  });
                  const url = upload.upload.url;
                  resolve(url);
                  success(url);
                } catch (e) {
                  reject(e);
                  failure(e);
                }
              });
            },
            setup: isSetup ? setup : () => {},
          }}
          onEditorChange={handleEditorChange}
        />
        {characterLimit ? (
          <div
            id="charCountFooter"
            style={{
              textAlign: 'right',
              padding: '8px',
              fontSize: '12px',
              color: '#555',
              position: 'absolute',
              right: '0px',
              bottom: '0px',
            }}
          >
            {`${characterNumber}/${characterLimit}`}
          </div>
        ) : null}
      </div>
      {error === true ? <FormHelperText error={true}>{helperText}</FormHelperText> : null}
    </Box>
  );
};

TextEditor.propTypes = propTypes;
TextEditor.defaultProps = defaultProps;

export default TextEditor;
