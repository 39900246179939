import React from 'react';
import { Dialog, Stack, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ModalContainer, ModalContent, ModalHeader, useStyles } from './style';
import { Button } from '../../../../components/shared';

const IcpModal = ({
  isErrorButton,
  secondButtonTitle = '',
  onCancel = () => {},
  onSubmit = () => {},
  content = '',
  open = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <Dialog open={open} onClose={() => onCancel()} classes={classes.commonModalContainer}>
        <ModalContainer>
          <ModalHeader>
            <CloseIcon className={classes.icpModalCloseIcon} onClick={() => onCancel()} />
          </ModalHeader>
          <ModalContent>{content}</ModalContent>
          <Stack
            direction="row"
            p={'0px 24px 24px 24px'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            gap={'20px'}
          >
            <Button className={classes.cancelButtonICPModal} color={'secondary'} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant={'contained'}
              color={isErrorButton ? 'error' : 'secondary'}
              onClick={() => onSubmit()}
              className={classes.secondButtonICPModal}
              sx={isErrorButton && { background: theme.palette.background.red }}
            >
              {secondButtonTitle}
            </Button>
          </Stack>
        </ModalContainer>
      </Dialog>
    </>
  );
};
export default IcpModal;
