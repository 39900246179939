export const LOGIN = 'LOGIN';
export const LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE';
export const LOGOUT = 'LOGOUT';
export const RESTORE_SESSION = 'RESTORE_SESSION';
export const VERIFY_USER = 'VERIFY_USER';
export const SHOW_WELCOME_MODAL = 'SHOW_WELCOME_MODAL';

export const REGISTER = 'REGISTER';
export const REGISTER_WITH_THIRD_PARTY = 'REGISTER_WITH_THIRD_PARTY';
export const REGISTER_SET_PASSWORD = 'REGISTER_SET_PASSWORD';
export const REGISTER_RESEND_VERIFICATION_LINK = 'REGISTER_RESEND_VERIFICATION_LINK';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const SET_AUTH = 'SET_AUTH';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_EMAIL_PLUGIN = 'SET_EMAIL_PLUGIN';

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_USER_ID = 'SET_USER_ID';

export const SET_NL_TOKEN = 'SET_NL_TOKEN';
export const SET_EMAIL_TOKEN = 'SET_EMAIL_TOKEN';
export const DISCONNECT_EMAIL_INTEGRATION = 'DISCONNECT_EMAIL_INTEGRATION';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const DELETE_UPLOAD_IMAGE = 'DELETE_UPLOAD_IMAGE';
export const SET_IMAGE_UPLOAD = 'SET_IMAGE_UPLOAD';

export const FETCH_VOICES_TABS = 'FETCH_VOICES_TABS';
export const SET_VOICES_TABS = 'SET_VOICES_TABS';

export const FETCH_VOICES = 'FETCH_VOICES';
export const FETCH_USERS_DATA = 'FETCH_USERS_DATA';
export const UPDATE_VOICES_STATUS = 'UPDATE_VOICES_STATUS';
export const SET_VOICES_STATUS = 'SET_VOICES_STATUS';
export const SET_VOICES = 'SET_VOICES';
export const SAVE_VOICES = 'SAVE_VOICES';
export const UPDATE_VOICE = 'UPDATE_VOICE';
export const SET_VOICE = 'SET_VOICE';
export const FETCH_BY_ID = 'FETCH_BY_ID';

export const FETCH_ICP_TABS = 'FETCH_ICP_TABS';

export const FETCH_ICP = 'FETCH_ICP';
export const SET_ICP = 'SET_ICP';
export const SAVE_ICP = 'SAVE_ICP';
export const FETCH_ICP_BY_ID = 'FETCH_ICP_BY_ID';
export const UPDATE_ICP = 'UPDATE_ICP';
export const DELETE_ICP = 'DELETE_ICP';

export const HIDE_PROFILE_TAB = 'HIDE_PROFILE_TAB';
