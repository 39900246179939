import React, { useMemo, useState } from 'react';
import { Stack, Typography, Box, Avatar, Menu } from '@mui/material';
import images from 'src/config/images';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, showWelcomeModal } from 'src/modules/auth/actions/authActions';
import { Button } from '../../../../components/shared';
import storage from '../../../../utils/storageUtils';
import { makeStyles } from '@mui/styles';

const trialSubscriptionPlansPriceId = [
  'Starter-Monthly-Plan-GBP-Monthly',
  'Trial-Plan-14-day-GBP-Every-14-days',
];

const useStyles = makeStyles(({ palette }) => ({
  iconStyle: {
    padding: '12px 0',
    display: 'grid',
    justifyContent: 'center',
    gap: '12px',
    width: '100px',
    height: '94px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#21313f',
    },
  },
  menuPaper: {
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
    color: palette.text.white,
    backgroundColor: '#21313f',
    borderRadius: '16px',
    marginTop: '10px',
    marginLeft: '-13px',
  },
  goToProfile: {
    paddingLeft: '0px',
    color: '#e4c077',
  },
}));

const LandingPageView = ({ userData, newUser = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userNameMenuElement, setUserNameMenuElement] = useState(null);
  const name = userData ? `${userData.fname || ''}` : '';
  const userNameId = 'user-name-menu-id';

  const hasLmsAccess = useMemo(() => {
    return userData?.lmsmonthly === true || userData?.lmsannual === true;
  }, [userData]);

  const hasOutreachAccess = useMemo(() => {
    if (!userData?.outreachAnnual && !userData?.outreachMonthly && !hasLmsAccess) return true;
    return userData?.outreachAnnual === true || userData?.outreachMonthly === true;
  }, [userData]);

  const handleUserNameMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setUserNameMenuElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setUserNameMenuElement(null);
  };

  const handleLogout = (event) => {
    // setTenantHash();
    handleMenuClose();
    dispatch(logout(event));
  };

  const isGetUserOnTrial = (user) => {
    if (!user?.tenants?.length || !user.tenants[0].subscription) {
      return false;
    }
    const subscriptionData = user.tenants[0].subscription;
    const hasActiveStatus = Object.values(subscriptionData).filter(
      (item) =>
        item &&
        Object.keys(item).length > 0 &&
        item?.status === 'active' &&
        !trialSubscriptionPlansPriceId.includes(item?.item_price_id || item?.entity_id),
    );
    return hasActiveStatus?.length > 0;
  };

  const showUpgradePlan = useMemo(() => isGetUserOnTrial(userData), [userData]);

  return (
    <Stack position={'fixed'} top={'0'} bottom={'0'} left={'0'} right={'0'} zIndex={10000}>
      <Stack color={'white'} width={'100vw'} height={'100vh'} bgcolor={'#021424'}>
        <Box
          sx={{
            padding: '0px 48px',
            height: '44px',
            backgroundColor: '#060A0D',
          }}
          display={'flex'}
          gap={'24px'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <img src={images.app.logoWhite} height={'24px'} width={'120px'} />
          <Box
            onClick={handleUserNameMenuOpen}
            sx={{ position: 'relative', zIndex: 1000 }} // Adding z-index here
          >
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              sx={{
                cursor: 'pointer',
                border: '1px solid #1A2633',
                boxShadow: !Boolean(userNameMenuElement) ? 'none' : '0px 4px 4px 0px #26394D',
                padding: '4px 10px',
                borderRadius: '999px',
              }}
            >
              <img src={images.icons.whiteAppIcon} alt="app icon" />
              <Avatar
                sx={{
                  width: '20px',
                  height: '20px',
                  fontSize: '16px',
                  backgroundColor: '#AB47BC',
                  textTransform: 'capitalize',
                }}
              >
                {name.charAt(0)}
              </Avatar>
            </Box>
          </Box>
          <Menu
            id={userNameId}
            keepMounted
            anchorEl={userNameMenuElement}
            open={Boolean(userNameMenuElement)} // Ensure open is true if anchor is set
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{
              paper: classes.menuPaper,
            }}
            sx={{ position: 'absolute', zIndex: 12000 }}
          >
            <Box sx={{ padding: '28px' }}>
              <Box display="flex" gap="16px">
                <Avatar
                  sx={{
                    width: '60px',
                    height: '60px',
                    fontSize: '32px',
                    backgroundColor: '#AB47BC',
                    textTransform: 'capitalize',
                  }}
                >
                  {name.charAt(0)}
                </Avatar>
                <Box sx={{ width: '224px' }}>
                  <Stack gap={'8px'}>
                    <Typography variant="h6" sx={{ fontWeight: '600', fontSize: '16px' }}>
                      Hi, {name}!
                    </Typography>
                    <Typography variant="body2">{userData?.email}</Typography>
                    <Box>
                      <Button
                        onClick={() => {
                          history.push('/profile/details');
                          handleMenuClose();
                        }}
                        className={classes.goToProfile}
                        variant={'text'}
                      >
                        <Typography variant={'h5'}>Go to Profile</Typography>
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Box>
            <Box sx={{ padding: '16px 28px', paddingTop: '0' }}>
              <Box
                sx={{
                  padding: '12px',
                  display: 'grid',
                  gridTemplateColumns: 'auto auto auto',
                  backgroundColor: '#26394d',
                  borderRadius: '12px',
                }}
              >
                {userData?.acl?.accessLevel === 'Admin' && (
                  <Box
                    onClick={() => {
                      const route = history.location.pathname;
                      if (route && route !== '') {
                        storage.set('REFERRAL_ROUTE', route);
                      }
                      window.open('/admin/users', '_blank');
                    }}
                    class={classes.iconStyle}
                  >
                    <img
                      src={images.icons.admin}
                      alt="Admin icon"
                      style={{ margin: 'auto' }}
                      height="40px"
                      width="40px"
                    />
                    Admin
                  </Box>
                )}
                {hasLmsAccess && (
                  <Box
                    class={classes.iconStyle}
                    onClick={() => {
                      window.location.replace(process.env.REACT_APP_LMS_URL);
                    }}
                  >
                    <img
                      src={images.icons.learning}
                      alt="learning icon"
                      style={{ margin: 'auto' }}
                      height="40px"
                      width="40px"
                    />
                    Learning
                  </Box>
                )}
                {hasOutreachAccess && (
                  <Box
                    class={classes.iconStyle}
                    onClick={() => {
                      window.open('/');
                    }}
                  >
                    <img
                      src={images.icons.outreach}
                      alt="outreach icon"
                      style={{ margin: 'auto' }}
                      height="40px"
                      width="40px"
                    />
                    Outreach
                  </Box>
                )}
              </Box>
            </Box>

            {!showUpgradePlan && (
              <Box
                sx={{
                  margin: '0 0 24px 0',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'white',
                  alignItems: 'flex-end',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    padding: '8px 0 8px 23px',
                    margin: '0',
                    height: '80px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Trillion...</Typography>
                  <div
                    style={{
                      background: 'linear-gradient(to right, #3F9FFE, #CF0175, #F0A818)',
                      borderRadius: '999px',
                      marginTop: '8px',
                      padding: '0',
                    }}
                  >
                    <Button
                      sx={{
                        borderRadius: '999px',
                        background: 'white',
                        fontSize: '12px',
                        fontWeight: '500',
                        padding: '0 8px',
                        margin: '1px',
                      }}
                      onClick={() => {
                        if (user?.acl?.accessLevel?.toLowerCase() === 'admin') {
                          history.push('/admin/billing/subscriptions');
                        }
                      }}
                    >
                      Upgrade
                    </Button>
                  </div>
                </Box>
                <img src={images.icons.multiIcons} alt="Multi icons" />
                <Box padding="0 5px">
                  <img
                    src={images.icons.close}
                    style={{ position: 'absolute', top: '4px', right: '4px' }}
                    alt="close"
                  />
                </Box>
              </Box>
            )}

            <Box
              gap="8px"
              padding="0 28px"
              sx={{ paddingBottom: '16px', cursor: 'pointer', display: 'inline-block' }}
              onClick={handleLogout}
            >
              <Box display="flex" alignItems="center" gap="8px">
                <img src={images.icons.whiteSignOut} alt="sign out" />
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  Sign out of all accounts
                </Typography>
              </Box>
            </Box>
          </Menu>
        </Box>
        <Stack justifyContent={'center'} alignItems={'center'} padding={'80px 48px'}>
          <Stack width={'100%'} maxWidth={'900px'} justifyContent={'center'}>
            <Stack justifyContent={'center'} gap={'64px'}>
              <Stack gap={'16px'} justifyContent={'center'} alignItems={'center'}>
                <Typography fontSize={'45px'} fontWeight={200}>
                  Good Morning, {userData?.fname}!
                </Typography>
                <Typography fontSize={'22px'} fontWeight={300} sx={{ color: '#CCCCCC' }}>
                  What would you like to do today?
                </Typography>
              </Stack>
              <Stack gap={'32px'} justifyContent={'center'} alignItems={'center'} direction={'row'}>
                {hasLmsAccess && (
                  <Stack
                    gap={'24px'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    maxWidth={'176px'}
                    onClick={() => {
                      window.location.replace(process.env.REACT_APP_LMS_URL);
                    }}
                  >
                    <img
                      height={'64px'}
                      width={'64px'}
                      src={images.loginDashboard.learning}
                      style={{ borderRadius: '10px', cursor: 'pointer' }}
                    />
                    <Typography
                      fontSize={'26px'}
                      fontWeight={200}
                      lineHeight={'31.2px'}
                      sx={{ cursor: 'pointer' }}
                    >
                      Learning
                    </Typography>
                  </Stack>
                )}

                {hasOutreachAccess && (
                  <Stack
                    gap={'24px'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    maxWidth={'176px'}
                    onClick={() => {
                      history.push('/outreachvideolibrary');
                      // history.push('/onboarding');
                      dispatch(showWelcomeModal(false));
                    }}
                  >
                    <img
                      height={'64px'}
                      width={'64px'}
                      src={images.loginDashboard.outreach}
                      style={{ borderRadius: '10px', cursor: 'pointer' }}
                    />
                    <Typography
                      fontSize={'26px'}
                      fontWeight={200}
                      lineHeight={'31.2px'}
                      sx={{ cursor: 'pointer' }}
                    >
                      Outreach
                    </Typography>
                  </Stack>
                )}

                {/*  <Stack*/}
                {/*    gap={'24px'}*/}
                {/*    justifyContent={'center'}*/}
                {/*    width={'100%'}*/}
                {/*    maxWidth={'176px'}*/}
                {/*    alignItems={'center'}*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      height={'64px'}*/}
                {/*      width={'64px'}*/}
                {/*      src={images.loginDashboard.trillion}*/}
                {/*      style={{ borderRadius: '10px' }}*/}
                {/*    />*/}
                {/*    <Typography fontSize={'26px'} fontWeight={200} lineHeight={'31.2px'}>*/}
                {/*      Trillion*/}
                {/*    </Typography>*/}
                {/*  </Stack>*/}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LandingPageView;
