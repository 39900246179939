import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { Button } from '../../../../components/shared';

export const EmptyVoiceCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: '56px 12px 12px 12px',
  padding: '80px',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.border.lightWhite}`,
  boxShadow: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.background.paper,
}));

export const TOVWithNoDataFound = styled(Stack)(({ theme }) => ({
  padding: '92px 0px',
  marginTop: '60px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px'
}));

export const ModalContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  borderRadius: '12px',
  background: theme.palette.background.lightWhite,
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.07) inset',
}));

export const LoadingText = styled(Typography)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  color: theme.palette.text.primary,
  padding: '24px',
  paddingBottom: '0',
}));

export const LoadingImage = styled(Stack)({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
});

export const ModalHeader = styled(Box)({
  display: 'flex',
  padding: '24px',
  justifyContent: 'space-between',
  gap: '24px',
  alignSelf: 'stretch',
});

export const ModalContent = styled(Stack)(({ withPadding }) => ({
  padding: withPadding && '0 24px',
  flexDirection: 'column',
  alignSelf: 'stretch',
}));

export const ModalFooter = styled(Stack)({
  padding: '24px',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
});

export const FieldTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const FieldSubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.text.secondary,
}));

export const NameFieldTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.text.primary,
  lineHeight: '28px',
}));

export const useStyles = makeStyles((theme) => ({
  noDataTxT:{
    color: theme.palette.text.grey
  },
  modalContainer: {
    '& .MuiDialog-paper': {
      width: '720px',
      borderRadius: '12px',
      maxWidth: '720px',
      background: theme.palette.text.white1,
    },
  },
  inputFieldContainerAnalyse: {
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
    },
    '& .MuiInputBase-root': {
      minHeight: '425px',
      alignItems: 'flex-start',
      textAlign: 'start',
      padding: '16px',
      background: theme.palette.background.paper,
      borderRadius: '8px',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: `${theme.palette.text.grey} !important`,
      fontWeight: '400',
      fontSize: '14px',
      opacity: '1',
    },
  },
  reAnalyseButton: {
    color: theme.palette.secondary.main,
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Inter',
    lineHeight: '24px',
    letterSpacing: '0.46px',
  },
  inputFieldContainerAddName: {
    '& .MuiInputBase-root': {
      height: '48px',
      fontSize: '14px',
      borderRadius: '7px',
      background: theme.palette.background.paper,
    },
  },
  gradiantBG: {
    borderRadius: '8px',
    background:
      'linear-gradient(236deg, rgba(255, 212, 128, 0.20) 10.71%, rgba(242, 64, 164, 0.20) 58.14%, rgba(25, 118, 210, 0.20) 93.71%)',
    padding: '16px',
  },
  manualButtonStyle: {
    width: '118px !important',
    background:
      'linear-gradient(236deg, rgba(255, 212, 128, 0.50) 10.71%, rgba(242, 64, 164, 0.50) 58.14%, rgba(25, 118, 210, 0.50) 93.71%)',
    backgroundClip: 'padding-box',
    border: '1px solid transparent',
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 'inherit',
      top: '1px !important',
      right: '1px !important',
      bottom: '1px !important',
      left: '1px !important',
      zIndex: -1,
    },
    zIndex: 1,
  },
  toneList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    rowGap: '12px',
    columnGap: '12px',
    paddingLeft: '4px',
    maxWidth: '512px',
  },
  activeButton: {
    borderWidth: '0px',
    background:
      'linear-gradient(236deg, rgba(255, 212, 128, 0.50) 10.71%, rgba(242, 64, 164, 0.50) 58.14%, rgba(25, 118, 210, 0.50) 93.71%)',
    '&::before': {
      display: 'none',
    },
  },
  applyBtn: {
    background: 'rgba(0, 0, 0, 0.12) !important',
    color: 'rgba(0, 0, 0, 0.38) !important',
    borderRadius: '999px',
    fontSize: '16px',
    fontWeight: '500',
    padding: '10px 24px',
    width: '100%',
  },
  textFieldNameBox: {
    '& .MuiFormControl-root': {
      marginTop: '6px !important',
    },
  },
  textFieldName: {
    '& .MuiInputBase-root': {
      height: '48px',
      fontSize: '14px',
      borderRadius: '7px',
      background: theme.palette.background.paper,
    },
  },
  inputSelectSampleText: {
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiInputBase-root': {
      borderRadius: '8px !important',
      backgroundColor: theme.palette.text.white1,
    },
    '& .MuiSelect-nativeInput': {
      backgroundColor: theme.palette.text.white1,
      borderRadius: '8px !important',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '28px !important',
    },
    '& .MuiSelect-select': {
      padding: '10px 16px !important',
      backgroundColor: theme.palette.text.white1,
      borderRadius: '8px !important',
    },
    '& .MuiTypography-root': {
      fontSize: '14px !important',
      fontWeight: '400 !important',
      color: `${theme.palette.text.grey} !important`,
    },
    '& .MuiSelect-nativeInput::placeholder': {
      fontSize: '14px !important',
      fontWeight: '400 !important',
      color: 'rgba(0, 0, 0, 0.38) !important',
    },
  },
  emptyButton: {
    cursor: 'auto',
    p: {
      color: theme.palette.text.grey,
    },
  },
  textButton: {
    fontSize: '16px',
    fontWeight: '500',
  },
  gradientButtonEmpty: {
    borderRadius: '999px',
    background: '#F4F4F4 !important',
    color: theme.palette.text.grey,
    fontWeight: 400,
    '&::before': {
      display: 'none !important',
    },
  },
  gradientButtonManualCreate: {
    color: '#1A1A1A !important',
    fontSize: '16px !important',
    fontWeight: '500 !important',
  },
  secondaryButton: {
    fontSize: '16px',
    fontWeight: '500',
    padding: '8px 16px',
    width: '70px',
    borderRadius: '4px',
  },
  nameTypography: { fontSize: '20px', fontWeight: '500' },
  descriptionTypography: {
    letter: '0.4px',
    color: '#1A1A1A',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    wordBreak: 'break-word',
  },
  defaultTypography: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.secondary.main,
    height: '42px',
    padding: '0px 16px',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1976D217',
    width: 'fit-content',
  },
  description: {
    maxWidth: '515px',
    minHeight: '120px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: '48px',
  },
  contentContainer: {
    padding: '24px 72px',
    overflowY: 'auto',
    scrollbarGutter: 'stable',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
  },
  menuItem: {
    padding: '8px 16px',
    color: theme.palette.text.secondary,
    fontSize: '16px !important',
    fontWeight: '500 !important',
    '&:hover': {
      color: '#1976D2 !important',
      background: 'rgba(25, 118, 210, 0.08) !important',
    },
  },
  menuLabel: {
    color: theme.palette.text.secondary,
    fontSize: '16px !important',
    fontWeight: '500 !important',
    '&:hover': {
      color: '#1976D2 !important',
      background: 'rgba(25, 118, 210, 0.08) !important',
    },
  },
  getStartedNow: {
    borderColor: theme.palette.secondary.main,
  },
  lh32: {
    lineHeight: '32px',
  },
  lh28: {
    lineHeight: '28px',
  },
}));

export const AIVoiceCreateViewHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Inter',
}));

AIVoiceCreateViewHeader.defaultProps = {
  variant: 'h1',
};

export const AIVoiceCreateViewSubHeader = styled(Typography)(({ theme, ...props }) => ({
  color: props?.isGrey ? theme.palette.text.grey : theme.palette.text.black,
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: 'Inter',
  lineHeight: '21px',
  letterSpacing: '0.15px',
}));

export const EmptyAIVoiceCreateViewSubHeader = AIVoiceCreateViewSubHeader;
EmptyAIVoiceCreateViewSubHeader.defaultProps = {
  variant: 'body1',
};

export const EmptyVoiceHeader = styled(Typography)(({ theme, ...props }) => ({
  color: '#00000099',
  fontSize: '14px',
  fontWeight: 700,
  fontFamily: 'Inter',
  lineHeight: '16.338px',
  letterSpacing: '0.15px',
}));

EmptyVoiceHeader.defaultProps = {
  variant: 'h1',
};
AIVoiceCreateViewHeader.defaultProps = {
  variant: 'h1',
};
export const AIVoiceContentHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '28px',
}));

export const AIVoiceActionContainer = styled(Stack)(({ theme }) => ({
  gap: '24px',
  padding: '16px',
  backgroundColor: theme.palette.text.white1,
  borderRadius: '8px',
  height: '100%',
  minHeight: '457px',
}));

export const HeadLineContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.black1,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
}));

export const AttributeSubHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.grey,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.46px',
}));

export const DetailedContent = styled(Typography)(({ theme, ...props }) => ({
  color: props?.isGrey ? theme.palette.text.grey : theme.palette.text.black1,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
}));

export const AIDetailedContent = styled(DetailedContent)(({}) => ({
  fontWeight: 700,
}));

export const BackButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.4px',
}));

export const ButtonBaseContainer = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: 0,
}));

export const StyledButton = styled(Button)({
  borderRadius: '999px',
  background:
    'linear-gradient(236deg, rgba(255, 212, 128, 0.20) 10.71%, rgba(242, 64, 164, 0.20) 58.14%, rgba(25, 118, 210, 0.20) 93.71%)',
  padding: '10px 0px',
});
