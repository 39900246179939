import * as authTypes from './authTypes';

// export const toggleSidebar = () => ({ type: authTypes.TOGGLE_SIDEBAR });
export const login = (data, resolve, reject) => ({
  type: authTypes.LOGIN,
  data,
  resolve,
  reject,
});

export const loginWithGoogle = (data, resolve, reject) => ({
  type: authTypes.LOGIN_WITH_GOOGLE,
  data,
  resolve,
  reject,
});

export const register = (data, resolve, reject) => ({
  type: authTypes.REGISTER,
  data,
  resolve,
  reject,
});

export const thirdPartyRegister = (data, resolve, reject) => ({
  type: authTypes.REGISTER_WITH_THIRD_PARTY,
  data,
  resolve,
  reject,
});

export const registerReVerification = (id, resolve, reject) => ({
  type: authTypes.REGISTER_RESEND_VERIFICATION_LINK,
  id,
  resolve,
  reject,
});

export const registerSetPassword = (uid, data, resolve, reject) => ({
  type: authTypes.REGISTER_SET_PASSWORD,
  uid,
  data,
  resolve,
  reject,
});

export const logout = (data, resolve, reject) => ({
  type: authTypes.LOGOUT,
  data,
});

export const verifyUser = (tid, uid) => ({
  type: authTypes.VERIFY_USER,
  tid,
  uid,
});

export const restoreSession = (newUser = false, redirect) => ({
  type: authTypes.RESTORE_SESSION,
  newUser,
  redirect,
});

export const showWelcomeModal = (welcomeModal = false, newUser = false) => ({
  type: authTypes.SHOW_WELCOME_MODAL,
  payload: { welcomeModal, newUser },
});

export const setAuth = (data) => ({ type: authTypes.SET_AUTH, data });
export const userLoginError = (error) => ({ type: authTypes.USER_LOGIN_ERROR, error });
export const setEmailPlugIn = (data) => ({
  type: authTypes.SET_EMAIL_PLUGIN,
  data,
});
export const setProfile = (data) => ({ type: authTypes.SET_PROFILE, data });

export const forgotPassword = (payload, resolve, reject) => ({
  type: authTypes.FORGOT_PASSWORD,
  payload,
  resolve,
  reject,
});
export const resetPassword = (payload, resolve, reject) => ({
  type: authTypes.RESET_PASSWORD,
  payload,
  resolve,
  reject,
});

export const putUser = (payload, resolve, reject) => ({
  type: authTypes.UPDATE_CURRENT_USER,
  payload,
  resolve,
  reject,
});
export const setCurrentUser = (payload, resolve, reject) => ({
  type: authTypes.SET_CURRENT_USER,
  payload,
  resolve,
  reject,
});
export const setUser = (payload, resolve, reject) => ({
  type: authTypes.SET_USER_ID,
  payload,
  resolve,
  reject,
});
export const setEmailNLToken = (payload, resolve, reject) => ({
  type: authTypes.SET_EMAIL_TOKEN,
  payload,
  resolve,
  reject,
});
export const fetchUsers = (id, resolve, reject) => ({
  type: authTypes.GET_CURRENT_USER,
  id,
  resolve,
  reject,
});

export const setNLToken = (code, userid, resolve, reject) => ({
  type: authTypes.SET_NL_TOKEN,
  code,
  userid,
  resolve,
  reject,
});

export const disconnectIntegration = (id, status, resolve, reject) => ({
  type: authTypes.DISCONNECT_EMAIL_INTEGRATION,
  id,
  status,
  resolve,
  reject,
});

export const uploadImage = (file, resolve, reject) => ({
  type: authTypes.UPLOAD_IMAGE,
  file,
  resolve,
  reject,
});
export const deleteProfileImage = (id, resolve, reject) => ({
  type: authTypes.DELETE_UPLOAD_IMAGE,
  id,
  resolve,
  reject,
});
export const setUploadImage = (payload, resolve, reject) => ({
  type: authTypes.SET_IMAGE_UPLOAD,
  payload,
  resolve,
  reject,
});

/* Voices Actions */

export const fetchVoicesTabs = () => ({ type: authTypes.FETCH_VOICES_TABS });

export const setVoiceTabs = (tabs) => ({ type: authTypes.SET_VOICES_TABS, tabs });

export const fetchVoices = (paging, filters, sort) => ({
  type: authTypes.FETCH_VOICES,
  paging,
  filters,
  sort,
});

export const setVoices = (data) => ({
  type: authTypes.SET_VOICES,
  data,
});

export const saveVoice = (data, resolve, reject) => ({
  type: authTypes.SAVE_VOICES,
  data,
  resolve,
  reject,
});

export const updateVoiceStatus = (rowData) => ({
  type: authTypes.UPDATE_VOICES_STATUS,
  rowData,
});

export const setVoiceStatus = (rowData) => ({
  type: authTypes.SET_VOICES_STATUS,
  rowData,
});

export const setVoice = (data) => ({
  type: authTypes.SET_VOICE,
  data,
});

export const updateVoice = (rowData, resolve, reject) => ({
  type: authTypes.UPDATE_VOICE,
  rowData,
  resolve,
  reject,
});

export const fetchVoiceById = (id, resolve, reject) => ({
  type: authTypes.FETCH_BY_ID,
  id,
  resolve,
  reject,
});

export const fetchIcp = (paging, filters, sort) => ({
  type: authTypes.FETCH_ICP,
  paging,
  filters,
  sort,
});

export const setICP = (data) => ({
  type: authTypes.SET_ICP,
  data,
});

export const saveICP = (data, resolve, reject) => ({
  type: authTypes.SAVE_ICP,
  data,
  resolve,
  reject,
});

export const fetchICPById = (id, resolve, reject) => ({
  type: authTypes.FETCH_ICP_BY_ID,
  id,
  resolve,
  reject,
});
export const updateICP = (data, resolve, reject) => ({
  type: authTypes.UPDATE_ICP,
  data,
  resolve,
  reject,
});

export const deleteICP = (id, resolve, reject) => ({
  type: authTypes.DELETE_ICP,
  id,
  resolve,
  reject,
});

export const toggleVisibilityOfProfileTab = (data) => ({
  type: authTypes.HIDE_PROFILE_TAB,
  data,
});
