import React, { useMemo, useState } from 'react';
import { Box, CircularProgress, Grid, Stack, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  AIDetailedContent,
  AIVoiceActionContainer,
  AIVoiceContentHeader,
  AIVoiceCreateViewHeader,
  AIVoiceCreateViewSubHeader,
  DetailedContent,
  HeadLineContent,
  StyledButton,
  useStyles,
} from '../../components/Voice/styles';
import images from 'src/config/images';
import { Button, Form } from '../../../../components/shared';
import { formatKey, handleSocketListener } from '../../../../utils/helper';
import { useSocketStore } from '../../../../components/hooks/socketConnector';
import { Title } from '../../../../components/App/Notifications/styles';

const AICreateVoice = ({
  formProps,
  setOpenAICreateVoice,
  setShowLoading,
  setShowVoiceNameField,
  maxHeight,
}) => {
  const [loading, setLoading] = useState(false);
  const { emit, socket } = useSocketStore();

  const { values } = formProps;
  const theme = useTheme();
  const classes = useStyles();

  const reAnalyse = () => {
    setShowLoading(true);
    setOpenAICreateVoice((prevState) => ({ ...prevState, reAnalyse: true }));
    formProps.setFieldValue('voicePrompt', {});
    emit(
      'pipeline:execute',
      JSON.stringify({
        slug: 'tov-v2',
        payload: {
          text: formProps?.values?.sampleText,
        },
      }),
    );

    const responseHandler = (res) => {
      formProps.setFieldValue('voicePrompt', res?.json);
      setShowLoading(false);
    };

    const errorHandler = () => {
      setShowLoading(false);
    };

    handleSocketListener({
      socket,
      responseHandler,
      errorHandler,
    });
  };

  const onChangeSampleVoice = (value) => {
    setLoading(true);
    formProps.setFieldValue('sampleVoice', {
      key: value,
      label: value,
      voice: {},
    });
    emit(
      'pipeline:execute',
      JSON.stringify({
        slug: 'getemail',
        payload: {
          text: JSON.stringify({ ...formProps?.values?.voicePrompt, type: value }),
        },
      }),
    );
    const responseHandler = (res) => {
      formProps.setFieldValue('sampleVoice', {
        key: value,
        label: value,
        voice: res?.json,
      });
      setLoading(false);
    };
    const errorHandler = () => {
      setLoading(false);
    };

    handleSocketListener({
      socket,
      responseHandler,
      errorHandler,
    });
  };

  return (
    <>
      <Stack sx={{ background: theme.palette.background.paper }}>
        <Stack
          direction={'row'}
          gap={'16px'}
          sx={{ padding: '24px', borderBottom: `1px solid ${theme.palette.border.default}` }}
          className={'ai-create-voice-header'}
        >
          <img src={images.voice.toneOfVoiceIcon} alt={''} height={32} width={32} />
          <Stack gap={'8px'}>
            <AIVoiceCreateViewHeader>AI: Create a Tone of Voice</AIVoiceCreateViewHeader>
            <AIVoiceCreateViewSubHeader isGrey>
              Set up a tone of voice profile by analysing example copy to make your generated
              content sound just like you!
            </AIVoiceCreateViewSubHeader>
          </Stack>
        </Stack>
        <Stack className={classes.contentContainer} gap={'24px'} sx={{ maxHeight }}>
          <Stack
            gap={'24px'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <AIVoiceCreateViewHeader>
              Based on the example copy, here is your tone of voice!
            </AIVoiceCreateViewHeader>
            <Button
              startIcon={<img src={images.voice.reanalyseIcon} alt={''} />}
              variant={'text'}
              className={classes.reAnalyseButton}
              onClick={reAnalyse}
            >
              Re-analyse
            </Button>
          </Stack>
          <Grid container columnSpacing={'24px'}>
            <Grid item xs={12} md={7}>
              <Stack gap={'24px'}>
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Headline</AIVoiceContentHeader>
                  <HeadLineContent className={classes.gradiantBG}>
                    {values?.voicePrompt ? values?.voicePrompt?.tone_of_voice : ''}
                  </HeadLineContent>
                </Stack>
                <Stack gap={'8px'}>
                  <AIVoiceContentHeader>Detailed Profile</AIVoiceContentHeader>
                  <DetailedContent className={classes.gradiantBG}>
                    {values?.voicePrompt &&
                      values?.voicePrompt?.characteristics &&
                      Object.entries(values?.voicePrompt?.characteristics).map(([key, value]) => (
                        <>
                          <span style={{ fontWeight: 700 }}>{formatKey(key)}:</span>
                          <br />
                          {Array.isArray(value) ? (
                            <>
                              {value.map((item) => (
                                <>
                                  {item}
                                  <br />
                                </>
                              ))}
                            </>
                          ) : (
                            value
                          )}
                          <br />
                          <br />
                        </>
                      ))}
                  </DetailedContent>
                </Stack>
                <Grid container columnSpacing={'24px'}>
                  <Grid item xs={12} md={6}>
                    <Stack gap={'8px'}>
                      <AIVoiceContentHeader>Style</AIVoiceContentHeader>
                      <AIDetailedContent className={classes.gradiantBG}>
                        {values?.voicePrompt?.style}
                      </AIDetailedContent>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack gap={'8px'}>
                      <AIVoiceContentHeader>Tone</AIVoiceContentHeader>
                      <AIDetailedContent className={classes.gradiantBG}>
                        {values?.voicePrompt?.tone}
                      </AIDetailedContent>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack gap={'24px'} height="100%">
                <Stack gap={'8px'}>
                  <AIVoiceCreateViewHeader>Your Tone of Voice Example</AIVoiceCreateViewHeader>
                  <AIVoiceCreateViewSubHeader isGrey>
                    Here is an example of your tone of voice in action
                  </AIVoiceCreateViewSubHeader>
                </Stack>
                <AIVoiceActionContainer>
                  {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                      <CircularProgress size={30} color="secondary" />
                    </Box>
                  ) : (
                    <>
                      <DetailedContent isGrey>
                        <Title
                          dangerouslySetInnerHTML={{
                            __html: values?.voicePrompt?.example?.replace(/\n/g, '<br />'),
                          }}
                        />
                      </DetailedContent>
                    </>
                  )}
                </AIVoiceActionContainer>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Box
          padding="24px"
          display={'flex'}
          gap="16px"
          justifyContent={'end'}
          className={'ai-creation-footer'}
        >
          <Button
            className={classes.textButton}
            color={'secondary'}
            onClick={() => {
              formProps.setFieldValue('sampleText', values.sampleText);
              formProps.setFieldValue('voicePrompt', {});
              formProps.setFieldValue('sampleVoice', {});
              setOpenAICreateVoice({
                open: true,
                edit: null,
                creationType: 'ai',
                isPromptView: true,
              });
            }}
          >
            Back to input screen
          </Button>
          <Button
            variant={'contained'}
            color="secondary"
            className={classes.secondaryButton}
            onClick={() => {
              setShowVoiceNameField(true);
            }}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </>
  );
};
export default AICreateVoice;
