import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import ICPView from './ICPView';
import { fetchIcp, saveICP, fetchICPById, updateICP, deleteICP } from '../../actions/authActions';
import { fetchJobs } from '../../../contacts/actions/contactActions';

const ICPContainer = (props) => {
  useEffect(() => {
    if (!props.icp?.length) {
      props.fetchICP(props.icp.paging, props.icp.filters, props.icp.sort);
    }
  }, []);
  return <ICPView {...props} />;
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    icp: state.auth?.icp,
    total: state.auth.icp?.paging.count || 10,
    globals: state.app.globals,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchICP: (paging, filters, sort) => dispatch(fetchIcp(paging, filters, sort)),
  getJobsList: (data, reqType) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchJobs(data, reqType, resolve, reject));
    });
  },
  saveICP: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(saveICP(data, resolve, reject));
    });
  },
  getICPById: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchICPById(id, resolve, reject));
    });
  },
  updateICP: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(updateICP(data, resolve, reject));
    });
  },
  deleteICP: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteICP(id, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ICPContainer);
