import React, { useMemo, useState } from 'react';
import { Spacer } from 'src/components/shared';
import { Box, Typography, ButtonBase, Radio, Link, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { ContactItemWrapper } from './styles';
import {
  getEmailIconBasedOnVerificationStatus,
  getFormattedPhoneNumber,
} from '../../utils/contactUtils';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SearchIcon from '@mui/icons-material/Search';
import theme from 'src/config/theme';
import images from '../../../../config/images';

function ContactItem({
  item,
  type,
  onEdit,
  onDelete,
  onAdd,
  showSelection,
  onSelectionChange,
  selected,
  props,
  isUnsubscribed,
  isEnroll = false,
}) {
  const tenant = useSelector((state) => state.app.tenant);

  const value = useMemo(() => {
    switch (type) {
      case 'email':
        return item.email;
        break;

      case 'phone':
        return getFormattedPhoneNumber(item.phone)?.formattedNoWithCC || item.phone;
        break;

      default:
        break;
    }
  }, [item, type]);

  const contactType = item.type;

  if (item.isDeleted) return null;

  const isDomainBlocked = (value, adminSettings) => {
    return adminSettings?.[0]?.blockedDomains?.includes(value.split('@')[1]);
  };

  const emailIcon = getEmailIconBasedOnVerificationStatus(item?.verificationStatus);
  const isEmailIconShow =
    tenant?.tenant_settings?.[0]?.emailVerificationSettings?.enabled && type === 'email';

  return (
    <ContactItemWrapper>
      <Box display="flex" alignItems="center">
        {type === 'email' && showSelection === true ? (
          <Radio
            sx={{ padding: 0, marginRight: '8px' }}
            color="secondary"
            checked={selected === item.id || selected === value}
            value={item.id}
            onChange={(event) => {
              onSelectionChange(item.id);
              // onSelectionChange(event.target.value);
            }}
          />
        ) : null}

        {/* {type === 'email' && (
          <EmailIcon
            style={{ fontSize: '20px', fill: item.isPrimary ? '#1976D2' : 'rgba(0,0,0,0.54)' }}
          />
        )}

        {type === 'phone' && (
          <LocalPhoneIcon
            style={{ fontSize: '20px', fill: item.isPrimary ? '#1976D2' : 'rgba(0,0,0,0.54)' }}
          />
        )} */}

        {isUnsubscribed && (
          <NotificationImportantIcon style={{ fontSize: '20px', fill: '#9C27B0' }} />
        )}

        <Spacer x={0.8} y={0.8} />

        {contactType && (
          <Typography
            textAlign="center"
            variant="body2"
            color="secondary"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {contactType.slice(0, 1).toUpperCase()} :
          </Typography>
        )}
        <Spacer x={0.8} y={0.8} />
        <Box className="__value">
          <Link
            disabled={isUnsubscribed}
            underline="hover"
            href={`${type === 'phone' ? 'tel:' : 'mailto:'}${value}`}
          >
            <Box display="flex" alignItems="center" gap="4px">
              {isEmailIconShow && emailIcon && (
                <img
                  src={images.emailVerification?.[emailIcon]}
                  alt=""
                  width={'20px'}
                  height={'20px'}
                />
              )}
              <Typography
                sx={{
                  color: `${
                    isDomainBlocked(value, props?.adminSettings)
                      ? theme.palette.error.main
                      : theme.palette.text.secondary
                  } !important`,
                  maxWidth: isEnroll ? '100%' : '200px !important',
                }}
                noWrap={true}
              >
                {value}
              </Typography>
            </Box>
          </Link>
        </Box>
      </Box>
      {!isUnsubscribed && (
        <Box display="flex" className="action-btn" pl={5}>
          <ButtonBase onClick={() => onEdit(type, item)}>
            <EditIcon sx={{ maxWidth: '18px', color: '#d6d6d6' }} />
          </ButtonBase>
          <ButtonBase onClick={async () => await navigator?.clipboard?.writeText(value)}>
            <ContentCopyIcon sx={{ maxWidth: '18px', color: '#d6d6d6' }} />
          </ButtonBase>
          <ButtonBase onClick={() => onDelete(type, item)}>
            <DeleteIcon sx={{ maxWidth: '18px', color: '#d6d6d6' }} />
          </ButtonBase>
          <ButtonBase onClick={onAdd}>
            <AddIcon sx={{ maxWidth: '18px', color: '#d6d6d6' }} />
          </ButtonBase>
        </Box>
      )}
    </ContactItemWrapper>
  );
}

export default ContactItem;
