export const aIWriterIcon = () => {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
  <path d="M13.8947 9.52981L15.4499 10.3077L13.8947 11.0879L13.1174 12.6401L12.3401 11.0879L10.785 10.3077L12.3401 9.52981L13.1174 7.97522L13.8947 9.52981ZM5.34275 4.10975L3.78758 3.33246L5.34275 2.55517L6.12004 1L6.89733 2.55517L8.4525 3.33246L6.89733 4.10975L6.12004 5.66492L5.34275 4.10975ZM12.7285 3.7214L11.3681 3.0409L12.7285 2.36041L13.409 1L14.0895 2.36041L15.4499 3.0409L14.0895 3.7214L13.409 5.08181L12.7285 3.7214Z" fill="url(#paint0_linear_7111_158566)"/>
  <path d="M1.50015 13.1936C1.4921 12.7306 1.66411 12.2826 1.97991 11.944L6.77097 7.15221L9.31831 9.65705L4.5048 14.4695C4.1661 14.7852 3.71805 14.9571 3.25509 14.9489C2.79213 14.9408 2.35041 14.7532 2.02303 14.4258C1.69566 14.0983 1.50819 13.6566 1.50015 13.1936Z" fill="url(#paint1_linear_7111_158566)"/>
  <path d="M10.2845 4.87532L11.574 6.16487C11.9156 6.50648 11.9156 7.06034 11.574 7.40191L9.9873 8.98821L7.44204 6.48104L9.04747 4.87537C9.38904 4.53374 9.94289 4.53372 10.2845 4.87532Z" fill="url(#paint2_linear_7111_158566)"/>
  <defs>
    <linearGradient id="paint0_linear_7111_158566" x1="11.5122" y1="1.71763" x2="3.94096" y2="13.1051" gradientUnits="userSpaceOnUse">
      <stop offset="0.107101" stop-color="#FFD480"/>
      <stop offset="0.378303" stop-color="#F240A4"/>
      <stop offset="0.587514" stop-color="#1976D2"/>
    </linearGradient>
    <linearGradient id="paint1_linear_7111_158566" x1="11.5122" y1="1.71763" x2="3.94096" y2="13.1051" gradientUnits="userSpaceOnUse">
      <stop offset="0.107101" stop-color="#FFD480"/>
      <stop offset="0.378303" stop-color="#F240A4"/>
      <stop offset="0.587514" stop-color="#1976D2"/>
    </linearGradient>
    <linearGradient id="paint2_linear_7111_158566" x1="11.5122" y1="1.71763" x2="3.94096" y2="13.1051" gradientUnits="userSpaceOnUse">
      <stop offset="0.107101" stop-color="#FFD480"/>
      <stop offset="0.378303" stop-color="#F240A4"/>
      <stop offset="0.587514" stop-color="#1976D2"/>
    </linearGradient>
  </defs>
</svg>`;
};

export const uploadButtonIcon = () => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
           <path d="M9.46148 7.31979V3.27137H10.7246V7.31979H9.46148ZM5.02421 12.5229C4.65186 12.3773 4.34828 12.1373 4.11348 11.8032C3.87867 11.4691 3.76127 11.0894 3.76127 10.664V3.27137H5.02421V12.5229ZM5.82548 16C4.4579 16 3.29691 15.52 2.34253 14.56C1.38814 13.6001 0.91095 12.4364 0.91095 11.0688V3.4979C0.91095 2.52625 1.24835 1.70035 1.92316 1.02021C2.59783 0.340071 3.42099 0 4.39263 0C5.36428 0 6.18751 0.340071 6.86232 1.02021C7.53713 1.70035 7.87453 2.52625 7.87453 3.4979V9.68421H6.61137V3.4899C6.60267 2.868 6.38667 2.3414 5.96337 1.9101C5.54007 1.47881 5.01649 1.26316 4.39263 1.26316C3.77144 1.26316 3.24639 1.48014 2.81748 1.9141C2.38856 2.34821 2.17411 2.87614 2.17411 3.4979V11.0688C2.16541 12.0869 2.51734 12.9528 3.2299 13.6663C3.94246 14.38 4.80779 14.7368 5.8259 14.7368C6.15727 14.7368 6.47207 14.6966 6.77032 14.6162C7.06856 14.5358 7.35025 14.4152 7.61537 14.2543V15.6583C7.33677 15.7706 7.04772 15.8556 6.74821 15.9133C6.44856 15.9711 6.14099 16 5.82548 16ZM9.46148 15.1579V12.7935H7.09727V11.5303H9.46148V9.16611H10.7246V11.5303H13.0891V12.7935H10.7246V15.1579H9.46148Z" fill="#656E83"/>
          </svg>
`;
};
