import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '7px',
    border: '1px solid #0000001F',
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
    gap: '8px',
    // flex: '1 0 0',
  },
  button: {
    // display: 'flex',
    padding: '8px 16px',
    // justifyContent: 'center',
    // alignItems: 'center',
    gap: '8px',
    borderRadius: '5px',
    color: '#00000099',
    fontWeight: '400',
    lineHeight: '20px',
    // letterSpacing: '0.15px',
    // textAlign: 'center',
    background: 'transparent',
    textTransform: 'none',
  },
  activeButton: {
    background: '#1976D214',
    color: '#1976D2',
    fontWeight: 500,
    lineHeight: '20px',
  },
  label: {
    color: '#00000099',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  card: {
    borderRadius: '15px',
    background: theme.palette.text.white,
    display: ' flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'fixed',
    right: '30%',
    top: '67px',
  },
  headerText: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '21.6px',
    letterSpacing: '-0.5px',
  },
  cardContent: {
    display: 'flex',
    padding: '16px 24px',
    flexDirection: 'column',
    // align-items: flex-start;
    gap: '16px',
    alignSelf: 'stretch',
    background: theme.palette.text.white,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.07) inset',
  },
  cardFooter: {
    justifyContent: 'flex-end',
    display: 'flex',
    borderRadius: '15px',
    background: theme.palette.text.white,
    padding: '0px 24px 16px 24px',
    gap: '10px',
  },
  aiInsertButton: {
    borderRadius: '3px',
    background: '#00B295',
    boxShadow: '0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.15), 0px 1px 0.75px 0px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    height: '42px',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    color: `${theme.palette.text.white} !important`,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.4px',
    '&:disabled': {
      color: '#FFF !important',
      background: '#999999',
    },
    '&:hover': {
      background: '#00B295',
      color: '#FFF !important',
    },
  },
  aiButton: {
    display: 'flex',
    height: '42px',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '3px 0px 0px 3px',
    background: 'linear-gradient(0deg, #009980 0%, #009980 100%), #FFF',
    boxShadow: '0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.15), 0px 1px 0.75px 0px rgba(0, 0, 0, 0.05)',
    color: theme.palette.text.white,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.4px',
    '&:disabled': {
      color: `${theme.palette.text.white} !important`,
      background: '#999999',
    },
  },
  promptText: {
    color: '#B3B9BF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  optionText: {
    color: '#031729',
    fontSize: '14px',
    fontWeight: 700,
    letterSpacing: '0.15px',
  },
  promptContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px 24px',
    background: theme.palette.text.white,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.07) inset',
    flex: '1 0 0',
    borderRadius: '7px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: '8px',
    height: '335px',
    overflowY: 'auto',
  },
  activePromptContent: {
    borderColor: '#1976D2',
    background: 'rgba(25, 118, 210, 0.04)',
  },
  promptContentHeader: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '19.92px',
    letterSpacing: ' 0.4px',
  },
  promptContentDescription: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  tootltipHeader: {
    color: theme.palette.text.white,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '23.34px',
  },
  tootltipContent: {
    color: theme.palette.text.white,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginTop: '20px',
  },
}));
