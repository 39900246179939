import React from 'react';
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { ModalFooter, useStyles } from '../styles';
import { Button, Form } from '../../../../../components/shared';

const CreateVoiceModalFooter = ({ isDisabled, loading, onSubmit, isEdit }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <ModalFooter>
      <Stack
        gap={'20px'}
        direction="row"
        justifyContent="space-between"
        alignItems={'center'}
        width="100%"
      >
        <Box display="flex" alignItems="center">
          {isDisabled && isEdit ? (
            <Tooltip
              title={
                <Stack padding={'16px'} maxWidth={'372px'}>
                  <Stack gap="8px">
                    <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                      <ErrorOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
                      <Typography variant={'body1'}>Voice disabled</Typography>
                    </Box>
                    <Typography variant={'body2'}>
                      Voice is disabled, so it cannot be set as the default.
                    </Typography>
                  </Stack>
                </Stack>
              }
              arrow
              placement={'bottom'}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: theme.palette.background.purple,
                    '& .MuiTooltip-arrow': {
                      color: theme.palette.background.purple,
                    },
                  },
                },
              }}
            >
              <div>
                <Form.Field.Switch
                  sx={{ mt: 0 }}
                  type={'default'}
                  label="Save as default voice"
                  name="isDefault"
                  color="secondary"
                  disabled={isDisabled}
                />
              </div>
            </Tooltip>
          ) : (
            <Stack ml={2} mt={-2}>
              <Form.Field.Switch
                type={'default'}
                label="Save as default voice"
                name="isDefault"
                color="secondary"
              />
            </Stack>
          )}
        </Box>
        <Button
          variant={'contained'}
          color="secondary"
          onClick={onSubmit}
          className={classes.secondaryButton}
          loading={loading}
        >
          Save
        </Button>
      </Stack>
    </ModalFooter>
  );
};

export default CreateVoiceModalFooter;
