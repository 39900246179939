import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';
import * as Yup from 'yup';
import {
  Alert,
  FormControlLabel,
  ListItemButton,
  Menu,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { Grid, Box, Typography } from '@mui/material';
import { Button, Form, Spacer, Modal } from 'src/components/shared';
import { fetchTemplatesLookup, populateTemplate, fetchFile } from 'src/modules/app/api/appApis';
import { fetchTemplateDetails } from 'src/modules/admin/api/adminApi';
import { useDispatch } from 'react-redux';
import { fetchTaskReplies } from 'src/modules/tasks/actions/taskActions';
import { EmailFormGroup } from '../ContactDetails/styles';
import { ConfirmDialog } from 'src/components/App';
import ContactDetailsForm from '../ContactDetails/ContactDetailsForm';
import { useMemo } from 'react';
import {
  deleteContactDetail,
  postContactDetails,
  putContactDetails,
} from '../../actions/contactActions';
import { Popover } from 'src/components/App';
import Moment from 'react-moment';
import moment from 'moment';
import { dateFormat } from 'src/config';
import { fetchSequencesLookup } from 'src/modules/sequence/api/sequenceApi';
import { capitalizeFirstLetter } from 'src/utils/helper';
import LayersIcon from '@mui/icons-material/Layers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduledEmail from 'src/components/shared/ScheduledEmail';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

const replyDurationOptions = [
  { value: 1, label: '1 Day' },
  { value: 7, label: '1 Week' },
  { value: 30, label: '1 Month' },
  { value: 'custom', label: 'Custom Date' },
];

const replyTypeOptions = [
  { value: 'Enroll_Sequence', label: 'Enrol in a sequence' },
  { value: 'Follow_up', label: 'Remind me to Follow up' },
];

const SendEmailForm = ({
  contact,
  sendContactEmail = () => {},
  putContactEmail = () => {},
  fetchContactNotes = () => {},
  putTaskReply = () => {},
  onClose = () => {},
  templateData, // edit mode
  formProps = {}, // to control
  type,
  id,
  task,
}) => {
  const popoverRef1 = useRef(null);
  const popoverRef2 = useRef(null);
  const formRef = useRef(null);
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(false);
  const [customDate, setCustomDate] = useState(task?.replyReminder?.date || 'select date');
  const [noEmailDisabled, setNoEmailDisabled] = useState(false); // disable fields if contact without email
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [description, setDescription] = useState('');
  const [subject, setSubject] = useState('');
  const [attachments, setAttachments] = useState([]);
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));
  const currentUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [formType, setFormType] = useState(undefined);
  const [formData, setFormData] = useState(undefined);
  const [deleteContactItem, setDeleteContactItem] = useState({});
  const [scheduledEmailTime, setScheduledEmailTime] = useState('');
  const [deleteType, setDeleteType] = useState('');
  const [errorMsg, setErrorMsg] = useState({});
  const isEmail = contact?.emails?.length ? contact?.emails[0].email : null;
  const [sender, setSender] = useState({});
  const [submitButtonText, setSubmitButtonText] = useState(
    formProps?.submitButtonText || 'Send email',
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { showCancelButton = true, dontShowEmailField = false } = formProps;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFocus = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (contact?.emails?.length || dontShowEmailField) setNoEmailDisabled(false);
    else setNoEmailDisabled(true);
  }, [contact]);

  useEffect(() => {
    if (templateData) {
      const { subject, content, sender } = templateData;
      //fetchContent(subject, content, contactId, currentUserId);
      const senderWithName = sender
        ? [
            {
              ...sender,
              name: `${sender.fname} ${sender.lname} (${sender.email})`,
            },
          ]
        : [];
      setSender(senderWithName);
      setTemplate(templateData);
      setDescription(content);
      setSubject(subject);
    }
  }, []);

  const fetchTemplatesDetails = async (val, type) => {
    const newTemplates = await fetchTemplatesLookup(val, type, 1000);
    if (newTemplates && newTemplates.length > 0) {
      const sequenceResults = newTemplates
        .sort(
          (a, b) =>
            (a.permissionType === '') - (b.permissionType === '') ||
            a.permissionType - b.permissionType,
        )
        .map((option) => {
          var permissionType =
            option.permissionType === 'team'
              ? 'Team'
              : option.permissionType === 'private'
              ? 'Private'
              : 'Organisation';

          return {
            accessType: permissionType,
            ...option,
          };
        });
      return await sequenceResults;
    }
  };

  const fetchContent = (subject, body, contact, user) => {
    setLoading(true);
    populateTemplate(subject, body, contact, user)
      .then((res) => {
        setDescription(res.content);
        setSubject(res.subject);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error: ', error);
      });
  };

  useEffect(() => {
    if (event && event?.id && event?.id !== '' && event?.templates && event?.templates !== '') {
      setDescription(event.description);
      setSubject(event.subject);
      setFetchingTemplates(true);
      fetchTemplateDetails(event?.templates)
        .then((res) => {
          setTemplate(res.template);
          setFetchingTemplates(false);
        })
        .catch((err) => {
          setTemplate({});
          setFetchingTemplates(false);
        });
    }
  }, []);

  useEffect(() => {
    if (template?.attachments && template.attachments.length) {
      fetchAttachments();
    }
  }, [template.attachments]);

  const fetchAttachments = async () => {
    const promises = [];
    for (let i = 0; i < template.attachments?.length; i++) {
      const attachmentId = template.attachments[i];
      promises.push(fetchFile(attachmentId));
    }

    try {
      let response = await Promise.all(promises);
      if (response && response.length) {
        const attaches = response.map((file) => ({
          ...file.upload,
        }));
        setAttachments(attaches);
      }
    } catch (error) {}
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const data = JSON.parse(JSON.stringify(values));
      delete data?.sender;

      const attachments = JSON.parse(JSON.stringify(data.attachments));
      data.content = data.description;
      data.attachments = attachments?.length ? attachments.map((file) => file.id) : [];
      data.files = data.attachments;
      data.email = data?.email?.email;
      if (data?.isReplyReminderEnabled) {
        const { date, sequence } = data?.replyReminder;
        data.replyReminder = {
          ...data?.replyReminder,
          sequence: sequence?.id,
          date:
            date === 'custom'
              ? moment(customDate)?.format('YYYY-MM-DD')
              : moment().add('days', date)?.format('YYYY-MM-DD'),
        };
      } else delete data?.replyReminder;
      if (scheduledEmailTime && submitButtonText !== 'Send email')
        data.scheduledOn = scheduledEmailTime;

      if (templateData) {
        delete templateData.id;
        const updateData = { ...templateData, ...data, templates: {} };
        if (type === 'reply') {
          await putTaskReply(id, {
            contact: contact,
            subject: updateData.subject,
            content: updateData.content,
            // sender: updateData?.sender?.id,
            from: updateData?.sender?.email,
            taskId: updateData.task,
            templateName: updateData?.name,
            includeTemplateData: true,
          });
          dispatch(fetchTaskReplies(templateData.taskId));
        } else {
          updateData.type = 'clonedEmail'; // override type
          delete updateData.status;
          updateData.description = data.description;
          // updateData.sender = updateData?.sender?.id;
          await putContactEmail(contact.id, templateData.taskId, updateData);
          dispatch(fetchTaskReplies(templateData.taskId));
        }
      } else {
        data.type = 'clonedEmail';
        if (data?.templates && data?.templates?.id) {
          data.sourceTemplate = data.templates.id;
        }
        await sendContactEmail(contact.id, data);
      }
      await fetchContactNotes(contact.id);
      setLoading(false);
      onClose(undefined);
    } catch (error) {
      setLoading(false);
    }
  };

  const openForm = (type, value = undefined) => {
    if (type === 'social') {
      if (value && value !== '') {
        window.open(value, '_blank', 'noopener,noreferrer');
      } else {
        setFormType(type);
      }
    } else {
      if (value) {
        setFormData(value);
      }
      setFormType(type);
    }
  };

  const addContactDetails = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(postContactDetails(contact.id, formType, data, resolve, reject));
    });
  };

  const editContactDetails = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(putContactDetails(contact.id, formType, formData.id, data, resolve, reject));
    });
  };

  const modalTitle = useMemo(() => {
    return formType === 'email'
      ? formData?.id
        ? 'Edit email address'
        : 'Add Email address'
      : formData?.id
      ? 'Edit Phone number'
      : 'Add Phone number';
  }, [formType, formData]);

  const onSubmit = async (data) => {
    try {
      if (formData?.id && formData?.id !== '') {
        await editContactDetails(data);
      } else {
        await addContactDetails(data);
      }
      await props.fetchContact(contact?.id);
      onFormClose();
    } catch (error) {
      return error;
    }
  };

  const fetchSequenceDetails = async (val) => {
    const newSequences = await fetchSequencesLookup(val);
    if (newSequences && newSequences.length > 0) {
      const sequenceResults = newSequences.sort(
        (a, b) => (a.accessType === null) - (b.accessType === null) || a.accessType - b.accessType,
      );
      return await sequenceResults;
    }
  };

  const onDelete = (type, item) => {
    setDeleteContactItem(item);
    setDeleteType(type);
  };

  const closeDeleteModal = () => {
    setDeleteContactItem({});
    setDeleteType('');
  };

  const confirmDelete = async () => {
    try {
      await new Promise((resolve, reject) => {
        dispatch(
          deleteContactDetail(contact.id, deleteType, deleteContactItem.id, resolve, reject),
        );
      });
      await closeDeleteModal();
    } catch (error) {}
  };

  const onFormClose = () => {
    setFormData(undefined);
    setFormType(undefined);
  };

  return (
    <>
      {noEmailDisabled ? (
        <Box mt={2}>
          <Alert severity="warning">Contact does not have an email</Alert>
        </Box>
      ) : null}
      <Form
        formRef={formRef}
        initialValues={{
          type: 'email',
          eventType: 'email',
          name: template?.name || '',
          subject: subject,
          templates: template,
          description: description,
          category: template?.category || '',
          attachments: attachments,
          isPublished: true,
          email: isEmail,
          isReplyReminderEnabled: task?.isReplyReminderEnabled || false,
          replyReminder: {
            type: task?.replyReminder?.type || null,
            date: task?.replyReminder?.date || null,
            sequence: task?.replyReminder?.sequence || null,
          },
        }}
        validationSchema={Yup.object().shape({
          subject: Yup.string().required('Please enter subject!'),
          description: Yup.string().required('Please enter description!'),
          isReplyReminderEnabled: Yup.boolean(),
          replyReminder: Yup.object().when('isReplyReminderEnabled', {
            is: true,
            then: Yup.object().shape({
              type: Yup.string().nullable().required('Please select required actions!'),
              date: Yup.string().nullable().required('Please select required days!'),
              sequence: Yup.object()
                .nullable()
                .test('sequence', 'Please enter a valid sequence.', function (value) {
                  const { type } = this.parent;
                  if (type === 'Enroll_Sequence') {
                    return Object.keys(value || {})?.length;
                  }
                  return true;
                }),
            }),
          }),
        })}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setErrorMsg({
                  type:
                    values?.isReplyReminderEnabled && !values?.replyReminder?.type
                      ? 'Please select required actions!'
                      : '',
                  date:
                    values?.isReplyReminderEnabled && !values?.replyReminder?.date
                      ? 'Please select required days!'
                      : '',
                });
                formProps.submitForm();
                return false;
              }}
              style={{ height: '100%' }}
              noValidate
            >
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
                height="100%"
                width="100%"
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    width: '100%',
                    margin: '12px 0',
                    '& > div': { margin: 0 },
                  }}
                >
                  <Form.Field.AutoComplete
                    sx={{
                      padding: '5px 20px',
                      borderRadius: '4px',
                      border: '1px solid #70707080',
                      '& .MuiAutocomplete-inputRoot': {
                        '&:before': {
                          border: 'none !important',
                        },
                        '&:after': {
                          border: '1px solid #70707060',
                        },
                      },
                    }}
                    disabled={noEmailDisabled}
                    options={[]}
                    fullWidth
                    clearOnEscape={true}
                    name="templates"
                    inputProps={{
                      startAdornment: (
                        <LayersIcon style={{ color: '#707070', marginRight: '8px' }} />
                      ),
                    }}
                    placeholder={'Apply template'}
                    groupBy={(option) => option?.accessType || ''}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(val) => {
                      if (val) {
                        setTemplate(val);
                        setDescription(val.content);
                        setSubject(val.subject);
                        fetchContent(val.subject, val.content, contact.id, currentUser.id);
                      }
                    }}
                    remoteMethod={(val) => {
                      return fetchTemplatesDetails(val, 'email');
                    }}
                    renderOption={(option, props) => {
                      return (
                        <Box component="li" {...props}>
                          <Grid container spacing={0}>
                            {option.name}
                          </Grid>
                        </Box>
                      );
                    }}
                    loading={fetchingTemplates}
                    optLabel="name"
                    optValue="id"
                  />
                  <EmailFormGroup>
                    {!dontShowEmailField && contact.emails?.length !== 0 && (
                      <Form.Field.AutoComplete
                        sx={{
                          padding: '5px 20px',
                          borderRadius: '4px',
                          border: '1px solid #70707080',
                          '& .MuiAutocomplete-inputRoot': {
                            '&:before': {
                              border: 'none !important',
                            },
                            '&:after': {
                              border: '1px solid #70707060',
                            },
                          },
                        }}
                        options={contact.emails}
                        fullWidth
                        clearOnEscape={true}
                        name="email"
                        placeholder={'To'}
                        optLabel="email"
                        optValue="email"
                      />
                    )}

                    <Form.Field.Input
                      fullWidth
                      sx={{
                        '& > .MuiOutlinedInput-root:hover > fieldset': {
                          borderColor: '#70707080 !important',
                        },
                      }}
                      variant="outlined"
                      name="subject"
                      placeholder="Subject"
                      showPicker={true}
                      options={replacementVariables}
                      optValue="value"
                      optLabel="text"
                      emojiPicker={true}
                      disabled={noEmailDisabled}
                    />

                    <Form.Field.TextEditor
                      fullWidth
                      toolbarLocation="bottom"
                      variant="outlined"
                      name="description"
                      placeHolder="Your email..."
                      disabled={noEmailDisabled}
                    />
                  </EmailFormGroup>

                  <Form.Field.File
                    fullWidth
                    variant="outlined"
                    name="attachments"
                    label="Files"
                    maxSize={5}
                  />
                  <Stack gap={1.5}>
                    <Box display="flex" alignItems={'center'} gap={1.5}>
                      <Box sx={{ '& div, & span, & label': { margin: '0 !important' } }}>
                        <Form.Field.Switch name="isReplyReminderEnabled" />
                      </Box>
                      <Typography variant="body2" display={'flex'} gap={'10px'}>
                        if no reply in
                        <Popover
                          trigger="click"
                          sx={{ '& .MuiCardContent-root': { minWidth: 240 } }}
                          Child={
                            <Typography
                              color={values?.isReplyReminderEnabled ? 'secondary' : 'textSecondary'}
                              variant="body2"
                              sx={{ display: 'inline' }}
                            >
                              {values?.replyReminder?.date &&
                              (values?.replyReminder?.date > 0 ||
                                values?.replyReminder?.date === 'custom') ? (
                                values?.replyReminder?.date === 'custom' ? (
                                  customDate === 'select date' ? (
                                    'select date'
                                  ) : (
                                    <Moment format={dateFormat}>{customDate}</Moment>
                                  )
                                ) : (
                                  replyDurationOptions.find(
                                    (item) => item.value === values?.replyReminder?.date,
                                  )?.label
                                )
                              ) : task?.replyReminder?.date ? (
                                moment(task.replyReminder.date).fromNow()
                              ) : (
                                'Select Days'
                              )}
                            </Typography>
                          }
                          isPadding={true}
                          ref={popoverRef1}
                          disabled={!values?.isReplyReminderEnabled}
                        >
                          {values?.replyReminder?.date === 'custom' &&
                          customDate === 'select date' ? (
                            <Form.Field.Datepicker
                              fullWidth={true}
                              variant="inline"
                              size="small"
                              value={customDate}
                              inputVariant="standard"
                              isStatic={true}
                              onChange={(val) => {
                                setCustomDate(val);
                                popoverRef1.current.close();
                              }}
                            />
                          ) : (
                            <Form.Field.Select
                              options={replyDurationOptions}
                              fullWidth={true}
                              variant="outlined"
                              name="replyReminder.date"
                              optLabel="label"
                              optValue="value"
                              onChange={(val) => {
                                setErrorMsg((prevProps) => {
                                  return {
                                    ...prevProps,
                                    date: !!val ? '' : 'Please select required days!',
                                  };
                                });
                                if (val !== 'custom') popoverRef1.current.close();
                                else setCustomDate('select date');
                              }}
                            />
                          )}
                        </Popover>
                        then
                        <Popover
                          trigger="click"
                          sx={{ '& .MuiCardContent-root': { minWidth: 240 } }}
                          Child={
                            <Typography
                              color={values?.isReplyReminderEnabled ? 'secondary' : 'textSecondary'}
                              variant="body2"
                              sx={{ display: 'inline' }}
                            >
                              {values?.replyReminder?.type !== null
                                ? replyTypeOptions.find(
                                    (item) => item.value === values?.replyReminder?.type,
                                  )?.label
                                : 'Select Action'}
                            </Typography>
                          }
                          isPadding={true}
                          ref={popoverRef2}
                          disabled={!values?.isReplyReminderEnabled}
                        >
                          <Form.Field.Select
                            options={replyTypeOptions}
                            fullWidth={true}
                            showNone={false}
                            variant="outlined"
                            name="replyReminder.type"
                            optLabel="label"
                            optValue="value"
                            onChange={(val) => {
                              setErrorMsg((prevProps) => {
                                return {
                                  ...prevProps,
                                  type: !!val ? '' : 'Please select required type!',
                                };
                              });
                              popoverRef2.current.close();
                            }}
                          />
                        </Popover>
                      </Typography>
                    </Box>
                    {errorMsg?.date ||
                      (errorMsg?.type && (
                        <Stack>
                          {errorMsg?.date && (
                            <Typography variant="caption" color={'#d32f2f'}>
                              {errorMsg?.date}
                            </Typography>
                          )}
                          {errorMsg?.type && (
                            <Typography variant="caption" color={'#d32f2f'}>
                              {errorMsg?.type}
                            </Typography>
                          )}
                        </Stack>
                      ))}
                  </Stack>
                  <Box display="flex" justifyContent="flex-end" width="100%">
                    {values?.replyReminder?.type === 'Enroll_Sequence' && (
                      <Stack width={'100%'}>
                        <Form.Field.AutoComplete
                          multiple={false}
                          fullWidth
                          options={[]}
                          variant="outlined"
                          name="replyReminder.sequence"
                          label="Select Sequence"
                          optLabel="name"
                          optValue="id"
                          groupBy={(option) => capitalizeFirstLetter(option?.accessType)}
                          getOptionLabel={(option) => option.name || ''}
                          remoteMethod={async (val) => {
                            return await fetchSequenceDetails(val);
                          }}
                        />
                        <Typography variant="caption" color="#00000099">
                          The first step will start on the date specified above.
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="flex-end" width="100%">
                  {showCancelButton ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={loading}
                      onClick={() => {
                        onClose(undefined);
                      }}
                      sx={{ width: 120 }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <div></div>
                  )}

                  <Spacer x={1} y={1} />
                  {submitButtonText === 'Send email' ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      loading={loading || fetchingTemplates}
                      disabled={loading || noEmailDisabled}
                      sx={{
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                        borderRight: '1px solid #00000020',
                      }}
                    >
                      {submitButtonText}
                    </Button>
                  ) : (
                    <ScheduledEmail
                      onClose={() => setScheduledEmailTime('')}
                      onSubmit={(value) => {
                        setScheduledEmailTime(value);
                        formProps.submitForm();
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        loading={loading || fetchingTemplates}
                        disabled={loading || noEmailDisabled}
                        sx={{
                          borderBottomRightRadius: 0,
                          borderTopRightRadius: 0,
                          borderRight: '1px solid #00000020',
                        }}
                      >
                        {submitButtonText}
                      </Button>
                    </ScheduledEmail>
                  )}
                  <Button
                    aria-controls={'menuId'}
                    aria-haspopup="true"
                    variant={'contained'}
                    color={'secondary'}
                    disabled={loading || noEmailDisabled}
                    onClick={handleFocus}
                    sx={{
                      width: 40,
                      minWidth: 'unset',
                      borderBottomLeftRadius: 0,
                      borderTopLeftRadius: 0,
                    }}
                  >
                    <ArrowDropDownOutlinedIcon />
                  </Button>
                  <Menu id={'menuId'} anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <ListItemButton
                      onClick={() => {
                        setSubmitButtonText('Send email');
                        handleClose();
                      }}
                    >
                      Send email
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        setSubmitButtonText('Send email later');
                        handleClose();
                      }}
                    >
                      Send email later
                    </ListItemButton>
                  </Menu>
                </Box>
              </Box>
            </form>
          );
        }}
      </Form>
      <Modal open={formType === 'email'} onClose={onFormClose} title={modalTitle} size="xs">
        <ContactDetailsForm
          formType={formType}
          formData={formData}
          onSubmit={onSubmit}
          onClose={onFormClose}
        />
      </Modal>
      <ConfirmDialog
        title="Remove Contact Detail"
        open={deleteContactItem?.id}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
      >
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to remove{' '}
          <b>{deleteType === 'email' ? deleteContactItem?.email : deleteContactItem?.phone}?</b>
        </Typography>
      </ConfirmDialog>
    </>
  );
};

export default SendEmailForm;
