import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, Typography, useTheme } from '@mui/material';

import images from 'src/config/images';
import { ModalContent, ModalHeader, NameFieldTitle, useStyles } from '../styles';
import { Form } from '../../../../../components/shared';
import Dialog from '../../../../../components/shared/DialogV2';
import CreateVoiceModalFooter from './CreateVoiceModalFooter';

const CreateVoiceNameModal = ({
  setShowVoiceNameField,
  voiceData,
  setVoiceData,
  onSubmit,
  open = false,
  isDisabled,
  loading,
  formProps,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { errors } = formProps;

  const handleClose = () => {
    setShowVoiceNameField(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} className={classes.modalContainer}>
      <ModalHeader>
        <Stack direction="row" gap="8px" alignItems="center">
          <img src={images.voice.toneOfVoiceIcon} alt={'icon'} width={32} height={32} />
          <Typography variant={'h1'} fontWeight={'600'}>
            {voiceData?.id ? 'Edit' : 'Create'} a Tone of Voice
          </Typography>
        </Stack>
        <CloseIcon
          sx={{ color: theme.palette.text.grey1, fontSize: '32px', cursor: 'pointer' }}
          onClick={handleClose}
        />
      </ModalHeader>
      <ModalContent withPadding>
        <Box>
          <NameFieldTitle variant={'h3'}>Add a name</NameFieldTitle>
          <Box className={classes.textFieldNameBox}>
            <Form.Field.Input
              fullWidth
              variant="outlined"
              padding="0px"
              className={classes.textFieldName}
              name="name"
              error={Boolean(errors?.name)}
              helperText={errors?.name}
            />
          </Box>
        </Box>
      </ModalContent>
      <CreateVoiceModalFooter
        isDisabled={isDisabled}
        loading={loading}
        onSubmit={onSubmit}
        isEdit={voiceData?.id}
      />
    </Dialog>
  );
};
export default CreateVoiceNameModal;
