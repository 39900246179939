import React from 'react';
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import GridFilter from '../../../../components/App/AppGrid/GridFilter';
import {
  AIVoiceCreateViewHeader,
  AttributeSubHeader,
  EmptyAIVoiceCreateViewSubHeader,
  EmptyVoiceCard,
  EmptyVoiceHeader,
  TOVWithNoDataFound,
  useStyles,
} from './styles';
import { Button } from '../../../../components/shared';
import { emptyScreenActiveItems, toneItems } from '../../container/createBrandVoice/utils';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import { CreateVoiceBtn } from './utils';
import images from 'src/config/images';

const EmptyVoice = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { filters, paging, sort = 'status:asc' } = props?.voices;

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      props.fetchVoices(
        {
          ...paging,
          pageNo: 0,
        },
        { ...filters, [key]: value },
        sort,
      );
    } else {
      delete filters[key];
      props.fetchVoices(paging, filters, sort);
    }
  };

  return (
    <>
      <GridFilter
        onChange={onFilterChange}
        options={{
          searchPlaceholder: 'Search tone of voice',
          searchText: filters?._search ? filters._search : '',
          searchOpen: true,
          search: true,
          renderCustomFilterActions: () => {
            return (
              <CreateVoiceBtn
                setOpenAICreateVoice={props.setOpenAICreateVoice}
                formProps={props.formProps}
              />
            );
          },
        }}
      />
      {filters?._search ? (
        <TOVWithNoDataFound>
          <img src={images.voice.noSearchFoundTov} alt={''} />
          <Typography className={classes.noDataTxT} align={'center'} color={'secondary'}>
            Sorry, we couldn’t find that tone of voice.<br/> Try searching with other keywords.
          </Typography>
        </TOVWithNoDataFound>
      ) : (
        <EmptyVoiceCard>
          <Stack gap={'40px'}>
            <Grid container columnSpacing={'24px'}>
              <Grid item xs={12} md={5}>
                <Box display="flex" justifyContent="center" alignItems="center" height={'100%'}>
                  <Stack>
                    <Stack gap={'24px'}>
                      <AIVoiceCreateViewHeader className={classes.lh32}>
                        Define Your Tone of Voice
                      </AIVoiceCreateViewHeader>
                      <EmptyAIVoiceCreateViewSubHeader
                        isGrey
                        variant={'body1'}
                        className={classes.lh28}
                      >
                        Make your content truly yours
                      </EmptyAIVoiceCreateViewSubHeader>
                    </Stack>
                    <AttributeSubHeader
                      mt={'24px'}
                      color={`${theme.palette.text.black1} !important`}
                      sx={{ lineHeight: '24px' }}
                    >
                      Your tone of voice guides AI to create emails, blogs, and everything in
                      between. Start defining your tone to create authentic, audience-focused
                      content every time.
                    </AttributeSubHeader>
                    <Box mt="40px">
                      <Button
                        variant={'outlined'}
                        color={'secondary'}
                        className={classes.getStartedNow}
                        onClick={() =>
                          props.setOpenAICreateVoice({ open: true, creationType: 'ai' })
                        }
                      >
                        Get Started Now
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box display="flex" justifyContent="center" alignItems="center" height={'100%'}>
                  <Stack gap={'16px'} padding="32px">
                    <Stack gap={'8px'}>
                      <EmptyVoiceHeader>Style</EmptyVoiceHeader>
                      <Stack direction={'row'} alignItems={'center'} gap={'12px'}>
                        <Button variant={'contained'} color={'gradient'}>
                          Neutral
                        </Button>
                        <Button
                          variant={'outlined'}
                          color={'gradient'}
                          className={classes.gradientButtonEmpty}
                        >
                          Formal
                        </Button>
                        <Button
                          variant={'outlined'}
                          color={'gradient'}
                          className={classes.gradientButtonEmpty}
                        >
                          Informal
                        </Button>
                      </Stack>
                    </Stack>
                    <Stack gap={'8px'}>
                      <EmptyVoiceHeader>Tone</EmptyVoiceHeader>
                      <Box
                        className={classes.toneList}
                        sx={{ gridTemplateColumns: 'repeat(3, 1fr) !important' }}
                      >
                        {toneItems.map((item) => (
                          <Button
                            variant={emptyScreenActiveItems.includes(item) ? 'contained' : 'solid'}
                            color={'gradient'}
                            className={
                              !emptyScreenActiveItems.includes(item) && classes.gradientButtonEmpty
                            }
                          >
                            {capitalizeFirstLetter(item)}
                          </Button>
                        ))}
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </EmptyVoiceCard>
      )}
    </>
  );
};
export default EmptyVoice;
